import ApiUtils from '../util/apiUtils.jsx'

const REST_HENT_START = "REST_HENT_START";
const REST_HENT_SLUT = "REST_HENT_SLUT";
const REST_HENT_FEJL = "REST_HENT_FEJL";
const REST_HENT_DETALJER_START = "REST_HENT_DETALJER_START";
const REST_HENT_DETALJER_SLUT = "REST_HENT_DETALJER_SLUT";
const REST_HENT_DETALJER_FEJL = "REST_HENT_DETALJER_FEJL";
const REST_OPDATER = "REST_OPDATER";
const REST_OPDATER_FEJL = "REST_OPDATER_FEJL";
const REST_OPDATER_DETALJER = "REST_OPDATER_DETALJER";
const REST_OPDATER_DETALJER_FEJL = "REST_OPDATER_DETALJER_FEJL";

const REST_SLET = "REST_SLET";
const REST_TILFOEJ = "REST_TILFOEJ";
const REST_OPDATERINGER_PAA_VEJ = "REST_OPDATERINGER_PAA_VEJ";

const REST_LOKALOPDATER_DETALJER = "REST_LOKALOPDATER_DETALJER";

const REST_INVALIDER = "REST_INVALIDER";

export function handleInvalidate(notification, dispatch, state) {
    let path = notification.path,
        time = notification.time,
        id = notification.id,
        matches = getMatches(path, id, time, state);
    matches.forEach(match => indlaesMatch(match, dispatch));
}

function indlaesMatch(match, dispatch) {
    if (match.indlaeser) {
        // assume that we are up to date
    } else {
        if (match.id) {
            dispatch(restIndlaesDetaljer(match.definition, match.id));
        } 
        else {
            dispatch(restIndlaes(match.definition));
        }

    }
}

function getMatches(path, id, time, state) {
    let ret = [];
    let match = state.rest[path];
    if (match) {
        let notificationTime = new Date(time);
        if (id) {
            let matchDetaljer = match.detaljer && match.detaljer[id];
            if (matchDetaljer) {
                if (notificationTime > matchDetaljer.indlaesningsTid) {
                    ret.push({ id, definition: match.definition });
                } 
                else {
                    console.log(`Change notification for ${path}/${id} ignored because of time`);
                }
            } else {
                console.log(`Change notification for ${path} ignored because id ${id} not registered`);
            }
        } 
        else {
            if (notificationTime > match.indlaesningsTid) {
                ret.push({ definition: match.definition });
            } else {
                if (!match.indlaesningsTid && match.detaljer) {
                    Object.keys(match.detaljer).forEach(matchDetaljeKey => {
                        let matchDetaljer = match.detaljer[matchDetaljeKey];
                        if (notificationTime > matchDetaljer.indlaesningsTid) {
                            ret.push({ id: matchDetaljeKey, definition: match.definition });
                        } 
                        else {
                            console.log(`Change notification for ${path}/${matchDetaljeKey} ignored because of time`);
                        }
                    });
                } 
                else {
                    console.log(`Change notification for ${path} ignored because of time`);
                }
            }
        }
    }
    return ret;
}

export function getRestStateIndlaeser(definition, state) {
    verify(definition);
    return getRestState(definition, state).indlaeser;
}

export function getDetaljerRestState(definition, id, state) {
    if (getRestState(definition, state).detaljer) {        
        return getRestState(definition, state).detaljer[id];
    }
    return {};
}

export function getDetaljerRestStateIndlaeser(definition, id, state) {    
    verify(definition);
    let detaljerRestState = getDetaljerRestState(definition, id, state);
    return detaljerRestState && detaljerRestState.indlaeser;
}

export function getRestStateListe(definition, state) {
    verify(definition);
    return getRestState(definition, state).liste;
}

export function getRestStateFejl(definition, state) {
    verify(definition);
    return getRestState(definition, state).fejl;
}

export function getRestState(definition, state) {
    verify(definition);
    return state.rest[definition.path] || { detaljer: {} };
}

export function restIndlaes(definition) {
    verify(definition);
    return (dispatch, getState) => {
        let state = getState();
        if (getRestStateIndlaeser(definition, state)) {
            return null;
        }
        dispatch(restHentStartAction(definition));
        return ApiUtils.get(definition.path)
            .then(
                response => {
                    dispatch(restHentSlutAction(definition, response));
                },
                error => {
                    console.log('Fejl ved indlæsning', error);
                    dispatch(restHentFejlAction(definition, 'Fejl ved indlæsning'));
                }
            );
    };
}

export function restIndlaesDetaljer(definition, id) {
    verify(definition);
    return (dispatch, getState) => {
        let state = getState();
        if (getDetaljerRestStateIndlaeser(definition, id, state)) {
            return null;
        }
        dispatch(restHentDetaljerStartAction(definition, id));
        return ApiUtils.get(definition.path+"/" + id)
            .then(
                response => {
                    dispatch(restHentDetaljerSlutAction(definition, id, response));
                },
                error => {
                    dispatch(restHentDetaljerFejlAction(definition, id, 'Fejl ved indlæsning: ' + error));
                }
            );
    };
}

export function restOpdater(definition, objekt, dispatch) {
    const update = definition.update || 'liste';
    if (Array.isArray(objekt)) {
        return ApiUtils.post(definition.path, objekt, { formMode: true })
            .then(response => dispatch(restHentSlutAction(definition, response)));
    } else if (objekt.hasOwnProperty(definition.id)) {
        let id = objekt[definition.id];
        if (update === 'detaljer' || update === 'begge') {
            dispatch(restHentDetaljerStartAction(definition, id));
        } 
        if (update === 'liste' || update === 'begge') {
            dispatch(restHentStartAction(definition));
        }
        return ApiUtils.put(definition.path, id, objekt, { formMode: true })
            .then(
                response => {
                    if (update === 'detaljer' || update === 'begge') {
                        dispatch(restOpdaterDetaljerAction(definition, response));
                    } 
                    if (update === 'liste' || update === 'begge') {
                        dispatch(restOpdaterAction(definition, response));
                    }
                }
            );
    } else {
        return ApiUtils.post(definition.path, objekt, { formMode: true })
            .then(
                response => {
                    if (update === 'detaljer' || update === 'begge') {
                        dispatch(restOpdaterDetaljerAction(definition, response));
                    } 
                    if (update === 'liste' || update === 'begge') {
                        dispatch(restOpdaterAction(definition, response));
                    }
                }
            );
    }
}

export function restSlet(definition, objekt, dispatch) {
    if (!objekt.hasOwnProperty(definition.id)) {
        return dispatch(restSletAction(definition, objekt));
    } else {
        return ApiUtils.delete(definition.path, objekt[definition.id], { formMode: true })
            .then(
                () => {
                    dispatch(restSletAction(definition, objekt));
                }
            );
    }
}

export function restTilfoej(definition, objekt, dispatch) {
    return dispatch(restTilfoejAction(definition, objekt));
}


function verify(definition) {
    if (!definition.path) {
        throw 'Definition skal indeholde path';
    }
    if (!definition.id) {
        throw 'Definition skal indeholde id';
    }
}

export function restHentStartAction(definition) {
    verify(definition);
    return {
        type: REST_HENT_START,
        definition
    };
}

export function restHentFejlAction(definition, fejl) {
    verify(definition);
    return {
        type: REST_HENT_FEJL,
        definition,
        fejl
    };
}

export function restOpdaterFejlAction(definition, fejl) {
    verify(definition);
    return {
        type: REST_OPDATER_FEJL,
        definition,
        fejl
    };
}

export function restOpdaterDetaljerFejlAction(definition, id, fejl) {
    verify(definition);
    return {
        type: REST_OPDATER_DETALJER_FEJL,
        definition,
        fejl,
        id
    };
}

export function restHentSlutAction(definition, liste) {
    verify(definition);
    return {
        type: REST_HENT_SLUT,
        definition,
        liste: Array.isArray(liste) ? liste : [liste]
    };
}

export function restHentDetaljerStartAction(definition, id) {
    verify(definition);
    return {
        type: REST_HENT_DETALJER_START,
        definition,
        id
    };
}

export function restHentDetaljerFejlAction(definition, id, fejl) {
    verify(definition);
    return {
        type: REST_HENT_DETALJER_FEJL,
        definition,
        id,
        fejl
    };
}

export function restHentDetaljerSlutAction(definition, id, detaljer) {
    verify(definition);
    return {
        type: REST_HENT_DETALJER_SLUT,
        definition,
        id, 
        detaljer
    };
}

export function restOpdaterAction(definition, objekt) {
    verify(definition);
    return {
        type: REST_OPDATER,
        definition,
        objekt
    };
}

export function restOpdaterDetaljerAction(definition, objekt) {
    verify(definition);
    return {
        type: REST_OPDATER_DETALJER,
        definition,
        id : objekt[definition.id], 
        objekt
    };
}

export function restSletAction(definition, objekt) {
    verify(definition);
    return {
        type: REST_SLET,
        definition,
        objekt
    };
}

export function restTilfoejAction(definition, objekt) {
    verify(definition);
    return {
        type: REST_TILFOEJ,
        definition,
        objekt
    };
}

export function restOpdateringerPaaVej(value) {
    return {
        type: REST_OPDATERINGER_PAA_VEJ,
        value
    }
}

export function restLokalOpdaterDetaljerAction(definition, objekt) {
    verify(definition);
    return {
        type: REST_LOKALOPDATER_DETALJER,
        definition,
        id : objekt[definition.id], 
        objekt
    };
}

export function restInvalider(definition) {
    verify(definition);
    return {
        type: REST_INVALIDER,
        definition
    };
}

export function reducer(state = {}, action) {
    switch (action.type) {
    case REST_OPDATERINGER_PAA_VEJ:
        return Object.assign({}, state, { _opdateringerPaaVej: action.value });
    case REST_HENT_START:
        return setState(state,
            action,
            {
                indlaeser: true,
                definition: action.definition
            });
    case REST_HENT_SLUT:
        return setState(state,
            action,
            {
                indlaeser: false,
                liste: action.liste,
                detaljer: null,
                fejl: null,
                indlaesningsTid: new Date()
            });
    case REST_HENT_FEJL:
        return setState(state,
            action,
            {
                indlaeser: false,
                fejl: action.fejl
            });
    case REST_HENT_DETALJER_START:
        return setDetaljer(setState(state, action, { definition: action.definition }), action, { indlaeser: true });
    case REST_HENT_DETALJER_SLUT:
        return setDetaljer(state,
            action,
            {
                indlaeser: false,
                detaljer: action.detaljer,
                fejl: null,
                indlaesningsTid: new Date()
            });
    case REST_HENT_DETALJER_FEJL:
        return setDetaljer(state,
            action,
            {
                indlaeser: false,
                fejl: action.fejl
            });
    case REST_OPDATER:
        return setState(state,
            action,
            {
                indlaeser: false,
                liste: opdaterObjekt(state, action),
                indlaesningsTid: new Date()
            });
    case REST_OPDATER_DETALJER:
        return setDetaljer(state,
            action,
            {
                indlaeser: false,
                detaljer: action.objekt,
                fejl: null,
                indlaesningsTid: new Date()
            });
    case REST_LOKALOPDATER_DETALJER:
        {
            let prevDetaljer = (((state[action.definition.path] || {}).detaljer || {})[action.id] || {}).detaljer || {};
            return setDetaljer(state,
                action,
                {
                    detaljer: Object.assign({}, prevDetaljer, action.objekt)
                });
        }
    case REST_SLET:
        return setState(state,
            action,
            {
                liste: sletObjekt(state, action)
            });
    case REST_TILFOEJ:
        return setState(state,
            action,
            {
                liste: tilfoejObjekt(state, action)
            });
    case REST_OPDATER_FEJL:
        return setState(state,
            action,
            {
                indlaeser: false,
                fejl: action.fejl
            });
    case REST_OPDATER_DETALJER_FEJL:
        return setDetaljer(state,
            action,
            {
                indlaeser: false,
                fejl: action.fejl
            });
    case REST_INVALIDER:
        return Object.assign({}, state, { [action.definition.path]: null });
    default:
        return state;
    }
}

function setState(state, action, values) {
    let prevRestState = state[action.definition.path] || {};
    return Object.assign({},
        state,
        { [action.definition.path]: Object.assign({}, prevRestState, values)
        });
}

function setDetaljer(state, action, values) {
    let prevRestState = state[action.definition.path] || {},
        prevDetaljer = prevRestState.detaljer || {},
        prevDetaljeState = prevDetaljer[action.id];

    return setState(state, action, { detaljer: Object.assign({}, prevDetaljer, { [action.id]: Object.assign({}, prevDetaljeState, values)})});
}

function tilfoejObjekt(state, action) {
    let liste = state[action.definition.path].liste.slice();
    liste.push(action.objekt);
    return liste;
}

function sletObjekt(state, action) {
    let i,
        oldList=state[action.definition.path].liste,
        newList=[],
        found = findObjekt(action, oldList, action.objekt);
    for (i=0;i<oldList.length;++i) {
        if (oldList[i]===found) {
            // do not push the deleted one
        } else {
            newList.push(oldList[i]);
        }
    }
    return newList;
}

function opdaterObjekt(state, action) {
    let i,
        oldList=state[action.definition.path].liste ? state[action.definition.path].liste : [action.objekt],
        newList=[],
        found = findObjekt(action, oldList, action.objekt);
    for (i=0;i<oldList.length;++i) {
        if (oldList[i]===found) {
            newList.push(action.objekt);
        } else {
            newList.push(oldList[i]);
        }
    }
    return newList;
}

function findObjekt(action, liste, objekt) {
    let i,
        id = action.definition.id;
    for (i = 0; i < liste.length; ++i) {
        if (objekt[id] === liste[i][id]) {
            return liste[i];
        }
    }
    for (i = 0; i < liste.length; ++i) {
        if (!liste[i].hasOwnProperty('id')) {
            return liste[i];
        }
    }
    return null;
}



