import React, { Component } from 'react';
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class LetsikringAfBarnVedDoed extends Component {
    render() {
        return (
            <div className="harlekin-baggrund">
			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Letsikring af barn ved død</h3>
                        </div>
                    </div>
                    <div className="row">
                        <video controls className="col-xs-12 col-sm-8 col-xs-offset-0 col-sm-offset-2">
                            <source src="/resources/videos/Letsikring_af_barn_ved_doed.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="separator center"></div>
                            <p>Med en Letsikring af barn ved død kan du sikre en udbetaling til dit barn, hvis du dør, inden barnet fylder 21 år.</p>
                            <p>En Letsikring af barn ved død er det, andre pensionsselskaber kalder en børnerente. Den sikrer, at dit barn får udbetalt penge, indtil han eller hun fylder 21 år, hvis du dør inden udgangen af det år, hvor du fylder 60 år.</p>
                            <p>Du kan oprette Letsikringen både til dine egne børn, til din samlevers børn og til adoptivbørn. Har du flere børn, kan du oprette en Letsikring til hvert barn.</p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>

			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-md-8">
										<h3 className="title">Hvis du vil vide mere </h3>
										<p>Vil du vide mere om, hvordan vi kan sikre dit barn økonomisk, kan du kontakte din rådgiver i dit lokale pengeinstitut.</p>
									</div>
									<div className="col-md-4">
										<br/>
										<p><SiteLink url="/blivkunde" classname="btn btn-lg btn-gray-transparent btn-animated">Bliv kunde i Letpension<i className="fa fa-arrow-right pl-20"></i></SiteLink></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/produktet/letsikringvedkritisksygdom#top" billede="/Resources/images/Letpension/produkter/letsikringvedkritisksygdom.png" tekst="Letsikring ved kritisk sygdom" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-5">
                            <ImageLink link="/produktet/letsikringafindtaegtvedpension#top" billede="/Resources/images/Letpension/produkter/letsikringafindtaegtvedpension.png" tekst="Letsikring af indtægt ved pension" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/produktet/letsikringvedsygdom#top" billede="/Resources/images/Letpension/produkter/letsikringvedkritisksygdom.png" tekst="Letsikring ved kritisk sygdom" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-5">
                            <ImageLink link="/produktet/letsikringveddoed#top" billede="/Resources/images/Letpension/produkter/letsikringveddoed.png" tekst="Letsikring ved død" />
                        </div>
                    </div>

                </div>
            </section>

            </div>
      );
    }
}

