import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, PanelGroup } from 'react-bootstrap';
import { ErrorBoundary } from "./errors";
import { getValue, setValueAction } from 'reducers/values';
import PropTypes from 'prop-types';


function mapStateToPropsGroup(state, ownProps) {
    return {
        activeKey: getValue(state, 'activeKey-'+ownProps.id)
    };
}

function mapDispatchToPropsGroup(dispatch, ownProps) {
    return {
        dispatchSetActiveKey: (activeKey) => dispatch(setValueAction('activeKey-'+ownProps.id, activeKey))
    }
}

class AccordionGroup extends Component {
    select(activeKey) {
        this.props.dispatchSetActiveKey(activeKey);
    }
    render() {
        let key = 0;
        let prefix = "expanded-" + this.props.id + "-";
        return (
            <PanelGroup accordion id={this.props.id} className="collapse-style-1" activeKey={this.props.activeKey} onSelect={(activeKey) => this.select(activeKey)}>
                {React.Children.map(this.props.children,
                    (c) => c &&
                    c.type === Accordion
                    ? React.cloneElement(c, { defaultEventKey: ++key, expandedKey: prefix + key })
                    : c)}
            </PanelGroup>
        );
    }
}

AccordionGroup = connect(mapStateToPropsGroup, mapDispatchToPropsGroup)(AccordionGroup);


class Accordion extends Component {
    toggleExpanded() {
        this.props.dispatchSetExpanded(!this.props.expanded);
    }
    render() {
        return (
            <Panel eventKey={this.props.eventKey || this.props.defaultEventKey} >
                <Panel.Heading>
                    <Panel.Title toggle componentClass="h4">{this.props.titel}</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <MountOnEnter mountOnEnter={this.props.mountOnEnter}>
                        <ErrorBoundary>
                            {this.props.children}
                        </ErrorBoundary>
                    </MountOnEnter>
                </Panel.Body>
            </Panel>
        );
    }
}

const contextTypes = {
    // hack into react-bootstrap internals to support mountOnEnter for accordions
    $bs_panel: PropTypes.shape({
        expanded: PropTypes.bool
    })
};

class MountOnEnter extends Component {
    render() {
        let { expanded } = this.context.$bs_panel;
        return expanded || !this.props.mountOnEnter ? this.props.children : null;
    }
}

MountOnEnter.contextTypes = contextTypes;

export { Accordion, AccordionGroup, MountOnEnter };