import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import SiteLink from './navigation/sitelink.jsx';
import ImageLink from './navigation/imagelink.jsx';

import { Panel } from 'react-bootstrap';




export default class Forside extends Component {
  componentDidMount() {
    document.title = 'Neotech';
  }
    render() {
        return (
		<div className="">
			<Velkommen />
		</div>
      );
    }
}

//Forside.defaultProps = { title: 'Forside' };

class Velkommen extends Component {
    render() {
        return (

			<section className="main-container padding-bottom-clear ">
				<div className="container">
                    <img id="cover" src={'/Resources/images/Cover.jpg'}/>
                    <br/>
					<div className="row">
                        <div className="col-md-12">
						    <h3 className="title text-center">Applikationsudvikling</h3>
                            <div className="separator center"></div>   
                            <div>   
						        <p></p>
                                <br/>
                                <p></p>
                            </div>
                        </div>
					</div>
				</div>
            </section>
      );
    }
}

class TypiskeOpgaver extends Component {
    render() {
        return (
            
        <section>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-6">
                        <TypiskOpgaveTile title={"Platform udvikling"}>Ipsum lorum</TypiskOpgaveTile>
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-6">
                        <TypiskOpgaveTile title={"App udvikling"}>Ipsum lorum</TypiskOpgaveTile>
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-6">
                        <TypiskOpgaveTile title={"Integrationsopgaver"}>Ipsum lorum</TypiskOpgaveTile>                        
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-6">
                        <TypiskOpgaveTile title={"Beregningstunge algoritmer"}>Ipsum lorum</TypiskOpgaveTile>
                        </div>
                    </div>
                </div>


             
            </section>
        );
    }
}

class TypiskOpgaveTile extends Component {
    content() {
        return this.props.children;
    }
    render() {
        return (

            <Panel className="oversigt-tile">
                <Panel.Heading>
                    {this.props.title}
                </Panel.Heading>
                <Panel.Body className="">
                    {this.content()}
                </Panel.Body>
            </Panel>

      );
    }
}
