export const VIS_INFOMODAL = 'VIS_INFOMODAL';
export const OPDATER_INFOMODAL = 'OPDATER_INFOMODAL';
export const SKJUL_INFOMODAL = 'SKJUL_INFOMODAL';

export function visInfoModal(titel, indhold, skjulLuk) {
    return {
        type: VIS_INFOMODAL,
        titel: titel,
        indhold: indhold,
        skjulLuk
    };
}

export function opdaterInfoModal(indhold, skjulLuk) {
    return {
        type: OPDATER_INFOMODAL,
        indhold: indhold,
        skjulLuk
    };
}

export function skjulInfoModal() {
    return {
        type: SKJUL_INFOMODAL
    };
}
