import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class DataBeskyttelse extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Videregivelse af kundeoplysninger</h3>
                            <div className="separator center"></div>
                            <p>I vores arbejde med at skabe den rette løsning for dig, indhenter vi i Letpension en række oplysninger om vores kunder, som for eksempel navn, adresse, CPR-nummer og helbredsoplysninger. Det er personlige oplysninger, som vi selvfølgelig behandler fortroligt.</p>
                            <p>Mange af oplysningerne får vi fra dig, men nogle af oplysningerne modtager vi fra andre, for eksempel CPR-registeret, andre pensionsselskaber eller det pengeinstitut, som har rådgivet dig i forbindelse med købet af en Letsikring i Letpension.</p>
                            <p>Medarbejdere i Letpension, som gennem deres arbejde får adgang til dine kundeoplysninger, har tavshedspligt og må ikke uberettiget videregive eller udnytte disse oplysninger, medmindre du har givet din accept til det.</p>
                            <p>Letpension har i enkelte tilfælde mulighed for at videregive visse oplysninger:</p>
							<ul class="list-icons">
								<li><i class="fa fa-caret-right pr-10"></i> Letpension har pligt til at videregive oplysninger til offentlige myndigheder, eksempelvis SKAT.</li>
								<li><i class="fa fa-caret-right pr-10"></i> Letpension kan videregive sædvanlige kundeoplysninger om kundeforhold, som bliver brugt til at løse administrative opgaver i Letpension og PFA Pension. Hvilke oplysninger, der er sædvanlige kundeoplysninger, er fastlagt af Finanstilsynet, men begrebet omfatter for eksempel navn, adresse og CPR-nummer.</li>
								<li><i class="fa fa-caret-right pr-10"></i> Letpension kan videregive oplysninger i særlige tilfælde. Det gælder dog kun, hvis det ikke strider mod dine interesser som kunde.</li>
							</ul>


                            <p><strong>Oplysninger om helbred</strong></p>
                            <p>Letpension har brug for at få kendskab til dit helbred, så vi kan skræddersy en løsning, der passer præcis til dit behov. Oplysninger om dit helbred, som du har svaret på via Letpensions helbredsportal, videregiver vi kun, hvis vi har modtaget din accept til det.</p>

                            <p><strong>Accept til videregivelse</strong></p>
                            <p>Når du laver en aftale med Letpension om en Letsikring, giver du accept til, at Letpension, PFA Pension og dit pengeinstitut må videregive oplysninger til hinanden. Denne accept gælder dog ikke videregivelse af helbredsoplysninger til dit pengeinstitut. Formålet med videregivelsen er, at Letpension og PFA Pension kan registrere dig og administrere dine Letsikringer og at dit pengeinstitut kan give dig den optimale rådgivning.</p>

                            <p><strong>Indsigt i oplysningerne</strong></p>
                            <p>Du har til hver en tid mulighed for at få at vide:</p>
							<ul class="list-icons">
								<li><i class="fa fa-caret-right pr-10"></i> Hvilke typer af oplysninger, Letpension kan videregive med dit samtykke,</li>
								<li><i class="fa fa-caret-right pr-10"></i> Til hvilke formål, Letpension kan videregive dine oplysninger, og</li>
								<li><i class="fa fa-caret-right pr-10"></i> Hvem der kan modtage dine oplysninger på baggrund af dit samtykke.</li>
							</ul>

                            <p>Hvis oplysningerne ikke er korrekte, retter vi naturligvis fejlen. </p>

                            <p>Har du spørgsmål til vores retningslinjer for videregivelse af kundeoplysninger, er du velkommen til at kontakte os på adressen: </p>
                            <p>
                                Letpension<br/>
                                Sundkrogsgade 4<br/>
                                2100 København Ø<br/>
                                Telefon 7020 2212<br/>
                                E-mail: letpension@letpension.dk
                            </p>


                        </div>
                    </div>
                </div>
            </section>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/kontakt#top" billede="/Resources/images/Letpension/Brug_din_forsikring_456x300.jpg" tekst="Brug din forsikring" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>


</div>


      );
    }
}

