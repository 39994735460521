import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';

import ImageLink from '../navigation/imagelink.jsx';
import LetsikringVedSygdom from '../produktet/letsikringvedsygdom.jsx';
import LetsikringVedDoed from '../produktet/letsikringveddoed.jsx';
import LetsikringVedKritiskSygdom from '../produktet/letsikringvedkritisksygdom.jsx';
import LetsikringAfBarnVedDoed from '../produktet/letsikringafbarnveddoed.jsx';
import LetsikringAfIndtaegtVedPension from '../produktet/letsikringafindteagtvedpension.jsx';
import {OmkostningerStandard, OmkostningerUdvidet} from '../produktet/omkostninger.jsx';
import Investering from '../produktet/investering.jsx';


export default class Produktet extends Component {
  render() {
      return (
	  <div>
          <Switch>
            <Route exact path='/produktet' component={ProduktetOverblik}/>
            <Route path='/produktet/letsikringvedsygdom' component={LetsikringVedSygdom}/>
            <Route path='/produktet/letsikringveddoed' component={LetsikringVedDoed}/>
            <Route path='/produktet/letsikringvedkritisksygdom' component={LetsikringVedKritiskSygdom}/>
            <Route path='/produktet/letsikringafbarnveddoed' component={LetsikringAfBarnVedDoed}/>
            <Route path='/produktet/letsikringafindtaegtvedpension' component={LetsikringAfIndtaegtVedPension}/>
            <Route path='/produktet/omkostninger' component={OmkostningerStandard}/>
            <Route path='/produktet/omkostningerudvidet' component={OmkostningerUdvidet}/>
            <Route path='/produktet/investering' component={Investering}/>
          </Switch>
		</div>

      );
    }
}


class ProduktetOverblik extends Component {
    render() {
        return (

<div className="harlekin-baggrund">
			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Det vigtigste produkt er den gode oplevelse</h3>
                        </div>
                    </div>
                    <div className="row">
                        <video controls className="col-xs-12 col-sm-8 col-xs-offset-0 col-sm-offset-2">
                            <source src="/resources/videos/Produktintro.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="separator center"></div>
							<p>Det vigtigste produkt hos Letpension er den gode oplevelse. Den kan vi give dig, fordi det er din egen rådgiver i dit lokale pengeinstitut, som allerede kender dig, din økonomi og dine værdier, der rådgiver dig om vores løsninger til pension og forsikring</p>
							<p>Med vores enkle og moderne tilgang kan du mere end bare spare op til din pension i dit pengeinstitut. Du kan kombinere din opsparing med Letsikringer, som passer til det sted i livet, hvor du er lige nu.</p>
							<p>Du er med hele vejen, når du sammen med din lokale rådgiver skaber din løsning elektronisk.</p>
                            <br />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/produktet/letsikringvedsygdom" billede="/Resources/images/Letpension/produkter/letsikringvedsygdom.png" tekst="Letsikring ved sygdom" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/Produktet/LetsikringVedKritiskSygdom" billede="/Resources/images/Letpension/produkter/letsikringvedkritisksygdom.png" tekst="Letsikring ved kritisk sygdom" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/Produktet/LetsikringAfIndtaegtVedPension" billede="/Resources/images/Letpension/produkter/letsikringafindtaegtvedpension.png" tekst="Letsikring af indtægt ved pension" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/Produktet/LetsikringAfBarnVedDoed" billede="/Resources/images/Letpension/produkter/letsikringafbarnveddoed.png" tekst="Letsikring af barn" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/produktet/letsikringveddoed" billede="/Resources/images/Letpension/produkter/letsikringveddoed.png" tekst="Letsikring ved død" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/blivkunde" billede="/Resources/images/Letpension/Find_pengeinstitut_457x300.jpg" tekst="Bliv kunde i Letpension" />
                        </div>

                    </div>
			    </div>
            </section>
</div>
      );
    }
}


