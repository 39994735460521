
import {
  OPDATER_BEHOVSGUIDE_AKTIVTTRIN
} from 'actions/behovsguide.jsx'

export default function behovsguide(state = {
    aktivtGuideTrin: null,
    aktivtIndtastTrin: null
}, action)
{
  switch (action.type) {
    case OPDATER_BEHOVSGUIDE_AKTIVTTRIN:
        return Object.assign({},
            state,
            {
                aktivtGuideTrin: action.aktivtGuideTrin,
                aktivtIndtastTrin: action.aktivtIndtastTrin
            });
    default:
      return state;
  }
}
