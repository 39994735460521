import {
    HENT_LETFORSLAG,
    MODTAG_LETFORSLAG,
    INVALIDER_LETFORSLAG,
    OPDATER_LETFORSLAG
    } from '../actions/letforslag.jsx'


export default function letforslag(
    state = {
        indlaeser: false,
        igangvaerendeopdatering: false,
        invalideret: false,
        fejlmeddelelse: null,
        letforslag: null
    },
    action
)
{
    switch (action.type) {
    case HENT_LETFORSLAG:
        return Object.assign({},
            state,
            {
                indlaeser: true,
                igangvaerendeopdatering: false,
                invalideret: false,
                fejlmeddelelse: null,
                letforslag: null
            });
    case MODTAG_LETFORSLAG:
        return Object.assign({},
            state,
            {
                indlaeser: false,
                igangvaerendeopdatering: false,
                invalideret: false,
                fejlmeddelelse: action.fejlmeddelelse,
                letforslag: action.letforslag
            });
    case INVALIDER_LETFORSLAG:
        return Object.assign({},
            state,
            {
                indlaeser: false,
                igangvaerendeopdatering: false,
                fejlmeddelelse: null,
                invalideret: true,
                letforslag: null
            });
    case OPDATER_LETFORSLAG:
        return Object.assign({},
            state,
            {
                indlaeser: false,
                igangvaerendeopdatering: true,
                invalideret: false,
                fejlmeddelelse: null
            });

    default:
        return state;
    }
}


