import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class Klage extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Hvis du vil klage</h3>
                            <div className="separator center"></div>
                            <p>Har du haft en dårlig oplevelse med Letpension, vil vi meget gerne høre fra dig. Send os en mail på <a className="link-dark" href="mailto:klage@letpension.dk">klage@letpension.dk</a> - så vender vi tilbage til dig så hurtigt som muligt og senest indenfor en uge. 
                                I e-mailen skal du oplyse dit navn og telefonnummer og beskrive den oplevelse, som giver anledning til din klage. Bemærk, at når du henvender dig til os via e-mail, giver du samtidig samtykke til, at vi må bruge de oplysninger, du sender os, til at behandle din henvendelse. 
                                Du kan læse mere under <SiteLink url="/privatlivogcookies" className="link-dark">”Privatliv og cookies”</SiteLink>.</p>
                            <p>Din klage giver os mulighed for at blive bedre og lære mere om, hvilke behov du har som kunde, så vi sætter stor pris på, at du bruger tid på at gøre os opmærksomme på, hvad vi kan gøre bedre.</p>
                            <br />     
                            <p><strong>Sådan behandler vi din klage</strong></p>
                            <p>Så snart vi har modtaget din klage, sender vi dig en bekræftelse, så du ved, at vi er i gang med at behandle klagen. Det er muligt, at vi kontakter dig, fordi vi gerne vil have dig til at fortælle os mere om din oplevelse.</p>

                            <p><strong>Hvis du er utilfreds med vores svar</strong></p>
                            <p>Er du ikke tilfreds med vores behandling af din klage, kan du skrive en e-mail eller et brev til vores klageansvarlige. Den klageansvarlige har til opgave at vurdere din sag med nye øjne og har ikke været involveret i din klage i det tidligere forløb. Den klageansvarlige kan beslutte, at vi fortsat eller på ny skal behandle klagen og han kan udtrykke kritik af vores behandling af din henvendelse. Den klageansvarlige kan også vælge at fastholde Letpensions svar på klagen.</p>
                            <p>Den klageansvarliges beslutning skal altid godkendes af Letpensions direktion.</p>
                            <p>Letpensions klageansvarlige hedder Kenneth Handgaard. </p>

                            <p><strong>Kontakt Letpensions klageansvarlige</strong></p>
                            <p>
                                Letpension<br/>
                                Att.: Kenneth Handgaard<br/>
                                Sundkrogsgade 4<br/>
                                2100 København Ø
                            </p>

                            <p></p>
                            <p>Eller via e-mail: <a className="link-dark" href="mailto:klageansvarlig@letpension.dk">klageansvarlig@letpension.dk</a><br />
                                Bemærk, at når du henvender dig til os via e-mail, giver du samtidig samtykke til, at vi må bruge de oplysninger, du sender os, til at behandle din henvendelse. 
                                Du kan læse mere under <SiteLink url="/privatlivogcookies" className="link-dark">”Privatliv og cookies”</SiteLink>.
                            </p>

                            <p></p>
                            <p>Når du kontakter den klageansvarlige, er det vigtigt du oplyser:</p>
							<ul className="list-icons">
								<li><i className="fa fa-caret-right pr-10"></i> Navn</li>
								<li><i className="fa fa-caret-right pr-10"></i> Adresse</li>
								<li><i className="fa fa-caret-right pr-10"></i> Fødselsdato</li>
								<li><i className="fa fa-caret-right pr-10"></i> Telefonnummer</li>
								<li><i className="fa fa-caret-right pr-10"></i> En beskrivelse af din oplevelse med Letpension </li>
							</ul>


                            <p><strong>Hvis du stadig ikke er tilfreds</strong></p>
                            <p>Du kan vælge at indbringe din klage for Ankenævnet for Forsikring eller for domstolene. Vælger du at indbringe klagen for Ankenævnet for Forsikring, anbefaler vi dig at følge <a href="http://ankeforsikring.dk/klageguide/Sider/klageguide.aspx" className="link-dark" target="_blank">Ankenævnets klageguide</a>. Før du klager, er det ifølge Ankenævnet for Forsikring en god idé at sammenholde vores begrundelse for svaret med dine forsikringsbetingelser. Under "Typiske årsager til klager" på Ankenævnets hjemmeside kan du måske finde sager, som ligner din. </p>
                            <p>Du kan se mere på <a href="http://www.ankeforsikring.dk" className="link-dark" target="_blank">www.ankeforsikring.dk</a> </p>

                            <p>Hvis du vælger at indbringe din klage for domstolene, anbefaler vi dig at tage kontakt til en advokat.</p>

                        </div>
                    </div>
                </div>
            </section>


</div>


      );
    }
}

