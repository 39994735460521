import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class TjekDinPension extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Tjek din pension</h3>
                            <div className="separator center"></div>
                            <p>Som kunde i Letpension har du altid mulighed for at se din aftale med os. Du kan fx se, hvor stor din opsparing er, hvad du betaler i omkostninger og hvilket afkast, du har fået.</p>
                            <p>Har du spørgsmål til din aftale eller er der noget, du gerne vil ændre, anbefaler vi dig at kontakte din rådgiver i dit lokale pengeinstitut. Han kan hjælpe dig med at tilpasse din aftale, så den bedst muligt svarer til dit behov.</p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>


			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-md-8">
										<h3 className="title">Brug for at se din aftale med os?</h3>
										<p>Vil du vide mere om, hvordan din aftale med os er sat sammen, kan du altid finde oplysningerne ved at logge ind på din Letportal.</p>
									</div>
									<div className="col-md-4">
										<br/>
										<p><a href="https://www.letportal.dk" className="btn btn-lg btn-gray-transparent btn-animated">Log ind på Letportalen<i className="fa fa-arrow-right pl-20"></i></a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/blivkunde#top" billede="/Resources/images/Letpension/Find_pengeinstitut_457x300.jpg" tekst="Bliv kunde i Letpension" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-5">
                            <ImageLink link="/kontakt#top" billede="/Resources/images/Letpension/Brug_din forsikring_457x300.jpg" tekst="Brug din forsikring" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/omletpension#top" billede="/Resources/images/Letpension/om_Letpension_457x300.png" tekst="Om Letpension" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-5">
                            <ImageLink link="/produktet/omkostninger#top" billede="/Resources/images/Letpension/omkostninger_457x300.jpg" tekst="Omkostninger" />
                        </div>

                </div>
            </section>



</div>


      );
    }
}




