import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class MoedeForberedelse extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Forbered møde med rådgiver</h3>
                            <div className="separator center"></div>
                            <p>Når du sammen med din rådgiver skal se på din pension og forsikring gennem Letpension, sker det givetvis på en anden måde, end du er vant til.</p>
                            <p>Vi har nemlig skabt en elektronisk løsning, der giver dig overblik og gør det nemt for dig at forstå pension og forsikring.</p>
                            <br />     
                            <p><strong>Spørgsmål om dit helbred</strong></p>
                            <p>Er der behov for, at vi kender dit helbred, er det nemt og hurtigt at give os oplysningerne. Du får adgang til vores elektroniske helbredsportal via din netbank, og her kan du i ro og mag svare på de relevante spørgsmål om dit helbred og dit erhverv hjemmefra. Du kan gøre det, når det passer dig. Når du har svaret på spørgsmålene ved din rådgiver med det samme, hvilken løsning han kan tilbyde netop dig. Der er ingen ventetid, og du får den rigtige løsning fra starten.</p>

                            <p><strong>Her er der hjælp at hente</strong></p>
                            <p>Når du svarer på spørgsmål om dit helbred, er det vigtigt, at du så præcist som muligt fortæller os om tidligere eller nuværende sygdomme og sygdomstidspunkt.</p>
                            <p>Hvis du har en digital signatur eller NemID, kan du måske finde hjælp ved at gå ind på <a className="link-dark" href="http://www.sundhed.dk" target="_blank">www.sundhed.dk</a>. Her kan du få en oversigt over, hvornår du eventuelt har været indlagt på hospitalet og for hvad. Vær opmærksom på, at oversigten på www.sundhed.dk kun dækker hospitalsindlæggelser. Derfor er det en god idé også at tænke over, hvornår du ellers har været syg, og hvad du fejlede. Det kan alt sammen hjælpe dig, når du svarer på spørgsmål om dit helbred.</p>
                        </div>
                    </div>
                </div>
            </section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/blivkunde#top" billede="/Resources/images/Letpension/Find_pengeinstitut_457x300.jpg" tekst="Bliv kunde i Letpension" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/omletpension#top" billede="/Resources/images/Letpension/om_Letpension_457x300.jpg" tekst="Om Letpension" />
                        </div>
                    </div>

                </div>
            </section>



</div>


      );
    }
}

