import ApiUtils from '../util/apiUtils.jsx'
import { setLoginMetodeAction, setKundeskiftIgangAction } from "../reducers/values";
import { signalRRegisterCommands } from 'util/signalr';

export const HENT_BRUGER = 'HENT_BRUGER';
export const MODTAG_BRUGER = 'MODTAG_BRUGER';
export const INVALIDER_BRUGER = 'INVALIDER_BRUGER';
export const LOG_UD = 'LOG_UD';

export function hentBruger() {
  return {
    type: HENT_BRUGER,
    logginin: true
  }
}

export function modtagBruger(json, fejl, invalidtoken) {
    return {
        type: MODTAG_BRUGER,
        bruger: json,
        receivedAt: Date.now(),
        fejlmeddelelse: fejl,
        invalidtoken
    }
}

export function skiftKunde(data) {

    return (dispatch) => {
        dispatch(setKundeskiftIgangAction(true));
        ApiUtils.post('/login/fornytoken', data).then(
            json => {
                sessionStorage["lptoken"] = json.token;
                document.cookie = "lptoken=" + json.token;
                signalRRegisterCommands(json.token);
                ApiUtils.get('/bruger').then(
                    json => {
                        dispatch(modtagBruger(json));
                        dispatch(setKundeskiftIgangAction(false));
                    },
                    error => {
                        dispatch(setKundeskiftIgangAction(false));
                    }
                );
            },
            error => {
                dispatch(setKundeskiftIgangAction(false));
            }
        );
    }
}

export function indlaesBruger() {

    return (dispatch) => {

        dispatch(hentBruger());
        let url = ApiUtils.apiRootUrl() + 'bruger';

        let token = ApiUtils.getAuthenticationToken();
        let header = ApiUtils.buildHeader(token);

        return fetch(url,
                {
                    method: 'GET',
                    headers: header
                })
            .then(response => {
                if (response.status === 401) {
                    dispatch(modtagBruger(null, null, true));
                } else if (response.status === 200) {
                    response.json()
                        .then(json => {
                            //console.log('Action indlaes bruger: ' + JSON.stringify(json))
                            dispatch(modtagBruger(json, null, false));
                            dispatch(setLoginMetodeAction(json.loginInfo.loginMetode));
                        });
                } else {
                    console.log('Action bruger error.', response);
                    let fejlTekst = 'Fejl ved indlæsning af bruger';
                    sessionStorage.removeItem("lptoken");
                    dispatch(modtagBruger(null, fejlTekst, false));
                }
            });
    }
}

export function accepterVilkaar() {
    return (dispatch) => {

        dispatch(hentBruger());
        let url = ApiUtils.apiRootUrl() + 'login/accept';

        let token = ApiUtils.getAuthenticationToken();
        let header = ApiUtils.buildHeader(token);

        return fetch(url,
                {
                    method: 'POST',
                    headers: header
                })
            .then(response => {
                if (response.status === 200) {
                    response.json()
                        .then(json => {
                            //console.log('Action indlaes bruger: ' + JSON.stringify(json))
                            sessionStorage["lptoken"] = json.token;
                            document.cookie = "lptoken=" + json.token;
                            sessionStorage["tokenTime"] = new Date().getTime();
                            dispatch(indlaesBruger());
                        });
                } else {
                    console.log('Action accept error.', response);
                    let fejlTekst = 'Fejl ved accept af brugervilkår';
                    sessionStorage.removeItem("lptoken");
                    dispatch(modtagBruger(null, fejlTekst, false));
                }
            });
    }
}

export function vaelgPengeinstitut(hovedRegNr) {
    return (dispatch) => {

        dispatch(hentBruger());
        let url = ApiUtils.apiRootUrl() + 'login/vaelgpi';

        let token = ApiUtils.getAuthenticationToken();
        let header = ApiUtils.buildHeader(token);

        return fetch(url,
                {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify({ hovedRegNr })
                })
            .then(response => {
                if (response.status === 200) {
                    response.json()
                        .then(json => {
                            //console.log('Action indlaes bruger: ' + JSON.stringify(json))
                            sessionStorage["lptoken"] = json.token;
                            document.cookie = "lptoken=" + json.token;
                            sessionStorage["tokenTime"] = new Date().getTime();
                            window.location.href = "/";
                        });
                } else {
                    console.log('Action vælg pengeinstitut error.', response);
                    let fejlTekst = 'Fejl ved valg af pengeinstitut';
                    sessionStorage.removeItem("lptoken");
                    dispatch(modtagBruger(null, fejlTekst, false));
                }
            });
    }
}

export function opretKunde(data) {
    return (dispatch) => {

        dispatch(hentBruger());
        let url = ApiUtils.apiRootUrl() + 'login/opretKunde';

        let token = ApiUtils.getAuthenticationToken();
        let header = ApiUtils.buildHeader(token);

        return fetch(url,
                {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify(data)
                })
            .then(response => {
                if (response.status === 200) {
                    response.json()
                        .then(json => {
                            //console.log('Action indlaes bruger: ' + JSON.stringify(json))
                            sessionStorage["lptoken"] = json.token;
                            document.cookie = "lptoken=" + json.token;
                            sessionStorage["tokenTime"] = new Date().getTime();
                            dispatch(indlaesBruger());
                        });
                } else {
                    console.log('Action opret kunde error.', response);
                    let fejlTekst = 'Fejl ved oprettelse af kunde';
                    sessionStorage.removeItem("lptoken");
                    dispatch(modtagBruger(null, fejlTekst, false));
                }
            });
    }
}

export function logout() {
    sessionStorage.removeItem("lptoken");
    document.cookie = "lptoken=;";
    sessionStorage.removeItem("tokenTime");
    signalRRegisterCommands(null);
    return {
        type: LOG_UD
    }
}


