import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'


import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';
import OmkostningBeregner from './omkostningberegner.jsx';
import { Tabs, Tab } from 'react-bootstrap'

export class OmkostningerStandard extends Component {
    render() {
        return (
            <Omkostninger maxDepotVardi={2500001} udvidet={false} />
        )    
    }
}

export class OmkostningerUdvidet extends Component {
    render() {
        return (
            <Omkostninger maxDepotVardi={99900000} udvidet={true} />
        )    
    }
}

class Omkostninger extends Component {
    render() {

        return (

        <div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Vi lægger ikke skjul på, hvad tingene koster</h3>
                            <div className="separator center"></div>
                            <p>I Letpension lægger vi ikke skjul på, hvad tingene koster. Med omkostningsberegneren giver vi dig et hurtigt overblik over dine årlige omkostninger ved at have en Letsikring af indtægt ved pension.  Du kan også læse om forudsætningerne for omkostningsberegneren, få en forklaring på, hvad ÅOK/ÅOP er, samt et overblik over vores gebyrer.</p>
                            <p>Er du allerede kunde i Letpension, kan du se omkostningerne for netop din Letsikring i Letportalen ved at bruge "Log ind" i øverste højre hjørne her på siden eller via din netbank.</p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>

			<section className="section clearfix">
				<div className="container white-bg">
			        <Tabs defaultActiveKey={1} id="tabs">
			            <Tab eventKey={1} title={<div><i className="fa fa-calculator pr-10"></i>Omkostningsberegner</div>}>
			                <div className="letTabContent active">
			                    <OmkostningBeregner maxDepotVardi={this.props.maxDepotVardi} udvidet={this.props.udvidet} />
			                </div>
			            </Tab>
			            <Tab eventKey={2} title={<div><i className="fa fa-file-text pr-10"></i>Forudsætninger</div>}>
						    <div className="letTabContent">
    						    <div className="row">
								    <div className="col-md-6">
                                        <h4 className="title">Forudsætninger for omkostningsberegneren</h4>
                                        <p>Beregningen af de årlige omkostninger for en Letsikring af indtægt ved pension er vejledende. Resultatet er altså ikke udtryk for en præcis opgørelse af de faktiske omkostninger.</p>
                                        <p>Vi forudsætter, at de indbetalinger du indtaster, alene vedrører opsparing og at indbetalingerne sker i starten af året. Det er ikke muligt at foretage beregninger for Letsikringer, der bliver udbetalt penge fra.</p>
                                        <p>Vi forudsætter desuden, at 5 % af opsparingen er placeret i PFA KundeKapital. Du kan læse med om PFA KundeKapital <a href="https://pfa.dk/privat/opsparing/pfa-kundekapital/" className="link-dark" target="_blank">her</a>.</p>

                                        <h4 className="title">Arbejdsgiver eller privat</h4>
                                        <p>Sker dine indbetalinger via en arbejdsgiver, bliver arbejdsmarkedsbidraget på 8 % trukket fra i dine indbetalinger. Indbetaler du pengene privat, bliver arbejdsmarkedsbidraget ikke trukket fra i dine indbetalinger, fordi du allerede har betalt arbejdsmarkedsbidrag af pengene.</p>

                                        <h4 className="title">Direkte omkostninger</h4>
                                        <p>De direkte omkostninger består af en fast betaling i kroner, en procent af indbetalingen og en procent af opsparingen.</p>
                                        <p>Omkostningerne bliver beregnet ud fra startsaldoen og en forventning om, at indbetalingen sker i starten af året og afkastet tilskrives sidst på året. Derfor kan der være marginale udsving, hvis din indbetaling eller dit afkast afviger fra dette.</p>

                                        <h4 className="title">Indirekte omkostninger</h4>
                                        <p>De indirekte omkostninger bliver fastlagt ud fra den valgte investeringsprofil og afhænger af, hvor lang tid der er, til du går på pension.</p>
                                        <p>Omkostningerne bliver beregnet ud fra startsaldoen og en forventning om, at indbetalingen sker i starten af året og afkastet tilskrives sidst på året. Derfor kan der være marginale udsving, hvis din indbetaling eller dit afkast afviger fra dette.</p>

								    </div>
								    <div className="col-md-6">
                                        <h4 className="title">Sådan beregner vi afkast</h4>
                                        <p>Til at beregne afkast på opsparingen bruger vi de fælles brancheforudsætninger. Det betyder, at vi regner med et afkast på 2 % på obligationer og 5 % på aktier og ejendomme. Derfor afhænger afkastet af den valgte investeringsprofil og hvor lang tid der er, til du går på pension. På den del af saldoen der er placeret i KundeKapital regner vi med et afkast på 10 % efter indirekte omkostninger.</p>

                                        <h4 className="title">Omkostninger i kroner og ører</h4>
                                        <p>Har du en Letsikring af indtægt ved pension, betaler du omkostninger af den del af opsparingen som er placeret i dit investeringsvalg, men du betaler ikke omkostninger af den del af din opsparing der er placeret i PFA KundeKapital.</p>
                                        <p>Af den del af saldoen der er placeret i dit investeringsvalg, betaler du 0,99 % af opsparingen hvert år. Hvis din saldo er over 250.000 kr. betaler du dog kun 0,75 % af den del der overstiger 250.000 kr. og hvis din saldo er over 500.000 kr. så betaler du kun 0,35 % af den del der overstiger 500.000 kr.</p>
                                        <p>Vi har forudsat, at du har valgt at sikre din opsparing, så den kommer til udbetaling, hvis du dør, før du begynder at få udbetaling fra din Letsikring. Denne sikring betaler du et mindre beløb for. Af dette beløb betaler du 4 % i omkostninger.</p>
                                        <p>Udover de nævnte omkostninger, er der knyttet omkostninger til investering af opsparingen. Hos mange selskaber er disse omkostninger ikke umiddelbart synlige. Omkostningerne udgør 0,38 % af opsparingen per år, hvis din opsparing er investeret i Lav risiko fonden eller Varighedsfonden og 0,80 % af opsparingen per år, hvis din opsparing er investeret i Høj risiko fonden. Desuden betaler du 0,52 % i omkostninger af opsparingen i PFA KundeKapital.</p>
                                        <p>Omkostningerne til investering bliver trukket fra, inden du får afkast fra fondene.</p>
                                        <p>Alle omkostninger bliver beregnet og trukket hver måned.</p>

								    </div>
						        </div>
						    </div>
			            </Tab>
			            <Tab eventKey={3} title={<div><i className="fa fa-percent pr-10"></i>Hvad er ÅOK/ÅOP</div>}>
						    <div className="letTabContent">
							    <div className="row">
							        <div className="col-md-6">
							            <h4 className="title">Årlige omkostninger i kroner</h4>
							            <p>ÅOK er en forkortelse for "årlige omkostninger i kroner". Taller er udtryk for de årlige omkostninger, du betaler for at have din Letsikring - angivet i kroner.</p>
							        </div>
							        <div className="col-md-6">
							            <h4 className="title">Årlige omkostninger i procent</h4>
							            <p>ÅOP er en forkortelse for "årlige omkostninger i procent". Tallet er udtryk for de årlige omkostninger, du betaler for at have din Letsikring i procent og bliver beregnet som de årlige omkostninger i kroner (ÅOK) divideret med opsparingens størrelse efter et år.</p>
							        </div>
							    </div>
						    </div>
			            </Tab>
			            <Tab eventKey={4} title={<div><i className="fa fa-usd pr-10"></i>Gebyrer</div>}>
			                <div className="letTabContent">
			                    <div className="row">
			                        <div className="col-md-7">
			                            <h4 className="title">Engangsomkostninger</h4>
			                            <p>Nogle ændringer af Letsikringer falder ikke under de løbende direkte omkostninger, du betaler for din Letsikring. For sådanne ændringer betaler du derfor omkostningerne enkeltvis. Det drejer sig om:</p>
			                            <ul className="list-icons">
			                                <li><i className="fa fa-caret-right pr-10"></i> Omkostning ved overførsel til anden leverandør: 1.800 kr</li>
			                                <li><i className="fa fa-caret-right pr-10"></i> Omkostning ved udbetaling af opsparing før tid (ophævelse): 1.800 kr. Derudover skal du betale en afgift til staten på 60 %.</li>
			                                <li><i className="fa fa-caret-right pr-10"></i> Omkostning ved ændringer, der kræver komplekse beregninger: 1.000 kr.*</li>
			                                <li><i className="fa fa-caret-right pr-10"></i> Omkostning ved ændring af sikring for udbetaling: 50 kr.</li>
			                                <li><i className="fa fa-caret-right pr-10"></i> Omkostning ved ændring af investeringsprofil: 200 kr</li>
			                                <li><i className="fa fa-caret-right pr-10"></i> Omkostning ved tilvalg eller fravalg af ægtefælledækning: 100 kr.</li>
			                                <li><i className="fa fa-caret-right pr-10"></i> Omkostning ved ændring af valg af KundeKapital: 100 kr</li>
			                                <li><i className="fa fa-caret-right pr-10"></i> Omkostning ved indbetalingsfri ordninger</li>
			                            </ul>
			                        </div>
			                        <div className="col-md-5">
			                            <h4 className="title">Omkostning ved indbetalingsfri ordninger</h4>
			                            <p>Du betaler samme omkostninger af opsparingen som på ordninger med indbetaling. Der er ingen yderligere omkostninger.</p>

			                            <p>*Omfatter for eksempel særlige prognoseberegninger i forbindelse med pensionering, hvis det ikke er muligt at imødekomme dine ønsker i Letpensions Rådgiverportal.</p>

			                        </div>
			                    </div>
			                </div>
			            </Tab>
                    </Tabs>
                </div>
            </section>


			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-md-8">
										<h3 className="title">Hvordan bliver dine penge investeret? </h3>
										<p>Vil du vide mere om, hvordan vi investerer dine penge, og hvad du får i afkast, kan du se mere her.</p>
									</div>
									<div className="col-md-4">
										<br/>
										<p><SiteLink url="/produktet/investering#top" classname="btn btn-lg btn-gray-transparent btn-animated">Se mere om investering<i className="fa fa-arrow-right pl-20"></i></SiteLink></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/blivkunde#top" billede="/Resources/images/Letpension/Find_pengeinstitut_457x300.jpg" tekst="Bliv kunde i Letpension" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>



        </div>


      );
    }
}


