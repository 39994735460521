import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';
import { Accordion, AccordionGroup } from "components/common/accordion.jsx";

export default class Skatteregler extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Skatteregler</h3>
                            <div className="separator center"></div>
                            <p>Hos Letpension kan du have Letsikringer som det, man på "forsikringssprog" kalder:</p>
							<ul className="list-icons">
								<li><i className="fa fa-caret-right pr-10"></i> Skattekode 1 – Pensionsordning med løbende udbetaling, bortset fra ophørende livrenter</li>
								<li><i className="fa fa-caret-right pr-10"></i> Skattekode 2 – Rateforsikring i pensionsøjemed</li>
								<li><i className="fa fa-caret-right pr-10"></i> Skattekode 3 – Kapitalforsikring i pensionsøjemed</li>
								<li><i className="fa fa-caret-right pr-10"></i> Skattekode 5 – Forsikring med engangsudbetaling</li>
							</ul>

                            <p>Som udgangspunkt er der fradrag for betalinger til skattekode 1 og 2. For skattekode 2 er der en øvre grænse for, hvor meget du samlet set kan betale for stadig at have fradrag for betalingen. Der er grænser for, hvor store udbetalinger du kan få på nogle af Letsikringerne. Derfor anbefaler vi dig, at du kontakter din rådgiver i dit lokale pengeinstitut, så I sammen kan finde ud af, hvad der er den bedste løsning for dig.</p>
                            <p>Herunder kan du se de gældende regler for de forskellige skattekoder.</p>
                        </div>
                    </div>
                </div>
            </section>


			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">

					    	<AccordionGroup id="gruppe">
						        <Accordion titel="Særligt om SKAT og samspillet med din årsopgørelse" mountOnEnter={true}>
									<div className="panel-body">
										<p>Dine indbetalinger til skattekode 1, som ikke bliver betalt via en anden pensionsordning, bliver indberettet til SKAT. Det betyder dog ikke nødvendigvis, at du automatisk får det fradrag for dine indbetalinger, som du skal have. Derfor skal du holde øje med din årsopgørelse og kontrollere, om du har fået de rigtige fradrag. Nedenfor beskriver vi, hvordan du kan kontrollere din årsopgørelse, alt efter hvilken aftale, du har hos os/PFA Pension.</p>
                                        <p><strong>Letsikring ved sygdom, Tab af erhvervsevne Basis eller Letsikring af barn ved død, som du ikke betaler fra en pensionskonto samt Letsikring af indtægt ved pension, som du betaler privat</strong></p>

										<p>PFA indberetter dine indbetalinger til SKAT efter de til enhver tid gældende regler. Det betyder, at din skattemappe løbende bliver opdateret.</p>
                                        <p>Hvis du ønsker at ændre eller tilføje noget til disse oplysninger, kan du inden den 1 maj rette oplysningerne tre kalenderår bagud.</p>


										<p>På årsopgørelsen skal dit fradrag fremgå af rubrik 24: <em>Bidrag til privattegnede livsvarige pensionsordninger</em>.</p>
										<p>På forskudsopgørelsen skal dit fradrag fremgå af rubrik 436: <em>Bidrag til privattegnede livsvarige pensionsordninger</em>.</p>
										<p>Du kan kontrollere og ændre oplysningerne ved at logge på SKAT.dk eller ved at ringe til SKAT på tlf. 7222 1818.</p>

                                        <p><strong>Overførsel fra ratepension til Letsikring af indtægt ved pension - hvis du har betalt for meget ind på din ratepension</strong></p>
										<p>Hvis du har betalt for meget ind på din ratepension, korrigerer SKAT automatisk din årsopgørelse, så du kun får det fradrag, du er berettiget til.</p>
										<p>Er din ratepension privat, bliver det samlede fradrag reduceret til 54.700 kr. (2018).</p>
										<p>Bliver din ratepension betalt via din arbejdsgiver, bliver din indtægt forhøjet med den betaling, der ligger ud over 54.700 kr. (2018).</p>
										<p>Du kan rette indbetalingerne ved at få dit pengeinstitut til at overføre beløbet fra din ratepension til en Letsikring af indtægt ved pension. Overførslen bliver indberettet til SKAT.</p>
										<p>Når overførslen er indberettet til SKAT, skal du rette din årsopgørelse.</p>
										<p>Hvis din ratepension er privat, skal du indtaste det beløb, der er overført fra ratepensionen til din Letsikring af indtægt ved pension, i årsopgørelsens rubrik 24: <em>Bidrag til privattegnede livsvarige pensionsordninger</em>. På forskudsopgørelsen skal du angive beløbet i rubrik 436: <em>Bidrag til privattegnede livsvarige pensionsordninger</em></p>
										<p>Hvis din ratepension bliver betalt via din arbejdsgiver, skal din indtægt sættes ned. Du kan kontrollere indtægten ved at sammenligne indtægten på din nye årsopgørelse med indtægten på din første årsopgørelse, men du kan ikke selv rette indtægten. Derfor skal du ringe til SKAT på tlf. 7222 1818 for at få indtægten rettet.</p>

                                        <p><strong>Indbetaling på Letsikring af indtægt ved pension via din arbejdsgiver</strong></p>
										<p>Din arbejdsgiver indberetter de beløb, som arbejdsgiveren indbetaler for det kalenderår, som beløbene vedrører. PFA indberetter betalingerne for det kalenderår, hvor beløbene er modtaget i PFA. Du skal ikke foretage dig noget.</p>
										<p>Hvis betalingen sker tæt på årsskiftet, kan PFA's indberetning derfor være i et andet kalenderår end arbejdsgiverens. Dermed vil oplysninger i din skattemappe ikke stemme med de beløb, som arbejdsgiveren har indberettet. Det har ifølge SKAT ingen betydning og du behøver derfor ikke holde øje med, om du får dit fradrag, når din indbetaling sker via din arbejdsgiver.</p>
										<p>Nedenfor kan du læse mere om, hvordan du kan få fradrag for betalinger til dine pensioner og forsikringer og hvordan du eller dine efterladte bliver beskattet af udbetalinger.</p>

									</div>
						        </Accordion>
						        <Accordion titel="Skattekode 1 - Pensionsordning med løbende udbetaling, bortset fra ophørende livrenter" mountOnEnter={true}>
									<div className="panel-body">
										<p>Du kan have disse Letsikringer hos Letpension, som hører til under skattekode 1:</p>
							            <ul className="list-icons">
								            <li><i className="fa fa-caret-right pr-10"></i> Letsikring ved sygdom</li>
								            <li><i className="fa fa-caret-right pr-10"></i> Tab af erhvervsevne Basis</li>
								            <li><i className="fa fa-caret-right pr-10"></i> Letsikring af barn ved død</li>
								            <li><i className="fa fa-caret-right pr-10"></i> Letsikring af indtægt ved pension (kan være oprettet både med garantiperiode og/eller dækning af ægtefælle/samlever)</li>
							            </ul>
                                        <fieldset className="white-bg">
                                            <legend>Hvordan kan du få fradrag?</legend>

                                            <p><strong>Letsikring ved sygdom, Tab af erhvervsevne Basis og Letsikring af barn ved død betalt via frie midler, samt Letsikring af indtægt ved pension oprettet privat</strong></p>

                                            <p>Reglerne for fradrag bliver styret af, hvor længe betalingen løber og hvor længe eventuelle forhøjelser af betalingen løber.</p>
                                            <p>Du får fradrag for din betaling for din Letsikring. PFA indberetter automatisk beløbet til SKAT, og du kan tjekke det i rubrik 24 i din selvangivelse og i rubrik 436 i din forskudsopgørelse.</p>

                                            <p>Du kan få fradrag for betalinger til private pensions- og forsikringsordninger på op til 50.400 kr. (2018) hvert år. Beløbsgrænsen på 50.400 kr. (2018) bliver kaldt et opfyldningsfradrag. Du har kun ét samlet opfyldningsfradrag for alle private betalinger til skattekode 1. Overstiger dine samlede betalinger grænsen, kan du trække dine betalinger fra efter én af følgende regler:</p>

                                            <p><strong>1) Betaling i MERE end 10 år</strong></p>
                                            <p>Hvis du har aftalt at betale i en periode på 10 år eller mere, kan du trække den fulde betaling fra i skat hvert år.</p>
                                            <p><em>Eksempel: Du betaler 120.000 kr. hvert år i 10 år eller mere og kan derfor trække den fulde betaling på 120.000 kr. fra i skat hvert år.</em></p>

                                            <p><strong>2) Betaling i MINDRE end 10 år</strong></p>
                                            <p>Hvis du har aftalt at betale i en periode, der er kortere end 10 år, skal du fordele dine samlede betalinger ligeligt over 10 år for at regne ud, hvor meget du kan få fradrag for. Det gælder også eventuelle forhøjelser af betalingen, hvis forhøjelserne gælder for en periode på mindre end 10 år.</p>
                                            <p><em>Eksempel: Du betaler 80.000 kr. hvert år i fem år. Din samlede aftalte betaling er derfor 400.000 kr. Når du fordeler dine samlede betalinger over 10 år, svarer det til, at du kan trække 1/10, altså 40.000 kr., fra i skat hvert år i 10 år. Derudover har du altid mulighed for at fylde op til grænsen på 50.400 kr. (2018), hvis din betaling er på minimum 50.400 kr. (2018) om året. I eksemplet her kan du altså trække 50.400 kr. (2018) fra om året, indtil du har trukket den samlede betaling på 400.000 kr. fra i skat.</em></p>

                                            <p><strong>3) Engangsbetaling</strong></p>
                                            <p>Hvis du kun betaler til ordningen én gang, kan du trække beløbet fra med 1/10 af betalingen hvert år i 10 år. Hvis 1/10 af betalingen er mindre end 50.400 kr. (2018), kan du vælge at trække op til 50.400 kr. (2018) fra i skat hvert år, indtil du har trukket hele betalingen fra i skat. Du kan ikke trække mere fra, end du har betalt.</p>
                                            <p><em>Eksempel 1: Du betaler et engangsbeløb på 600.000 kr. Du kan derfor trække 1/10, svarende til 60.000 kr., fra i skat hvert år i 10 år.</em></p>
                                            <p><em>Eksempel 2: Du betaler et engangsbeløb på 300.000 kr. Da 1/10 af beløbet, altså 30.000 kr., er under grænsen på 50.400 kr. (2018), kan du trække 50.400 kr. (2018) fra hvert år, indtil du har trukket den samlede betaling på 300.000 kr. fra i skat.</em></p>
                                            <p>Hvis du er selvstændig erhvervsdrivende, bliver grænsen for opfyldningsfradraget ændret fra 50.400 kr. (2018) til 30 % af din virksomheds overskud før renter - dog mindst 50.400 kr. (2018) Bemærk, at du som selvstændig kan sætte op til 54.700 kr. (2018) ind på en ratepension (skattekode 2), samtidig med, at du sætter op til 30 % af dit overskud ind på en Letsikring af indtægt ved pension (livrente - skattekode 1).</p>

                                            <p><strong>Letsikring af indtægt ved pension oprettet via arbejdsgiver</strong></p>
                                            <p>Der er ingen grænse for, hvor meget du kan betale og trække fra i skat, hvis din Letsikring af indtægt ved pension er oprettet via din arbejdsgiver. Betalingen til din pension via din arbejdsgiver bliver trukket fra i din løn, inden du betaler skat og derved får du fradraget med det samme.</p>

                                            <p><strong>Letsikringer betalt via en anden pensionsordning</strong></p>
                                            <p>Du får IKKE fradrag for betalingerne til din Letsikring, hvis betalingen sker med penge fra en anden pensionsordning, hvor du allerede har fået fradrag for betalingen. Hvis det er tilfældet, bliver din Letsikring betalt via din ratepension eller din kapitalpension i dit pengeinstitut.</p>
                                        </fieldset>

                                        <fieldset className="white-bg">
                                            <legend>Hvordan bliver udbetalingen beskattet?</legend>
                                            <p>Udbetalinger fra din Letsikring er personlig indkomst, som du skal betale skat af. Du skal ikke betale arbejdsmarkedsbidrag af udbetalingerne. Løbende udbetaling ved din død, der bliver ændret til en engangsudbetaling, bliver beskattet med en afgift til staten på 40 % samt eventuel boafgift.</p>

                                            <p><strong>Særligt for Letsikring af indtægt ved pension</strong></p>
                                            <p>I en overgangsperiode frem til og med 2019 kan udbetalingerne fra din Letsikring af indtægt ved pension blive omfattet af udligningsskatten, som gælder for store pensionsudbetalinger. Løbende udbetalinger på over 397.000 kr. (2018) om året er omfattet af udligningsskatten. Vi anbefaler, at du taler med din rådgiver om dine muligheder, hvis du får store løbende udbetalinger.</p>
                                            <p>Hvis den årlige udbetaling fra en Letsikring af indtægt ved pension, der er oprettet via din arbejdsgiver, er mindre end 10.700 kr. (2018), kan du ændre den løbende udbetaling til en engangsudbetaling, som du skal betale 40 % i afgift af til staten.</p>
                                            <p>Hvis udbetalingen fra en garantiperiode på en Letsikring af indtægt ved pension sker til en af følgende personer i forbindelse med din død, kan de vælge mellem løbende udbetaling eller en engangsudbetaling:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i> din ægtefælle/registrerede partner</li>
								                <li><i className="fa fa-caret-right pr-10"></i> din tidligere ægtefælle</li>
								                <li><i className="fa fa-caret-right pr-10"></i> din samlever</li>
								                <li><i className="fa fa-caret-right pr-10"></i> dine børn, samlevers børn eller stedbørn under 24 år</li>
							                </ul>
                                            <p>Sker udbetalingen til andre, herunder børn over 24 år, skal udbetalingen ske som en engangsudbetaling.</p>
                                        </fieldset>

                                        <fieldset className="white-bg">
                                            <legend>Uudnyttet fradrag i forbindelse med dødsfald</legend>
                                            <p>Hvis du for eksempel har oprettet en Letsikring af indtægt ved pension med et stort engangsindskud og dør, før du har fået fuldt fradrag for engangsindskuddet, gælder der særlige regler for udnyttelse af det resterende fradrag.</p>
                                            <p><strong>Generelt</strong></p>
                                            <p>I dødsåret er det kun muligt at udnytte fradraget i dødsboet. Fradragsværdien vil her være 50 %. Efter udnyttelse af fradraget vil en eventuel negativ indkomst i dødsåret kunne overføres til den efterlevende ægtefælle, men det er altså ikke muligt at overføre selve fradraget.</p>
                                            <p>Hvis der ikke er en efterladt ægtefælle og dødsboet ikke er dødsboskattepligtigt, er det ikke muligt at udnytte fradraget.</p>
                                            <p>Fra året efter dødsåret kan ægtefællen udnytte fradraget i den resterende periode, indtil fradraget er fuldt afskrevet. Fradragsværdien afhænger af ægtefællens indkomstforhold og kan være op mod ca. 52 %. Alternativt kan ægtefællen anvende det resterende fradrag til at nedsætte afgiften ved konvertering af en eventuel garantiperiode jf. nedenfor.</p>
                                            <p>Har du ikke en ægtefælle eller er dødsboet så lille, at det ikke forventes at blive skattepligtigt, er det en god idé at tage det med i overvejelsen om at foretage private indskud, der ikke kan fradrages i indskudsåret. Grænsen for, hvornår dødsboet bliver skattepligtigt er 2.839.100 kr. (2018).</p>

                                            <p><strong>Hvis ordningen har startet udbetaling</strong></p>
                                            <p>Dør du fra et uafskrevet fradrag, kan fradraget som udgangspunkt kun udnyttes på din Letsikring af indtægt ved pension, hvis udbetalingen er startet og det beløb, der bliver udbetalt i forbindelse med dødsfaldet er "garantiperiode konverteret til et engangsbeløb". I den situation kan afgiftsgrundlaget for beregning af de 40 % i afgift til staten nedsættes med det uafskrevne beløb efter udnyttelse af fradraget i dødsåret jf. ovenfor.</p>

                                            <p><strong>Hvis ordningen ikke har startet udbetaling</strong></p>
                                            <p>Det uafskrevne fradrag kan ikke udnyttes i en eventuel udbetaling af sikring af saldo - heller ikke selvom der betales 40 % af udbetalingen i afgift til staten. Det skyldes, at sikringen er en separat dødsfaldsdækning. Fradraget for denne del sker under reglerne om rateloft (skattekode 2), mens det uudnyttede fradrag vedrører reglerne for Letsikring af indtægt ved pension (skattekode 1).</p>

                                        </fieldset>


                                        <fieldset className="white-bg">
                                            <legend>Uudnyttet fradrag i forbindelse med tilbagekøb</legend>
                                            <p>Har du en Letsikring af indtægt ved pension, som du ønsker at tilbagekøbe - det vil sige ophæve din forsikring før pensionering - er det muligt mod et gebyr til PFA Pension på 1.660 kr. (2018) og en afgift til staten på 60 %. Hvis saldoen på din Letsikring er indtægt ved pension er under 5.000 kr., opkræver vi ikke gebyret, men du skal stadig betale 60 % i afgift til staten.</p>
                                            <p>Bemærk, at der kan være krav om nye helbredsoplysninger ved tilbagekøb.</p>
                                            <p>Har du ved tilbagekøbet uudnyttet fradrag, kan denne del udbetales uden afgift. Det vil sige, at hvis du et år har indskudt fx 250.000 kr., som fradrages med 1/10 om året, og du vælger at tilbagekøbe din Letsikring af indtægt ved pension efter 5 år, kan du få udbetalt 125.000 kr. uden afgift. For den resterende del skal du betale 60 % i afgift.</p>

                                        </fieldset>

									</div>
						        </Accordion>
						        <Accordion titel="Skattekode 2 - Rateforsikring i pensionsøjemed" mountOnEnter={true}>
									<div className="panel-body">

										<p>Du kan have tre Letsikringer hos Letpension, som hører under skattekode 2:</p>
							            <ul className="list-icons">
								            <li><i className="fa fa-caret-right pr-10"></i> Invalidesum Basis</li>
								            <li><i className="fa fa-caret-right pr-10"></i> Letsikring ved død</li>
								            <li><i className="fa fa-caret-right pr-10"></i> Sikring af opsparing</li>
							            </ul>
                                        <fieldset className="white-bg">
                                            <legend>Hvordan kan du få fradrag?</legend>
                                            <p>Du får IKKE fradrag for betalingerne til din Letsikring, hvis betalingen sker med penge fra en anden pensionsordning, hvor du allerede har fået fradrag for betalingen. Hvis det er tilfældet, bliver din Letsikring betalt via din ratepension eller kapitalpension i dit pengeinstitut.</p>
                                            <p>Har du en sikring af opsparing, der er skattekode 2 og IKKE betalt via en pensionsordning, kan du få fradrag for betalingen for sikringen af opsparingen. Du skal indtaste beløbet i rubrik 21 i såvel årsopgørelsen som forskudsopgørelsen.</p>
                                        </fieldset>

                                        <fieldset className="white-bg">
                                            <legend>Hvordan er de generelle regler for fradrag?</legend>

                                            <p>Du kan hvert år få fradrag for betalinger til skattekode 2 på op til 54.700 kr. (2018) efter arbejdsmarkedsbidrag. Du skal angive beløbet i rubrik 21 i såvel årsopgørelsen som forskudsopgørelsen.</p>

                                        </fieldset>

                                        <fieldset className="white-bg">
                                            <legend>Hvordan bliver udbetalingen beskattet?</legend>

                                            <p>Udbetalinger fra din Letsikring er personlig indkomst, som modtageren skal betale skat af. Modtageren skal ikke betale arbejdsmarkedsbidrag af udbetalingerne. Løbende udbetaling, der bliver ændret til en engangsudbetaling, bliver beskattet med en afgift til staten på 40 % samt eventuel boafgift.</p>

                                        </fieldset>

                                        <p><strong>Særligt for Letsikring ved død</strong></p>
                                        <p>Hvis udbetalingen fra en Letsikring ved død sker til en af følgende personer, kan de vælge mellem løbende udbetaling eller en engangsudbetaling:</p>
							            <ul className="list-icons">
								            <li><i className="fa fa-caret-right pr-10"></i> din ægtefælle/registrerede partner</li>
								            <li><i className="fa fa-caret-right pr-10"></i> din tidligere ægtefælle</li>
								            <li><i className="fa fa-caret-right pr-10"></i> din samlever</li>
								            <li><i className="fa fa-caret-right pr-10"></i> dine børn, samlevers børn og stedbørn under 24 år</li>
							            </ul>

										<p>Sker udbetalingen til andre, herunder børn over 24 år, skal udbetalingen ske som en engangsudbetaling.</p>

									</div>
						        </Accordion>
						        <Accordion titel="Skattekode 3 - Kapitalforsikring i pensionsøjemed" mountOnEnter={true}>
						            <div className="panel-body">

						                <p>Du kan have disse Letsikringer hos Letpension, som hører til under skattekode 3:</p>
						                <ul className="list-icons">
						                    <li><i className="fa fa-caret-right pr-10"></i> Letsikring ved død</li>
						                    <li><i className="fa fa-caret-right pr-10"></i> Invalidesum Basis</li>
						                </ul>
						                <fieldset className="white-bg">
						                    <legend>Hvordan kan du få fradrag?</legend>

						                    <p>Du har IKKE fradrag for betalinger til din Letsikring, da betalingen sker med penge fra en kapitalpension i dit pengeinstitut, hvor du allerede har fået fradrag for betalingen.</p>
						                </fieldset>

						                <fieldset className="white-bg">
						                    <legend>Hvordan bliver udbetalingen beskattet?</legend>

						                    <p>Modtageren skal som udgangspunkt betale 40 % af udbetalingen i afgift til staten. afgiften er som udgangspunkt på 40 %. Hvis der er tale om en udbetaling ved din død, skal modtageren af udbetalingen eventuelt betale boafgift.</p>

						                </fieldset>

						            </div>
						        </Accordion>
						        <Accordion titel="Skattekode 5 - Forsikring med engangsudbetaling" mountOnEnter={true}>
						            <div className="panel-body">

						                <p>Du kan have følgende Letsikringer hos Letpension, som hører under skattekode 5:</p>
						                <ul className="list-icons">
						                    <li><i className="fa fa-caret-right pr-10"></i> Invalidesum Basis</li>
						                    <li><i className="fa fa-caret-right pr-10"></i> Letsikring ved død</li>
						                    <li><i className="fa fa-caret-right pr-10"></i> Letsikring af lån og kredit</li>
						                    <li><i className="fa fa-caret-right pr-10"></i> Livsforsikring Basis (70)</li>
						                    <li><i className="fa fa-caret-right pr-10"></i> Letsikring ved kritisk sygdom</li>
						                    <li><i className="fa fa-caret-right pr-10"></i> Sikring af opsparing</li>
						                </ul>
						                <fieldset className="white-bg">
						                    <legend>Hvordan kan du få fradrag?</legend>

						                    <p>Du har IKKE fradrag for betalinger til din Letsikring.</p>
						                </fieldset>

						                <fieldset className="white-bg">
						                    <legend>Hvordan bliver udbetalingen beskattet?</legend>

						                    <p>Modtageren skal ikke betale skat eller afgift til staten af udbetalingen. Hvis der er tale om en udbetaling ved din død, skal modtageren af udbetalingen eventuelt betale boafgift.</p>

						                </fieldset>

						            </div>
						        </Accordion>
                            </AccordionGroup>

						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/produktet/omkostninger#top" billede="/Resources/images/Letpension/omkostninger_457x300.jpg" tekst="Omkostninger" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>



</div>


      );
    }
}




