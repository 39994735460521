import {
  HENT_BRUGER,
  MODTAG_BRUGER,
  INVALIDER_BRUGER,
  LOG_UD,
} from '../actions/bruger.jsx'

export default function bruger(
        state = {},
        action
    )
{
  switch (action.type) {
    case HENT_BRUGER:
        return Object.assign({}, state, { indlaeser: true });
    case MODTAG_BRUGER:
        return Object.assign({},
            state,
            {
                fejlmeddelelse: action.fejlmeddelelse,
                bruger: action.bruger,
                indlaeser: false,
                fatalfejl: !!state.fejlmeddelelse,
                invalidtoken: action.invalidtoken
            });
    case INVALIDER_BRUGER:
        return {};
    case LOG_UD:
        return Object.assign({},
            state,
            {
                fejlmeddelelse: null,
                bruger: null,
                indlaeser: null,
                fatalfejl: null,
                invalidtoken: false,
                loggetud: true
            });        
    default:
      return state;
  }
}


