import ApiUtils from 'util/apiUtils.jsx'

export const HENT_LETSIKRINGER = 'HENT_LETSIKRINGER';
export const MODTAG_LETSIKRINGER = 'MODTAG_LETSIKRINGER';
export const INVALIDER_LETSIKRINGER = 'INVALIDER_LETSIKRINGER';

export const HENT_LETSIKRING_AENDRINGSDATO = 'HENT_LETSIKRING_AENDRINGSDATO';
export const MODTAG_LETSIKRING_AENDRINGSDATO = 'MODTAG_LETSIKRING_AENDRINGSDATO';

export const HENT_LETSIKRING_DEPOTREGNSKAB = 'HENT_LETSIKRING_DEPOTREGNSKAB';
export const MODTAG_LETSIKRING_DEPOTREGNSKAB = 'MODTAG_LETSIKRING_DEPOTREGNSKAB';

export function getLetsikringerListeState(state) {
    return {
        indlaeser: state.letsikringer.indlaeserListe,
        fejlmeddelelse: state.letsikringer.fejlmeddelelseListe,
        liste: state.letsikringer.liste
    };
}

export function getLetsikringerAendringsDatoState(state, letsikringNr, aendringsDato) {
    return {
        indlaeser: state.letsikringer[`indlaeserAendringsDato/${letsikringNr}/${aendringsDato}`],
        fejlmeddelelse: state.letsikringer[`fejlmeddelelseAendringsDato/${letsikringNr}/${aendringsDato}`],
        letsikring: state.letsikringer[`aendringsDato/${letsikringNr}/${aendringsDato}`]
    };
}

export function getLetsikringerDepotregnskabState(state, letsikringNr, aarstal) {
    return {
        indlaeser: state.letsikringer[`indlaeserDepotregnskab/${letsikringNr}/${aarstal}`],
        fejlmeddelelse: state.letsikringer[`fejlmeddelelseDepotregnskab/${letsikringNr}/${aarstal}`],
        depotregnskab: state.letsikringer[`depotregnskab/${letsikringNr}/${aarstal}`]
    };
}

function hentLetsikringer() {
    return {
        type: HENT_LETSIKRINGER
    };
}

function modtagLetsikringer(state, liste, fejl) {
    return {
        type: MODTAG_LETSIKRINGER,
        liste: liste,
        receivedAt: Date.now(),
        fejlmeddelelse: fejl
    };
}

export function indlaesLetsikringer() {
    return (dispatch, getState) => {
        let state = getState();
        if (getLetsikringerListeState(state).indlaeser) {
            return null;
        }

        dispatch(hentLetsikringer());

        return ApiUtils.get('letsikringer')
            .then(
                liste => {
                    dispatch(modtagLetsikringer(getState(), liste));
                },
                error => {
                    console.log('Action indlaesLetsikringer error.', error);
                    dispatch(modtagLetsikringer(getState(), null, 'Fejl ved indlæsning af letsikringer'));
                }
            );
    };
}

export function invaliderLetsikringer() {
    return {
        type: INVALIDER_LETSIKRINGER
    };
}

function hentLetsikringAendringsDato(letsikringNr, aendringsDato) {
    return {
        type: HENT_LETSIKRING_AENDRINGSDATO,
        letsikringNr: letsikringNr,
        aendringsDato: aendringsDato
    };
}

function modtagLetsikringAendringsDato(state, letsikring, letsikringNr, aendringsDato, fejl) {
    return {
        type: MODTAG_LETSIKRING_AENDRINGSDATO,
        letsikring: letsikring,
        letsikringNr: letsikringNr,
        aendringsDato: aendringsDato,
        receivedAt: Date.now(),
        fejlmeddelelse: fejl
    };
}

export function indlaesLetsikringAendringsDato(letsikringNr, aendringsDato) {
    return (dispatch, getState) => {

        dispatch(hentLetsikringAendringsDato(letsikringNr, aendringsDato));

        return ApiUtils.get(`letsikringer/${letsikringNr}/${aendringsDato}`)
            .then(
                letsikring => {
                    dispatch(modtagLetsikringAendringsDato(getState(), letsikring, letsikringNr, aendringsDato));
                },
                error => {
                    console.log('Action indlaesLetsikringAendringsDato error.', error);
                    dispatch(modtagLetsikringAendringsDato(getState(), null, letsikringNr, aendringsDato, 'Fejl ved indlæsning af letsikring på dækningsdato'));
                }
            );
    };
}


function hentLetsikringDepotRegnskab(letsikringNr, aarstal) {
    return {
        type: HENT_LETSIKRING_DEPOTREGNSKAB,
        letsikringNr: letsikringNr,
        aarstal: aarstal
    };
}

function modtagLetsikringDepotRegnskab(state, depotregnskab, letsikringNr, aarstal, fejl) {
    return {
        type: MODTAG_LETSIKRING_DEPOTREGNSKAB,
        depotregnskab: depotregnskab,
        letsikringNr: letsikringNr,
        aarstal: aarstal,
        receivedAt: Date.now(),
        fejlmeddelelse: fejl
    };
}

export function indlaesLetsikringDepotRegnskab(letsikringNr, aarstal) {
    return (dispatch, getState) => {
        let state = getState();
        if (getLetsikringerDepotregnskabState(state, letsikringNr, aarstal).indlaeser) {
            return null;
        }

        dispatch(hentLetsikringDepotRegnskab(letsikringNr, aarstal));
        
        //dispatch(modtagLetsikringDepotRegnskab(getState(), dummyDepotregnskab, letsikringNr, aarstal));
        //return null;

        return ApiUtils.get(`letsikringer/depotregnskab/${letsikringNr}/${aarstal}`)
            .then(
                depotregnskab => {
                    dispatch(modtagLetsikringDepotRegnskab(getState(), depotregnskab, letsikringNr, aarstal));
                },
                error => {
                    console.log('Action indlaesLetsikringDepotRegnskab error.', error);
                    dispatch(modtagLetsikringDepotRegnskab(getState(), null, letsikringNr, aarstal, 'Fejl ved indlæsning af depotregnskab'));
                }
            );
    };
}
/*
let dummyDepotregnskab = {
    "letsikringNr": "31147809",
    "aar": 2018,
    "grupper": [
        {
            "raekker": [
                {
                    "beloeb": 2038884.91,
                    "dato": "2018-03-19T15:39:09.3043805+01:00",
                    "navn": "Opsparing den 19. marts 2018",
                    "procent": 0.0,
                    "noegle": "ULTIMOSALDO",
                    "sekvensNummer": 0,
                    "sumRaekke": true
                }
            ],
            "navn": "Opsparing den 19. marts 2018",
            "noegle": "POSTERINGER_ULTIMO_GRP",
            "sekvensNummer": 0
        },
        {
            "raekker": [
                {
                    "beloeb": 735.69,
                    "navn": "Betalinger",
                    "procent": 0.0,
                    "noegle": "INDBETALINGTOTAL",
                    "sekvensNummer": 0,
                    "sumRaekke": true
                },
                {
                    "beloeb": 367.07,
                    "dato": "2018-01-23T00:00:00",
                    "navn": "Indbetaling",
                    "procent": 0.0,
                    "noegle": "LOEBENDE_INDBETALINGLOEBENDE",
                    "sekvensNummer": 1,
                    "sumRaekke": false
                },
                {
                    "beloeb": 368.62,
                    "dato": "2018-02-20T00:00:00",
                    "navn": "Indbetaling",
                    "procent": 0.0,
                    "noegle": "LOEBENDE_INDBETALING",
                    "sekvensNummer": 2,
                    "sumRaekke": false
                }
            ],
            "navn": "Betalinger",
            "noegle": "POSTERINGER_INDBETALING_GRP",
            "sekvensNummer": 1
        },
        {
            "raekker": [
                {
                    "beloeb": 4420.53,
                    "navn": "Sikring af din opsparing og overlevelsesgevinst",
                    "procent": 0.0,
                    "noegle": "RISIKOTOTAL",
                    "sekvensNummer": 0,
                    "sumRaekke": true
                },
                {
                    "beloeb": -941.02,
                    "navn": "Sikring af opsparing",
                    "procent": 0.0,
                    "noegle": "FORSIKRINGER",
                    "sekvensNummer": 1,
                    "sumRaekke": false
                },
                {
                    "beloeb": 5361.55,
                    "navn": "Overlevelsesgevinst",
                    "procent": 0.0,
                    "noegle": "OVERLEVELSESGEVINST",
                    "sekvensNummer": 2,
                    "sumRaekke": false
                }
            ],
            "navn": "Sikring af din opsparing og overlevelsesgevinst",
            "noegle": "POSTERINGER_RISIKO_GRP",
            "sekvensNummer": 2
        },
        {
            "raekker": [
                {
                    "beloeb": -25170.95,
                    "navn": "Afkast",
                    "procent": 0.0,
                    "noegle": "AFKASTTOTAL",
                    "sekvensNummer": 0,
                    "sumRaekke": true
                },
                {
                    "beloeb": -25170.95,
                    "navn": "Afkast fra din investeringsprofil",
                    "procent": 0.0,
                    "noegle": "AFKAST_INVESTERINGSPROFIL",
                    "sekvensNummer": 0,
                    "sumRaekke": false
                },
                {
                    "beloeb": 6153.41,
                    "navn": "Heraf afkast fra PFA KundeKapital",
                    "procent": 0.0,
                    "noegle": "AFKAST_KUNDEKAPITAL",
                    "sekvensNummer": 1,
                    "sumRaekke": false
                }
            ],
            "navn": "Afkast",
            "noegle": "POSTERINGER_AFKAST_GRP",
            "sekvensNummer": 3
        },
        {
            "raekker": [
                {
                    "beloeb": -2415.10,
                    "navn": "Omkostninger",
                    "procent": 0.0,
                    "noegle": "OMKOSTTOTAL",
                    "sekvensNummer": 0,
                    "sumRaekke": true
                },
                {
                    "beloeb": -39.21,
                    "navn": "Til PFA - Administration af din sikring af opsparing",
                    "procent": 0.0,
                    "noegle": "ADMINOMKOST_FORSIKRING",
                    "sekvensNummer": 1,
                    "sumRaekke": false
                },
                {
                    "beloeb": -22.07,
                    "navn": "Til dit pengeinstitut - Procent af din betaling",
                    "procent": 0.0,
                    "noegle": "OMKOST_LOEBENDEVEDERLAGINDBETALING",
                    "sekvensNummer": 2,
                    "sumRaekke": false
                },
                {
                    "beloeb": -2353.82,
                    "navn": "Til dit pengeinstitut og PFA - Procent af din opsparing",
                    "procent": 0.0,
                    "noegle": "OMKOST_DIREKTEOPSPARING",
                    "sekvensNummer": 3,
                    "sumRaekke": false
                }
            ],
            "navn": "Omkostninger",
            "noegle": "POSTERINGER_OMKOST_GRP",
            "sekvensNummer": 4
        },
        {
            "raekker": [
                {
                    "beloeb": 2061314.74,
                    "navn": "Opsparing den 1. januar 2018",
                    "procent": 0.0,
                    "noegle": "PRIMOSALDO",
                    "sekvensNummer": 0,
                    "sumRaekke": true
                }
            ],
            "navn": "Opsparing den 1. januar 2018",
            "noegle": "POSTERINGER_PRIMO_GRP",
            "sekvensNummer": 5
        },
        {
            "raekker": [
                {
                    "beloeb": -3851.16,
                    "navn": "Ikke afregnet pensionsafkastskat",
                    "procent": 0.0,
                    "noegle": "EJREGNETPALTOTAL",
                    "sekvensNummer": 0,
                    "sumRaekke": true
                },
                {
                    "beloeb": -3851.16,
                    "navn": "Ikke afregnet pensionsafkastskat",
                    "procent": 0.0,
                    "noegle": "EJREGNETPAL",
                    "sekvensNummer": 1,
                    "sumRaekke": false
                }
            ],
            "navn": "Ikke afregnet pensionsafkastskat",
            "noegle": "AFKASTOMKOST_EJREGNETPAL_GRP",
            "sekvensNummer": 6
        },
        {
            "raekker": [
                {
                    "beloeb": 4239.55,
                    "navn": "Årlige omkostninger i kroner (ÅOK)",
                    "procent": 0.0,
                    "noegle": "OMKOSTSUMMARYTOTAL",
                    "sekvensNummer": 0,
                    "sumRaekke": true
                },
                {
                    "beloeb": 2415.10,
                    "navn": "- heraf omkostninger til administration",
                    "procent": 0.0,
                    "noegle": "OMKOSTDIREKTE",
                    "sekvensNummer": 1,
                    "sumRaekke": true
                },
                {
                    "beloeb": 1824.45,
                    "navn": "- heraf omkostninger til investering",
                    "procent": 0.0,
                    "noegle": "OMKOSTINDIREKTE",
                    "sekvensNummer": 2,
                    "sumRaekke": true
                }
            ],
            "navn": "Årlige omkostninger i kroner (ÅOK)",
            "noegle": "POSTERINGER_OMKOST_SUMMARY_GRP",
            "sekvensNummer": 7
        }
    ],
    "startDato": "2018-01-01T00:00:00",
    "slutDato": "2018-03-19T15:39:09.3043805+01:00",
    "fejlTekster": [],
    "beregningsTidspunkt": "2018-03-19T15:39:09.3043805+01:00"
};*/