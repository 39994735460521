import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Header from './header.jsx';
import Footer from './footer.jsx';

import Forside from './forside.jsx';
import ForKunder from './forkunder/forkunder.jsx';
import Ydelser from './ydelser/ydelser.jsx';
import Produktet from './produktet/produktet.jsx';
import { OmNeotech } from './omneotech/omneotech.jsx';
import PrivatlivOgCookies from './privatlivogcookies.jsx';
import { KontaktSide } from './sider/kontakt/kontakt.jsx';
import Fejl404 from './fejl404.jsx'


const Main = () => (
  <div>    
    <Switch>
      <Route exact path='/' component={Forside}/>
      <Route path='/forkunder' component={ForKunder}/>
      <Route path='/ydelser' component={Ydelser}/>
      <Route path='/produktet' component={Produktet}/>
      <Route path='/omneotech' component={OmNeotech}/>
      <Route path='/kontakt' component={KontaktSide}/>
      <Route path='/privatlivogcookies' component={PrivatlivOgCookies}/>
      <Route component={Fejl404} />
    </Switch>
  </div>
)


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
			loggedIn: false,
			username: '' 	
        };
    }
    componentDidMount() {
        document.title = 'Neotech';
    }
	render() {
		return (
            <div>
                <section id="top"/>
                <div className="page-wrapper">
                    <Header />
                    <Main />
                    <Footer />
                </div>
            </div>
        )
	}
}
