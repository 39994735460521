import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Table, ButtonGroup, Button, Panel } from 'react-bootstrap';
import withSizes from 'react-sizes';
import PropTypes from 'prop-types';
import elementType from 'prop-types-extra/lib/elementType';
import all from 'prop-types-extra/lib/all';
import ActivityIndicator from "components/common/activityIndicator.jsx";
import { isDirty } from 'redux-form';
import { IconLink, LpForm, LpFormTest } from './form';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getValue, setValueAction } from 'reducers/values';

const propTypes = {
    name: PropTypes.string.isRequired,

    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        field: PropTypes.string,
        hideBelow: PropTypes.number,
        renderer: PropTypes.func,
        styleName: PropTypes.string,
        align: PropTypes.oneOf(['left', 'right', 'center'])
    })).isRequired,

    listViewBelow: PropTypes.number,

    data: PropTypes.arrayOf(PropTypes.object).isRequired,

    idKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    ListComponent: elementType.isRequired, // props: { row: Proptypes.object, actions: Proptypes.element, changeState: Proptypes.func }

    EditComponent: all(elementType, (props) => {
            if (props.deletable === undefined && !(props.DeleteComponent || props.deleteFunction)) {
                return new Error(
                    "LpTable.EditComponent cannot be present without either deletable=false or DeleteComponent or deleteFunction");
            }
        }
        ), // props: { values: Proptypes.object, actions: Proptypes.element, changeState: Proptypes.func }

    DeleteComponent: elementType, // props: { values: Proptypes.object, actions: Proptypes.element, changeState: Proptypes.func }

    deleteFunction: PropTypes.func, // (row) =>

    updateFunction: PropTypes.func, // (row) =>

    addFunction: PropTypes.func, // (row) =>

    newObjectOnAddFunction: PropTypes.func, // () => {}

    DetailsComponent: elementType, // props: { values: Proptypes.object, actions: Proptypes.element, changeState: Proptypes.func }

    ListDetailsComponent: elementType, // props: { values: Proptypes.object, actions: Proptypes.element, changeState: Proptypes.func }

    detailsLink: PropTypes.func,

    editable: PropTypes.func,
    
    deletable: all(PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
            (props) => {
                if (props.deletable && !(props.DeleteComponent || deleteFunction)) {
                    return new Error("LpTable.deletable cannot be true without DeleteComponent or deleteFunction");
                }
            }
        ),

    hasDetails: PropTypes.func,

    updating: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),

    addButtonText: PropTypes.string,

    buttons: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

    styleName: PropTypes.string,

    hasSumRow: PropTypes.bool,

    withLetforslag: PropTypes.bool

};

const defaultProps = {
    idKey: 'id',
    withLetforslag: false
};

class LpTable extends Component {
    mapSizesToProps(cutoffs) {
        return ({ width }) => {
            let ret = {};
            Object.keys(cutoffs).map((key) => ret[key] = width < cutoffs[key]);
            return ret;
        };
    }
    render() {
        let cutOffs = { useListView: this.props.listViewBelow };
        this.props.columns.map((col, i) => {
            if (col.hideBelow) {
                cutOffs['hideCol' + i] = col.hideBelow;
            }
        });
        let Inner = withSizes(this.mapSizesToProps(cutOffs))(InnerLpTable);
        let props = this.props;
        return <Panel><Inner {...props} /></Panel>;
    }
}
LpTable.propTypes = propTypes;
LpTable.defaultProps = defaultProps;
export default connect(null,
    dispatch => ({ dispatch })
)(LpTable);

function getKey(row, tableProps) {
    let key = tableProps.idKey;
    let id = null;
    if (typeof key === 'string' || key instanceof String) {
        id = row[tableProps.idKey];
    } else {
        id = key(row);
    }
    if (typeof(id) != 'undefined' && id != null) {
        return id;
    }
    return '#new#';
}

function getMode(row, tableProps, propsMode) {
    let mode = 'standard';
    if (getKey(row, tableProps) === '#new#') {
        mode = 'edit';
    }
    else if (propsMode) {
        mode = propsMode;
    }
    return mode;
}

function isAdded(row, tableProps) {
    return getKey(row, tableProps) === '#new#';
}

function hasAdded(tableProps) {
    return tableProps.data && tableProps.data.some((r) => isAdded(r, tableProps));
}

class InnerLpTable extends Component {
    constructor(props) {
        super(props);
        this.handleEditOpenChange = this.handleEditOpenChange.bind(this);
        this.editsOpen = 0;
    }
    renderColumnHeader(column, key) {
        return <th key={key} style={{ textAlign: column.align || 'left' }}>
                   { column.title }
               </th>;
    }
    renderTableHeaders() {
        return <thead>
                   <tr>
                       {this.props.columns.map((c, i) => !this.props['hideCol'+i] && this.renderColumnHeader(c, i))}
                       <th key='actions'></th>
                   </tr>
               </thead>;
    }
    renderRow(row, i) {
        let sumRow = this.props.hasSumRow && i === this.props.data.length - 1;
        return <Row row={row} tableProps={this.props} key={getKey(row, this.props)} sumRow={sumRow} handleEditOpenChange={this.handleEditOpenChange} />;
    }
    renderTableFooter() {
        let { addFunction, buttons, addButtonText, dispatch, newObjectOnAddFunction } = this.props;
        let add = () => addFunction(newObjectOnAddFunction ? newObjectOnAddFunction() : {}, dispatch);
        addButtonText = addButtonText || 'Tilføj';
        return (addFunction || buttons) && 
            <tfoot>
            <tr width="100%">
                <td colSpan={this.props.columns.length + 1} className="padding-clear">
                    <div className="clearfix"> 
                        { addFunction && <Button key="add" onClick={add} disabled={hasAdded(this.props)} className="pull-right">{addButtonText}</Button> }
                        { buttons } 
                    </div>                       
                </td>
            </tr>
            </tfoot>;
    }
    renderTable() {
        return <Table striped className={classnames("table-colored", this.props.styleName)}>
                   { this.renderTableHeaders() }
                   <tbody>
                        <tr className="border-top-clear" key="filler" height="0"></tr>
                       { this.props.data.map((r, i) => this.renderRow(r, i)) }
                   </tbody>
                   { this.renderTableFooter() }
               </Table>;
    }
    renderListFooter() {
        let { addFunction, buttons, addButtonText, dispatch, newObjectOnAddFunction } = this.props;
        let add = () => addFunction(newObjectOnAddFunction ? newObjectOnAddFunction() : {}, dispatch);
        addButtonText = addButtonText || 'Tilføj';
        return (addFunction || buttons) && 
               <div key="buttons" className="clearfix">
                   { buttons } 
                   { addFunction && <Button key="add" onClick={add} disabled={hasAdded(this.props)}>{addButtonText}</Button> }
               </div>;
    }
    renderList() {
        return [<ListGroup key="listgroup">
                   { this.props.data.map((r) => <Item row={r} tableProps={this.props} key={getKey(r, this.props)} handleEditOpenChange={this.handleEditOpenChange} />) }
               </ListGroup>,
               this.renderListFooter() 
            ];
    }
    handleEditOpenChange(editOpen) {
        if (editOpen) {
            this.editsOpen += 1;
            if (this.props.handleEditOpenChange && this.editsOpen === 1) {
                this.props.handleEditOpenChange(true);
            }
        } else {
            this.editsOpen -= 1;
            if (this.props.handleEditOpenChange && this.editsOpen === 0) {
                this.props.handleEditOpenChange(false);
            }
        }
    }
    render() {
        if (this.props.useListView) {
            return this.renderList();
        } else {
            return this.renderTable();
        }
    }
}
InnerLpTable.propTypes = propTypes;
InnerLpTable.defaultProps = defaultProps;


function isEditable(row, tableProps) {
    let ret = false;
    if (tableProps.EditComponent) {
        ret = true;
    }
    if (tableProps.editable) {
        ret = ret && (tableProps.editable(row) || isAdded(row, tableProps));
    }
    return ret;
}

function isDeletable(row, tableProps) {
    let ret = isEditable(row, tableProps);
    if (tableProps.DeleteComponent || tableProps.deleteFunction) {
        ret = true;
    }
    if (typeof tableProps.deletable === 'boolean') {
        ret = ret && tableProps.deletable;
    } else if (tableProps.deletable) {
        ret = ret && tableProps.deletable(row);
    }
    return ret;
}

function hasDetails(row, tableProps) {
    let ret = false;
    if (tableProps.DetailsComponent || tableProps.detailsLink) {
        ret = true;
    }
    if (tableProps.hasDetails) {
        ret = ret && tableProps.hasDetails(row);
    }
    return ret;
}

function modeChange(changeState, triggerMode, mode) {
    return (e) => {
        e.preventDefault();
        if (triggerMode === mode) {
            changeState('standard');
        } else {
            changeState(triggerMode);
        }
    };
}

class EditLink extends Component {
    render() {
        let { onClick, icon, dirty, added } = this.props;
        let className = dirty||added ? 'color-attention' : undefined;
        return <IconLink {...{ onClick, icon }} className={className}/>;
    }
}

function actions(row, changeState, tableProps, mode) {
    let ret = [];
    if (isDeletable(row, tableProps)) {
        ret.push(<IconLink key='delete' onClick={modeChange(changeState,'delete',mode)} icon="trash"/>);
    }
    if (isEditable(row, tableProps)) {
        let Edit = connect((state) => { return { dirty: isDirty(formName(row, tableProps))(state) } })(EditLink);
        let debug = (p) => {
            return false;  // Set a breakpoint here to observer values and initalValues as subkeys of p
        }
        let added = getKey(row, tableProps) === '#new#';
        ret.push(<Edit key='edit' added={added} onClick={modeChange(changeState,'edit',mode)} icon="edit"/>);
        ret.push(<LpFormTest key="debug" name={formName(row, tableProps)} test={debug}></LpFormTest>);
    }   
    if (hasDetails(row, tableProps)) {
        if (tableProps.detailsLink) {
            ret.push(<IconLink key='details' linkTo={tableProps.detailsLink(row)} icon="arrow-circle-right" />);
        } else {
            let icon = mode === 'details' ? "minus-square" : "plus-square";
            ret.push(<IconLink key='details' onClick={modeChange(changeState,'details',mode)} icon={icon} />);
        }        
    }
    return ret;
}

function updating(row, tableProps) {
    return tableProps.updating && (tableProps.updating === row || (Array.isArray(tableProps.updating) && tableProps.updating.indexOf(row)>=0));
}

function formName(row, tableProps) {
    let name = tableProps.name + '-' + getKey(row, tableProps);
    return name;
}

class Row extends Component {
    constructor(props) {
        super(props);

        let mode = getMode(props.row, props.tableProps, props.mode);
        if (mode === 'edit') {
            props.handleEditOpenChange(true);
        }

        this.changeState = this.changeState.bind(this);
    }
    renderColumn(row, column, key) {
        let content = column.renderer ? column.renderer(row, column) : row[column.field];
        return <td key={key} className={column.styleName}> { content } </td>;
    }
    changeState(mode) {
        this.props.dispatchSetMode(mode);
        this.props.handleEditOpenChange(mode === 'edit');
    }
    render() {
        let mode = getMode(this.props.row, this.props.tableProps, this.props.mode);
        let columns = this.props.tableProps.columns;
        let row = this.props.row;
        let actionEle = actions(row, this.changeState, this.props.tableProps, mode);
        let ret = [<tr key="standard" className={this.props.sumRow ? 'sum-row' : undefined}>
                   { columns.map((c,i) => !this.props.tableProps['hideCol'+i] && this.renderColumn(row, c, i))}
                   <td key='actions' className="text-right">{actionEle}</td>
                   </tr>];
        let special = renderSpecial(this.props.row, this.changeState, this.props.tableProps, mode);
        if (special) {
            ret.push(<tr className="border-top-clear" key="filler" height="0"></tr>);
            ret.push(<tr className="border-top-clear" key={mode}><td className="border-top-clear" colSpan={columns.length+1} width="100%">{special}</td></tr>);
        }
        return ret;
    }
}


function mapRowModeStateToProps(state, ownProps) {
    return {
        mode: getValue(state, 'rowMode-' + formName(ownProps.row, ownProps.tableProps))
    }
};

function mapRowModeDispatchToProps(dispatch, ownProps) {
    return {
        dispatchSetMode: (mode) => dispatch(setValueAction('rowMode-'+formName(ownProps.row, ownProps.tableProps), mode))
    }
}

Row = connect(mapRowModeStateToProps, mapRowModeDispatchToProps)(Row);

function renderSpecial(row, changeState, tableProps, mode, isList) {
    let actionEle = actions(row, changeState, tableProps, mode);
    if (updating(row, tableProps)) {
        return <ActivityIndicator besked="Opdaterer"/>;
    }
    switch(mode) {
    case 'details': {
        let Det = tableProps.DetailsComponent;
        if (isList) {
            Det = tableProps.ListDetailsComponent || Det;
        }
        return <Det values={row} actions={actionEle} changeState={changeState}/>;
    }
    case 'delete' : {
        let { DeleteComponent, deleteFunction } = tableProps,
            Del = DeleteComponent || Delete;
        let resetFunction = () => changeState('standard');
        return <Del onSubmit={deleteFunction} onReset={resetFunction} name={formName(row, tableProps)+"-delete"} values={row} actions={actionEle} changeState={changeState} withLetforslag={tableProps.withLetforslag}/>;
    }
    case 'edit' : {
        let { EditComponent, updateFunction, deleteFunction, dispatch } = tableProps;
        let added = getKey(row, tableProps) === '#new#';
        let resetFunction = () => added ? deleteFunction(row, dispatch) : changeState('standard');
        return <EditComponent onSubmit={updateFunction} onReset={resetFunction} name={formName(row, tableProps)} values={row} actions={actionEle} changeState={changeState} withLetforslag={tableProps.withLetforslag}/>;
    }
    }
    return null;
}

class Item extends Component {
    constructor(props) {
        super(props);
        let mode = getMode(props.row, props.tableProps, props.mode);
        if (mode === 'edit') {
            props.handleEditOpenChange(true);
        }
        this.changeState = this.changeState.bind(this);
    }
    renderItem(row, Comp, mode) {
        let changeState = this.changeState.bind(this);
        return <Comp row={row} actions={actions(row, changeState, this.props.tableProps, mode)} tableProps={this.props.tableProps} changeState={changeState}/>;
    }
    changeState(mode) {
        this.props.dispatchSetMode(mode);
        this.props.handleEditOpenChange(mode === 'edit');
    }
    render() {
        let mode = getMode(this.props.row, this.props.tableProps, this.props.mode);
        return <ListGroupItem>
                   { this.renderItem(this.props.row, this.props.tableProps.ListComponent, mode) }
                   { renderSpecial(this.props.row, this.changeState, this.props.tableProps, mode, true) }
               </ListGroupItem>;
    }
}

Item = connect(mapRowModeStateToProps, mapRowModeDispatchToProps)(Item);

class Delete extends Component {
    render() {
        return <LpForm {...this.props} submitMessage='Sletter' submitButtonText='Slet' submitIfDirty={false}>
                   Bekræft sletning
               </LpForm>;
    }
}


export class TomListeBesked extends Component {
    render() {
        if (this.props.liste && this.props.liste.length === 0) {
            return (
                <div>                       
                    <p className="text-center">{this.props.children}</p>
                </div>
            );
        }
        return null;
    }
}
