import React, { Component } from 'react';
import ApiUtils from "../../util/apiUtils.jsx";

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        console.log(error);
        console.log(info);
        ApiUtils.error('Komponenten kunne ikke tegnes', error).then(json => this.setState({ requestId: json.requestId }));
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div className="row">
                       <div className="col-md-6 text-center col-md-offset-3">
                           <br/>
                           <div className="alert  alert-icon alert-warning" role="alert">
                               <i className="fa fa-warning"></i>
                               Komponenten kunne ikke tegnes.
                           </div>
                           { this.state.requestId && <p>Vi har gemt fejlen på vores system og ser på den hurtigst muligt. Hvis du henvender dig, skal du oplyse identifikationsteksten: "{ this.state.requestId }".</p> }
                           <br/>
                           <br/>
                       </div>
                   </div>;
        }
        return this.props.children;
    }
}