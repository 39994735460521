import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import {vaelgMenu, nulstilMenu } from '../actions/navigation.jsx';
import HashLink from './hashlink.jsx';

function mapStateToProps(state) {
    return {
        navigation: state.navigation
    };
}
function mapDispatchToProps (dispatch) {
  return {
    dispatchVaelgMenu: (valgtMenu) => dispatch(vaelgMenu(valgtMenu)),
    dispatchNulstilMenu: () => dispatch(nulstilMenu()),
  }
}

class SiteLink extends Component {
    vaelgMenupunkt(valgtElement) {
        //console.log('SiteLink vaelgMenupunkt: ' + valgtElement);
        this.props.dispatchVaelgMenu(valgtElement);
    }
    renderLink() {
        //console.log('SiteLink renderLink: ' + this.props.datatoggle);
        if (this.props.datatoggle) {
            return <HashLink to={this.props.url} className={this.props.classname} onClick={this.vaelgMenupunkt.bind(this, this.props.url)} data-toggle={this.props.datatoggle}>{this.props.children}</HashLink>            
        }
        else {
            return <HashLink to={this.props.url} className={this.props.classname} onClick={this.vaelgMenupunkt.bind(this, this.props.url)}>{this.props.children}</HashLink>
        }
    }
    render() {
        //console.log('SiteLink: ' + this.props.url);
        return (
            this.renderLink()
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteLink)