import React, { Component } from 'react';
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class LetsikringVedKritiskSygdom extends Component {
    render() {
        return (
            <div className="harlekin-baggrund">
			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Letsikring ved kritisk sygdom</h3>
                        </div>
                    </div>

                    <div className="row">
                        <video controls className="col-xs-12 col-sm-8 col-xs-offset-0 col-sm-offset-2">
                            <source src="/resources/videos/Letsikring_ved_kritisksygdom.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="separator center"></div>
                            <p>Med en Letsikring ved kritisk sygdom kan du sikre dig et økonomisk råderum, hvis du bliver alvorligt syg.</p>
                            <p>En Letsikring ved kritisk sygdom sikrer dig et skattefrit beløb, hvis du bliver ramt af en alvorlig sygdom som for eksempel kræft, en hjerneblødning eller Parkinsons og stadig er i live en måned efter, at du har fået stillet diagnosen.</p>
                            <p>Du kan bruge pengene, som du vil - for eksempel til at ændre dit hjem, så det passer til dine nye behov eller tage på rekreation, hvis du trænger til det.</p>
                            <br /> 
                            <h4 className="title text-center">Hvilke sygdomme dækker Letsikringen?</h4>
                            <p>Pengene bliver udbetalt, hvis du får stillet diagnosen på én af de sygdomme, som Letsikringen dækker, og diagnosen opfylder diagnosekravene i forsikringsbetingelserne.</p>
							<ul className="nav flex-column list-style-icons">
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Større blodprop i hjertet
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Hjerteoperation på grund af svær kranspulsåreforkalkning (bypass/ballonudvidelse (PCI))
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Kronisk hjertesvigt med svære symptomer og nedsat funktionsevne
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Livstruende rytmeforstyrrelser med indoperation af ICD-enhed
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Hjerteklapoperation på grund af hjerteklapfejl
                                </li>                                
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Hjerneblødning/blodprop i hjernen (apopleksi)
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Sækformet udvidelse af hjernens pulsårer (aneurisme) eller intrakraniel ateriovenøs karmisdannelse (AV-malfunktion)
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Svær sygdom i aorta (hovedpulsåren)
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Cancer (kræft)
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Visse godartede svulster i hjerne og rygmarv
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Dissemineret sklerose (DS)
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Amyotrofisk lateralsklerose (ALS) eller motorisk neuronsygdom (MND)
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Kronisk nyresvigt med behov for varig dialysebehandling
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Større organtransplantation
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    AIDS
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    HIV-infektion som følge af blodtransfusion eller arbejdsbetinget smitte
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Muskelsvind
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Muasthenia gravis
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Blindhed
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Døvhed
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Parkinsons syge
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Creutzfeldt-Jacobs sygdom
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Blivende følger efter hjerne- eller hjernehindebetændelse
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Følger efter Borrelia og Tick-Borne-Encephalitis (TBE) i nervesystemet efter flåtbid
                                </li>
                                <li className="nav-item">
                                    <i className="fa fa-caret-right pr-10"></i>
                                    Større forbrænding
                                </li>
							</ul>

                        </div>
                    </div>
                </div>
            </section>

			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-md-8">
										<h3 className="title">Hvis du vil vide mere </h3>
										<p>Vil du vide mere om, hvordan vi kan sikre dig et økonomisk råderum, hvis du bliver alvorligt syg, kan du kontakte din rådgiver i dit lokale pengeinstitut.</p>
									</div>
									<div className="col-md-4">
										<br/>
										<p><SiteLink url="/blivkunde" classname="btn btn-lg btn-gray-transparent btn-animated">Bliv kunde i Letpension<i className="fa fa-arrow-right pl-20"></i></SiteLink></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/produktet/letsikringvedsygdom#top" billede="/Resources/images/Letpension/produkter/letsikringvedkritisksygdom.png" tekst="Letsikring ved kritisk sygdom" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-5">
                            <ImageLink link="/produktet/letsikringafindtaegtvedpension#top" billede="/Resources/images/Letpension/produkter/letsikringafindtaegtvedpension.png" tekst="Letsikring af indtægt ved pension" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/produktet/letsikringafbarnveddoed#top" billede="/Resources/images/Letpension/produkter/letsikringafbarnveddoed.png" tekst="Letsikring af barn" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-5">
                            <ImageLink link="/produktet/letsikringveddoed#top" billede="/Resources/images/Letpension/produkter/letsikringveddoed.png" tekst="Letsikring ved død" />
                        </div>
                    </div>

                </div>
            </section>

            </div>
      );
    }
}

