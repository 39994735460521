export const TILBUD_OPDATERES_KEY = "TilbudOpdateres-";
export const LETFORSLAG_GENINDLAESNING_STATUS_KEY = "LetforslagGenindlæsningStatusKey";
export const LOGIN_METODE_KEY = "LoginMetode";
export const SIGNALR_BRUGER_KEY = "SignalrBruger";
const KUNDESKIFT_IGANG_KEY = "KundeskiftIgang";
const TILBUDSKIFT_IGANG_KEY = "TilbudskiftIgang";

export function handleSetValue(key, value, dispatch) {
    return dispatch(setValueAction(key, value));
}

export function setKundeskiftIgangAction(value) {
    return setValueAction(KUNDESKIFT_IGANG_KEY, value);
}

export function getKundeskiftIgang(state) {
    return getValue(state, KUNDESKIFT_IGANG_KEY);
}

export function setTilbudskiftIgangAction(value) {
    return setValueAction(TILBUDSKIFT_IGANG_KEY, value);
}

export function getTilbudskiftIgang(state) {
    return getValue(state, TILBUDSKIFT_IGANG_KEY);
}

export function getSignalrBruger(state) {
    return getValue(state, SIGNALR_BRUGER_KEY);
}

export function setSignalrBrugerAction(bruger) {
    return setValueAction(SIGNALR_BRUGER_KEY, bruger);
}

export function getLetforslagGenindlaesningStatus(state) {
    return getValue(state, LETFORSLAG_GENINDLAESNING_STATUS_KEY);
}

export function setLetforslagGenindlaesningStatusAction(value) {
    return setValueAction(LETFORSLAG_GENINDLAESNING_STATUS_KEY, value);
}

export function getLetForslagOpdateres(state) {
    if (!state.bruger || !state.bruger.bruger || !state.bruger.bruger.letforslagId) {
        return false;
    }
    return getValue(state, TILBUD_OPDATERES_KEY+state.bruger.bruger.letforslagId);
}

export function getLoginMetode(state) {
    return getValue(state, LOGIN_METODE_KEY);
}

export function setLoginMetodeAction(value) {
    return setValueAction(LOGIN_METODE_KEY, value);
}

export function getValue(state, key) {
    return state.values[key];
}

const SET_VALUE = "SET_VALUE";

export function setValueAction(key, value) {
    return {
        type: SET_VALUE,
        key,
        value
    }
}

export function reducer(state = {}, action) {
    switch (action.type) {
    case SET_VALUE:
        return Object.assign({}, state, { [action.key]: action.value });
    default:
        return state;
    }
}