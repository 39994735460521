import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { formatDate, formatBeloeb, parseDate } from "./formatters";
import PropTypes from 'prop-types';
import { FormGroup, FormControl, ControlLabel, InputGroup, Button, ButtonGroup, Overlay, Popover, OverlayTrigger, Tooltip, Checkbox, Radio } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker/DayPicker';
//import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';
import { Field, reduxForm, change, FormSection, getFormValues, getFormInitialValues, isDirty, destroy } from 'redux-form';
import TextMask from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import classnames from 'classnames';
import ActivityIndicator from "components/common/activityIndicator.jsx";
import { getLetForslagOpdateres } from "../../reducers/values";

function mapStateToPropsLetforslag(state) {
    return {
        letforslagOpdateres: getLetForslagOpdateres(state)
    }
}

export class LpForm extends Component {
    getChildContext() {
        let { name } = this.props;
        return {
            lpForm: { formName: name, values: this.getValues() }
        };
    }
    getValues() {
        let { values } = this.props;
        if (values) {
            return values;
        } else {
            let buildValues = {};
            allChildrenOfType(this, LpFormSection).forEach(c => {
                buildValues[c.props.name] = c.props.values;
            });
            return buildValues;
        }
    }

    render() {
        let { name, children, destroyOnUnmount, onSubmit, validate, withLetforslag, changeState } = this.props;
        let onSubmitSuccess = (res, dispatch) => {
            dispatch(destroy(name));
            if (changeState) {
                changeState('standard');
            }
        };
        let Form = reduxForm({
                form: name,
                initialValues: this.getValues(),
                destroyOnUnmount,
                onSubmit,
                onSubmitSuccess, 
                validate
            })(InnerForm);
        if (withLetforslag) {
            Form = connect(mapStateToPropsLetforslag)(Form);
        }
        return <Form {...this.props}>
                   { children }
               </Form>;
    }
}

LpForm.propTypes = {
    validate: PropTypes.func,
    name: PropTypes.string.isRequired,
    values: PropTypes.object,
    destroyOnUnmount: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    submitMessage: PropTypes.string,
    submitButtonText: PropTypes.string,
    submitIfDirty: PropTypes.bool,
    withLetForslag: PropTypes.bool,
    buttons: PropTypes.arrayOf(PropTypes.oneOf(['submit', 'fortryd'])),
    hideButtonIcons: PropTypes.bool
};
LpForm.defaultProps = {
    destroyOnUnmount: false,
    submitMessage: 'Gemmer',
    submitButtonText: 'Gem',
    submitIfDirty: true,
    withLetForslag: false,
    buttons: ['submit', 'fortryd'],
    hideButtonIcons: false
};
LpForm.childContextTypes = {
    lpForm: PropTypes.shape({
        formName: PropTypes.string,
        values: PropTypes.object
    })
}

function allChildrenOfType(start, componentType) {
    let array = [],
        addChildren = (component) => {
            if (component.props && component.props.children) {
                let { children } = component.props;
                React.Children.forEach(children, c => {
                    if (c) {
                        if (c.type === componentType) {
                            array.push(c);
                        }
                        addChildren(c);
                    }
                });
            }
        };
    addChildren(start);
    return array;
}

class InnerForm extends Component {
    renderButtons(handleSubmit, onReset, reset, submitButtonText, buttons, dirty, hideButtonIcons) {
        let { withLetforslag, letforslagOpdateres } = this.props;
        let disableSubmit = withLetforslag && letforslagOpdateres;
        let performReset = () => {
            reset();
            if (onReset) {
                onReset();
            }
        };
        return <div className="pull-right">
                   { buttons.includes('submit') && <IconButton icon="save" onClick={handleSubmit} disabled={!dirty || disableSubmit} hideIcon={hideButtonIcons}>{submitButtonText}</IconButton> }
                   { buttons.includes('fortryd') && <IconButton icon="undo" onClick={performReset} hideIcon={hideButtonIcons} className="ml-10">Fortryd</IconButton> }
               </div>;
    }
    renderError(error) {
        return <div className="alert  alert-icon alert-error" role="alert">
                   <i className="fa fa-exclamation-triangle"></i>
                   <div dangerouslySetInnerHTML={{ __html: error }} />
               </div>;
    }
    renderWarning(warning) {
        return <div className="alert  alert-icon alert-warning" role="alert">
                   <i className="fa fa-exclamation-triangle"></i>{warning}
               </div>;
    }

    componentDidMount() {
        if (this.formRef) {
            let input = this.formRef.querySelector('input');
            if (input) {
                if (this.focusTimeout) {
                    clearTimeout(this.focusTimeout);
                    this.focusTimeout = null;
                }
                this.focusTimeout = setTimeout(() => {
                    console.log("focus " + input.id);
                    input.focus();
                    this.focusTimeout = null;
                }, 500);
            }
        }
    }

    componentWillUnmount() {
        if (this.focusTimeout) {
            clearTimeout(this.focusTimeout);
            this.focusTimeout = null;
        }
    }

    render() {
        let { children, handleSubmit, submitting, error, warning, submitMessage, onReset, reset, submitButtonText, submitIfDirty, buttons, dirty, className, hideButtonIcons } = this.props;
        let enableSubmit = submitIfDirty ? dirty : true;
        return <form className={classnames(className, "clearfix")} ref={(ref) => { this.formRef = ref; }} onSubmit={handleSubmit}>
                    <fieldset className="lpform-allchildren-fieldset" disabled={submitting}>
                        { children }
                    </fieldset>
                   { error && this.renderError(error) }
                   { warning && this.renderWarning(warning) }
                   { submitting && <ActivityIndicator besked={submitMessage} /> }
                   { submitting || buttons && buttons.length>0 && this.renderButtons(handleSubmit, onReset, reset, submitButtonText, buttons, enableSubmit, hideButtonIcons) }
                    <input type="submit" hidden />
               </form>;
    }
}

export class LpFormSection extends Component {
    render() {
        let { name, children } = this.props;
        return <FormSection name={name}>{children}</FormSection>;
    }
}
LpFormSection.propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.object
}

export class LpFormSectionTest extends Component {
    formTest(formArgs) {
        let { test, name, input } = this.props,
            args = {};
        input.forEach(i => {
            switch(i) {
            case 'values':
            case 'initialValues':
                args[i] = formArgs[i][name];
                break;
            case 'dirty':
                args[i] = Object.keys(formArgs.values[name]).some((k) => formArgs.values[name][k] !== formArgs.initialValues[name][k]);
            }
        });
        return test(args);
    }
    render() {
        let { input, formName, children } = this.props,
            formInput = {};
        input.forEach(i => {
            switch (i) {
            case 'values':
            case 'initialValues':
                formInput[i] = true;
                break;
            case 'dirty':
                formInput['values'] = true;
                formInput['initialValues'] = true;
            }
        });
        return <LpFormTest name={formName} input={Object.keys(formInput)} test={(formArgs) => this.formTest(formArgs)}>
                   {children}
               </LpFormTest>;
    }
}

LpFormSectionTest.propTypes = {
    formName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    test: PropTypes.func.isRequired,
    input: PropTypes.arrayOf(PropTypes.oneOf(['values', 'initialValues', 'dirty']))
};
LpFormSectionTest.defaultProps = {
    input: ['values', 'initialValues', 'dirty']
};

export class LpFormTest extends Component {
    render() {
        let { name, input } = this.props,
            Comp = connect((state) => {
                    let stateProps = {};
                    input.forEach((i) => {
                        switch (i) {
                        case 'values':
                            stateProps.values = getFormValues(name)(state);
                            break;
                        case 'initialValues':
                            stateProps.initialValues = getFormInitialValues(name)(state);
                            break;
                        case 'dirty':
                            stateProps.dirty = isDirty(name)(state);
                        }
                    });
                    return { state: stateProps };
            })(LpFormTestInner);
        return <Comp {...this.props}>{this.props.children}</Comp>;
    }
}
LpFormTest.propTypes = {
    name: PropTypes.string.isRequired,
    test: PropTypes.func.isRequired,
    input: PropTypes.arrayOf(PropTypes.oneOf(['values', 'initialValues', 'dirty']))
};
LpFormTest.defaultProps = {
    input: ['values', 'initialValues', 'dirty']
};
class LpFormTestInner extends Component {
    render() {
        if (this.props.test(this.props.state)) {
            return this.props.children;
        } else {
            return null;
        }
    }
}

export function mandatory(msg) {
    return (value) => {
        if (!value) {
            return msg;
        }
        return undefined;
    }
}

export class TextField extends Component {
    render() {
        return <Field component={TextFieldInner} {...this.props}/>;
    }
}
TextField.propTypes = {
    validate: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    password: PropTypes.bool
}

class TextFieldInner extends Component {
    render() {
        let { placeholder, disabled } = this.props,
            { onChange, value } = this.props.input;
        return <LpFormGroup {...this.props}>
                   <FormControl type={this.props.password ? "password" : "text"} placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} />
               </LpFormGroup>;
    }
}

class LpFormGroup extends Component {
    render() {
        let { name } = this.props.input,
            { label } = this.props,
            { error, dirty, form, submitFailed } = this.props.meta,
            { style, children } = this.props,
            extra = { style },
            showError = (submitFailed || dirty) && error;
        return <FormGroup controlId={form + '-' + name} validationState={showError ? 'error' : undefined} {...extra} >
                   <ControlLabel>{label} {showError && ' - '+error} <ResetField {...this.props}/></ControlLabel>
                   {children}
               </FormGroup>;
    }
}

function getNested(path, obj) {
    let parts = path.split(".");
    parts.forEach((p) => obj = obj && obj[p]);
    return obj;
}

class ResetField extends Component {
    onClick() {
        let { changeValue } = this.props,
            { name } = this.props.input,
            { formName, values } = this.context.lpForm;
        changeValue(formName, name, getNested(name, values));
    }
    render() {
        let { dirty } = this.props.meta,
            { name } = this.props.input,
            { formName, values } = this.context.lpForm,
            { format } = this.props,
            tooltipId = formName + '.' + name + '.tooltip',
            rawValue = getNested(name, values),
            value = format?format(rawValue): rawValue;  // tooltip med value
        return dirty && rawValue!=undefined &&
            <OverlayTrigger placement="top" overlay={<Tooltip id={tooltipId}>{value}</Tooltip>}>
                <IconLink icon="undo" onClick={() => this.onClick()} />
            </OverlayTrigger>;
    }
}
// 
ResetField.contextTypes = {
    lpForm: PropTypes.shape({
        formName: PropTypes.string,
        values: PropTypes.object
    })
};
function mapResetDispatchToProps (dispatch) {
    return {
        changeValue: (name, field, value) => {
            dispatch(change(name, field, value));
        }
    }
}
ResetField = connect(() => { return {state: {}} }, mapResetDispatchToProps)(ResetField);

function parseTwoDigitYearDate(dateStr) {
    const parsed = dateStr.match(/^(\d{2})-(\d{2})-(\d{2})__$/);
    if (parsed) {
        let [date, day, month, year] = parsed;
        day = parseInt(day);
        month = parseInt(month);
        year = parseInt(year);
        const currentFullYear = (new Date()).getFullYear();
        const currentCentury = currentFullYear - (currentFullYear % 100);
        const currentYear = currentFullYear - currentCentury;
        const century = year <= currentYear ? currentCentury : currentCentury - 100;
        const fullYear = year + century;
        let ret = new Date(fullYear, month - 1, day);
        if (ret.getDate() == day && ret.getMonth() == month - 1 && ret.getFullYear() == fullYear) {
            return ret;
        }
    }
    return null;

}

export function validDate(msg, { allowNull } = {}) {
    return (value) => {
        if ((!value && !allowNull) || (value && !(parseDate(value, 'ISO') || parseTwoDigitYearDate(value)))) {
            return msg;
        }
        return undefined;
    }
}

export class DateField extends Component {
    format(value) {
        let { formatString } = this.props,
            date = value && parseDate(value, 'ISO');
        if (date) {
            return formatDate(date, formatString);
        } else {
            return value || '';
        }
    }
    parse(value) {
        let { formatString } = this.props,
            date = value && parseDate(value, formatString);
        if (date) {
            return formatDate(date, 'ISO');
        } else {
            return value;
        }
    }
    render() {
        let formatFunc = v => this.format(v);
        return <Field component={DateFieldInner} format={formatFunc} parse={v => this.parse(v)} formatFunc={formatFunc} {...this.props}/>;
    }
}

DateField.propTypes = {
    validate: PropTypes.func,
    name: PropTypes.string.isRequired,
    formatString: PropTypes.oneOf(['ShortDate']),
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string
};
DateField.defaultProps = {
    formatString: 'ShortDate'
};

class DateFieldInner extends Component {
    constructor(props) {
        super(props);
        this.state = { overlay: false };
    }
    toggleOverlay() {
        this.setState({ overlay: !this.state.overlay });
    }
    hideOverlay() {
        if (this.state.overlay) {
            this.setState({ overlay: false });
        }
    }
    setDate(date) {
        let { onChange } = this.props.input,
            { formatString } = this.props,
            value = formatDate(date, formatString);
        this.setState({ overlay: false });
        onChange(value);
    }
    getDayPickerValue(value) {
        let { formatString } = this.props,
            date = value && parseDate(value, formatString);
        return date ? date : new Date();
    }
    getTarget() {
        return ReactDOM.findDOMNode(this.target);
    }
    onBlur() {
        // If the field contains a valid date with a two digit year it should be changed to the corresponding 4-digit year date
        let { onChange, value } = this.props.input,
            { formatString } = this.props;
        if (value) {
            const date = parseTwoDigitYearDate(value);
            if (date) {
                onChange(formatDate(date, formatString));
            }
        }
    }
    render() {
        let { value, onChange } = this.props.input,
            { formatFunc } = this.props,
            mask = [/[0-3]/, /\d/, '-', /[0-1]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            overlayProps = {
                target: () => this.getTarget(),
                container: this,
                show: this.state.overlay,
                placement: 'bottom'
            };
        return <LpFormGroup {...this.props} style={{position: 'relative'}} format={formatFunc} >
                   <InputGroup width="100%" ref={field => this.target=field}>
                       <FormControl componentClass={TextMask} mask={mask} value={value} onChange={onChange} onFocus={()=>this.hideOverlay()} onBlur={()=>this.onBlur()} />
                       <InputGroup.Addon><IconLink icon="calendar" onClick={() => this.toggleOverlay()}/></InputGroup.Addon>
                   </InputGroup>
                   <Overlay {...overlayProps}>
                       <Popover id="date-input">
                           <DayPicker {...dayPickerOptions} 
                                      selectedDays={this.getDayPickerValue(value)} 
                                      initialMonth={this.getDayPickerValue(value)}
                                      onDayClick={(d) => this.setDate(d)} />
                       </Popover>
                   </Overlay>
               </LpFormGroup>;
    }
}

export class SelectField extends Component {
    render() {
        return <Field component={SelectFieldInner} {...this.props}/>;
    }
}
SelectField.propTypes = {
    validate: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object])
}

export class SelectFieldInner extends Component {
    renderArray(optionsArray, value) {
        let options = optionsArray.map((v) => <option key={v}>{v}</option>);
        if (!value && !optionsArray.includes('')) {
            options.unshift(<option key="blank" value=""></option>);
        }
        return options;
    }
    renderObject(optionsObject, value) {
        let options = Object.keys(optionsObject).map((rel) => <option key={rel} value={rel}>{optionsObject[rel]}</option>);
        if (!value && !optionsObject['']) {
            options.unshift(<option key="blank" value=""></option>);
        }
        return options;
    }
    renderOptions(options, value) {
        if (Array.isArray(options)) {
            return this.renderArray(options, value);
        } else {
            return this.renderObject(options, value);
        }
    }
    render() {
        let props = this.props,
            input = this.props.input,
            meta = this.props.meta,
            options = this.props.options;
        return <LpFormGroup {...this.props}>
                   <FormControl componentClass="select" value={input.value} onChange={input.onChange}>
                       { this.renderOptions(options, input.value) }
                   </FormControl>
               </LpFormGroup>;
    }
}
SelectField.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object])
};


function isValidNumber(num) {
    if (num === '') {
        return false;
    }
    return num.toString().match(/^(\+|\-)?[0-9\\.]*(\,[0-9]*)?$/);
}
export class NumberField extends Component {
    parse(value) {
        if (!isValidNumber(value)) {
            return value;
        }
        return Number(value.replace(/\./g, '').replace(/,/, '.'));
    }
    render() {
        let formatFunc = v => v ? formatBeloeb(v, this.props.decimals, false, v) : v;
        return <Field component={NumberFieldInner} format={formatFunc} formatFunc={formatFunc} parse={v=>this.parse(v)} {...this.props}/>;
    }
}
NumberField.propTypes = {
    validate: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    decimals: PropTypes.number,
    unit: PropTypes.string,
    align: PropTypes.oneOf(["left", "right"])
};
NumberField.defaultProps = {
    decimals: 2,
    align: "right"
};

export class NumberFieldInner extends Component {
    mask() {
        return createNumberMask({
            prefix: '',
            thousandsSeparatorSymbol: '.',
            decimalSymbol: ',',
            requireDecimal: this.props.decimals>0,
            decimalLimit: this.props.decimals
        });
    }
    render() {
        const props = this.props,
              { input, placeholder, formatFunc } = props,
              { onBlur, onChange, value } = input;
        return <LpFormGroup {...this.props} format={formatFunc}>
                   <InputGroup width="100%">
                       <FormControl componentClass={TextMask} mask={this.mask()} placeholder={placeholder} value={value} onChange={onChange} style={{textAlign:"right"}} />
                       { this.props.unit && <InputGroup.Addon>{this.props.unit}</InputGroup.Addon> }
                   </InputGroup>
               </LpFormGroup>;
    }
}

export class CheckField extends Component {
    render() {
        return <Field component={CheckFieldInner} {...this.props}/>;
    }
}
CheckField.propTypes = {
    validate: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};
export class CheckFieldInner extends Component {
    render() {
        let { value, onChange } = this.props.input,
            { label } = this.props,
            { error, dirty, submitFailed } = this.props.meta,
            showError = (submitFailed || dirty) && error;
        return <Checkbox validationState={showError ? 'error' : undefined}  value={value} onChange={onChange}>{label}{showError && ' - '+error} <ResetField {...this.props}/></Checkbox>;
    }
}

export class IconLink extends Component {
    onClick(e) {
        e.preventDefault();        
        this.props.onClick(e);
    }
    render() {
        let { icon, className, children, onMouseOver , onMouseOut  } = this.props;
        if (this.props.linkTo) {
            return (<Link to={this.props.linkTo} className={classnames("link-dark pr-5 pl-5", className)}><i className={classnames("fa","fa-"+icon)}/></Link>);
        }
        return <a onClick={(e) => this.onClick(e)} className={classnames("link-dark pr-5 pl-5", className)} href="#" {...{ onMouseOver, onMouseOut}}>
                   <i className={classnames("fa","fa-"+icon)}/>{children}
               </a>;
    }
}
IconLink.propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    linkTo: PropTypes.string,
    tooltip: PropTypes.string,
    onMouseOut : PropTypes.func,
    onMouseOver : PropTypes.func
};

export class IconButton extends Component {
    render() {
        let { onClick, icon, className, children, disabled, hideIcon } = this.props;
        return <Button onClick={onClick} className={className} disabled={disabled}>{!hideIcon && <i className={classnames("fa","fa-"+icon,"pr-5")}/>}{children}</Button>;
    }
}
IconButton.propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    hideIcon: PropTypes.bool
};
IconButton.defaultProps = {
    disabled: false,
    hideIcon: false
};

// Internal date field below this point

const propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    placeholder: PropTypes.string,
    format: PropTypes.string
};

const defaultProps = {
    format: 'ShortDate'
};

const dayPickerOptions = {
    months: [
        'Januar',
        'Februar',
        'Marts',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'December'
    ],
    weekdaysLong: [
        'Søndag',
        'Mandag',
        'Tirsdag',
        'Onsdag',
        'Torsdag',
        'Fredag',
        'Lørdag'
    ],
    weekdaysShort: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
    firstDayOfWeek: 1
};


class DateInput extends Component {
    constructor(props) {
        super(props);
        let value = props.input.value;
        if (value) {
            value = formatDate(new Date(value), props.format);
        }
        this.state = { value }
    }
    focus() {}
    render() {
        let format = (date) => formatDate(date, this.props.format) || '';
        let parse = (date) => parseDate(date, this.props.format);
        let props = Object.assign({}, this.props);
        let onChange = props.onChange;
        if (!props.placeholder) {
            props.placeholder = format(new Date());
        }
        if (onChange) {
            props.onDayChange = (date) => props.value instanceof Date ? onChange(date) : onChange(date.toString());
            delete props.onChange;
        }
        return <DayPickerInput 
            dayPickerProps={dayPickerOptions}
            formatDate={format}
            parseDate={parse}
            component={FormControl}
            inputProps={{ type: "text" }}
            {...props}
            />;
    }
}

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

