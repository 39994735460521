import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import { connect } from 'react-redux';
import {beregnOmkostninger, getOmkostningerState } from '../actions/omkostninger.jsx';

import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';
import FormatUtils from '../../util/formatUtils.jsx';
import Modal from 'react-bootstrap-modal';

function mapStateToProps(state) {

    let omkostningerState = getOmkostningerState(state);
    let omkostningerIndlaeser = false;
    let omkostningerFejlMeddelelse = null;
    let omkostninger = null;    
    if (omkostningerState) {
        omkostninger = omkostningerState.detaljer;
        omkostningerFejlMeddelelse = omkostningerState.fejl;
        if (omkostningerState.indlaeser) {
            omkostningerIndlaeser = omkostningerState.indlaeser;
        }
    }

    return {
        omkostninger: omkostninger,
        omkostningerIndlaeser: omkostningerIndlaeser,
        omkostningerFejlMeddelelse: omkostningerFejlMeddelelse
    };
    
}
function mapDispatchToProps (dispatch) {
    return {
        dispatchBeregnOmkostninger: (omkostningInput) => beregnOmkostninger(omkostningInput, dispatch)
    }
}




class OmkostningBeregner extends Component {
    constructor(props) {
        super(props);
        this.state = {
			pensionsopsparing: '',
			pensionindbetaling: '',
			forventetpensionsalder: '67',
			alder: '40',
			investeringsprofil: 'A',
			tegningsmaade: 'Arbejdsgiver',
            visberegningsdetaljer: false,
            kundekapital: true,
            betalingomkostningrabat: '',
            saldoomkostningrabat: false,
            visIndividuelOmkostningVurderingModal: false,
            tidligstenaesteberegning: Date.now(),
            senesteberegningforespoergsel: Date.now(),
            valgfriHoejAndel: '',
            valgfriLavAndel: ''
        };
        this.pensionsopsparingTextChange = this.pensionsopsparingTextChange.bind(this);
        this.pensionindbetalingTextChange = this.pensionindbetalingTextChange.bind(this);
        this.vaelgForventetPensionsalder = this.vaelgForventetPensionsalder.bind(this);
        this.vaelgAlder = this.vaelgAlder.bind(this);
        this.vaelgInvesteringsprofil = this.vaelgInvesteringsprofil.bind(this);
        this.vaelgTegningsmaade = this.vaelgTegningsmaade.bind(this);
        this.vaelgKundekapital = this.vaelgKundekapital.bind(this);
        this.betalingOmkostningRabatTextChange = this.betalingOmkostningRabatTextChange.bind(this);
        this.vaelgSaldoOmkostningRabat = this.vaelgSaldoOmkostningRabat.bind(this);
        this.toggleVisberegningsdetaljer = this.toggleVisberegningsdetaljer.bind(this);
        this.visberegningsdetaljerMulig = this.visberegningsdetaljerMulig.bind(this);
        this.visIndividuelOmkostningVurderingModal = this.visIndividuelOmkostningVurderingModal.bind(this);
        this.lukIndividuelOmkostningVurderingModal = this.lukIndividuelOmkostningVurderingModal.bind(this);
        this.valgfriHoejAndelTextChange = this.valgfriHoejAndelTextChange.bind(this);
        this.dispatchBeregnOmkostninger = this.dispatchBeregnOmkostninger.bind(this);
    }
    opsparingOvermax(opsparing) {
        return opsparing && this.props.maxDepotVardi && (opsparing >= this.props.maxDepotVardi);
    }
    individuelOmkostningVurdering() {
        let individuelOmkostningVurdering = false;        

        if (this.props.omkostninger) {
            individuelOmkostningVurdering = this.opsparingOvermax(this.props.omkostninger.pensionsopsparingEfter1Aar);
        }
        return individuelOmkostningVurdering;
    }
    componentWillUpdate(nextProps) {
        if (nextProps.omkostninger && nextProps.omkostninger) {
            if (this.opsparingOvermax(nextProps.omkostninger.pensionsopsparingEfter1Aar)) {
                this.state.visIndividuelOmkostningVurderingModal = true;
            }
        }
    }
    lukIndividuelOmkostningVurderingModal(){
        this.setState({visIndividuelOmkostningVurderingModal: false})
    }
    visIndividuelOmkostningVurderingModal(){
        this.setState({visIndividuelOmkostningVurderingModal: true})
    }
    muligePersonAldre() {
        let muligeAldre = [];

        for (var i = 0; i <= 67; i++) {
            muligeAldre.push(i);
        }
        
        let buildItem = function(alder, index) {
            return <option key={index}>{alder}</option>;
        };        

        return muligeAldre.map(buildItem);
    }
    pensionsopsparingTextChange(event) {
      const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
         this.state.pensionsopsparing = event.target.value;
         this.beregnOmkostninger();
         this.setState({pensionsopsparing: this.state.pensionsopsparing});
      }        
    }
    pensionindbetalingTextChange(event) {
      const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
         this.state.pensionindbetaling = event.target.value;
         this.beregnOmkostninger();
         this.setState({pensionindbetaling: this.state.pensionindbetaling});         
      }        
    }
	valgfriHoejAndelTextChange(event) {
        const re = /^[0-9\b]+$/;
        this.state.valgfriLavAndel = '';
        if (event.target.value !== '' && re.test(event.target.value)) {
            let parsedValue = parseInt(event.target.value.replace(/,/g, '.'))
            if (parsedValue <= 100)
            {
                this.state.valgfriHoejAndel = parsedValue;
                this.state.valgfriLavAndel = 100 - parsedValue;
                this.beregnOmkostninger();
                this.setState({valgfriHoejAndel: this.state.valgfriHoejAndel});
            }
        }  
        else
        {
            this.setState({valgfriHoejAndel: ''});
        }
    }
    vaelgForventetPensionsalder(event) {
         this.state.forventetpensionsalder = event.target.value;
         this.beregnOmkostninger();
         this.setState({forventetpensionsalder: this.state.forventetpensionsalder});         
    }
    vaelgAlder(event) {
         this.state.alder = event.target.value;
         this.beregnOmkostninger();
         this.setState({alder: this.state.alder});         
    }
    vaelgInvesteringsprofil(event) {
        console.log("vaelgInvesteringsprofil");
         this.state.investeringsprofil = event.target.value;
         this.beregnOmkostninger();
         this.setState({investeringsprofil: this.state.investeringsprofil});         
    }
    vaelgTegningsmaade(event) {
         this.state.tegningsmaade = event.target.value;
         this.beregnOmkostninger();
         this.setState({tegningsmaade: this.state.tegningsmaade});         
    }
    vaelgKundekapital(event) {
         this.state.kundekapital = !this.state.kundekapital;
         this.beregnOmkostninger();
         this.setState({kundekapital: this.state.kundekapital});         
    }
    betalingOmkostningRabatTextChange(event) {

        const re = /^([0-9]|\d{1,2},{0,1}\d{0,1}?\d{0,1})$/;
        if (event.target.value === '' || re.test(event.target.value)) {

            let enteredValue = event.target.value;
            let parsedValue = 0;
            if (enteredValue)  {
                parsedValue = parseFloat(enteredValue.replace(/,/g, '.'))
            }
            if (parsedValue <= 100) {  
                let newValue = enteredValue;
                if (enteredValue) {
                    let roundedValue = parsedValue.toFixed(2);
                    let diff = parsedValue - roundedValue;
                    if (diff !== 0) {
                        newValue = this.state.betalingomkostningrabat;
                    }
                }
            
                if (newValue !== this.state.betalingomkostningrabat) {
                    this.state.betalingomkostningrabat = newValue; 
                    this.beregnOmkostninger();
                    this.setState({betalingomkostningrabat: this.state.betalingomkostningrabat}); 
                }
            }
        }
    }
    vaelgSaldoOmkostningRabat(event) {
         this.state.saldoomkostningrabat = !this.state.saldoomkostningrabat;
         this.beregnOmkostninger();
         this.setState({saldoomkostningrabat: this.state.saldoomkostningrabat});         
    }
    toggleVisberegningsdetaljer() {
         this.setState({visberegningsdetaljer: !this.state.visberegningsdetaljer});         
    }
    dispatchBeregnOmkostninger() {      
        //console.log('dispatchBeregnOmkostninger...');  
        let omkostningBeregningInput = {};
        omkostningBeregningInput.pensionsopsparing = this.state.pensionsopsparing;
        omkostningBeregningInput.pensionindbetaling = this.state.pensionindbetaling;
        omkostningBeregningInput.valgfriHoejAndel = this.state.valgfriHoejAndel;
        omkostningBeregningInput.forventetpensionsalder = this.state.forventetpensionsalder;
        omkostningBeregningInput.alder = this.state.alder;
        omkostningBeregningInput.investeringsprofil = this.state.investeringsprofil;
        omkostningBeregningInput.tegningsmaade = this.state.tegningsmaade;
        if (this.props.udvidet) {
            omkostningBeregningInput.kundekapital = this.state.kundekapital;
            if (this.state.betalingomkostningrabat) {
                let parsedValue = parseFloat(this.state.betalingomkostningrabat.replace(/,/g, '.'))
                omkostningBeregningInput.betalingOmkostningRabat = parsedValue / 100;
            }
            omkostningBeregningInput.saldoOmkostningRabat = this.state.saldoomkostningrabat;
        }
        this.state.tidligstenaesteberegning = Date.now() + 1000;
        this.props.dispatchBeregnOmkostninger(omkostningBeregningInput);
    }
    beregnOmkostninger() {
        this.state.senesteberegningforespoergsel = Date.now(); 
        
        if (this.state.senesteberegningforespoergsel >= this.state.tidligstenaesteberegning) {
            this.dispatchBeregnOmkostninger();
        }
        else {
            setTimeout(this.dispatchBeregnOmkostninger, 1000);
        }
    }
    beregnerIkon() {
        if (this.props.omkostninger) {
            if (this.individuelOmkostningVurdering()) {
                return <i className="fa fa-warning"/>;
            }
            else if (this.props.omkostninger.beregner) {
                    return <i className="fa fa-calculator fa-spin"/>;
            }
            
        }
        return <i className="fa fa-calculator"/>;
    }
    omkostningerAfDinBetalingTilPFA() {
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {
            if (this.props.omkostninger && this.props.omkostninger.omkostningerAfDinBetalingTilPFA) {                
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.omkostningerAfDinBetalingTilPFA, 0) + ' kr.';
            }               
        }
        return result;
    }
    omkostningerAfDinBetalingTilDitPengeinstitut() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {
            if (this.props.omkostninger && this.props.omkostninger.omkostningerAfDinBetalingTilPengeinstituttet) {
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.omkostningerAfDinBetalingTilPengeinstituttet, 0) + ' kr.';
            }               
        }
        return result;
    }
    omkostningerAfDinSaldoTilPfaOgDitPengeinstitut() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {
            if (this.props.omkostninger && this.props.omkostninger.omkostningerAfSaldoPFAOgPengeinstitut) {
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.omkostningerAfSaldoPFAOgPengeinstitut, 0) + ' kr.';
            }               
        }
        return result;
    }
    investeringsOmkostninger() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {
            if (this.props.omkostninger && this.props.omkostninger.investeringsomkostninger) {
                
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.investeringsomkostninger, 0) + ' kr.';
            }               
        }
        return result;
    }
    standardAaok() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {
            if (this.props.omkostninger && this.props.omkostninger.standardAAOK) {
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.standardAAOK, 0) + ' kr.';
            }               
        }
        return result;
    }
    standardAaop() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {            
            if (this.props.omkostninger && this.props.omkostninger.standardAAOP) {
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.standardAAOP, 2) + ' %';
            }               
        }
        return result;
    }
    aarligIndbetalingEfterAmb() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {            
            if (this.props.omkostninger && this.props.omkostninger.aarligIndbetalingEfterAmb) {
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.aarligIndbetalingEfterAmb, 0) + ' kr';
            }               
        }
        return result;
    }    
    investeringsAfkast() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {            
            if (this.props.omkostninger && this.props.omkostninger.investeringsAfkast) {
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.investeringsAfkast, 0) + ' kr';
            }               
        }
        return result;
    }
    administrationOgInvesteringsomkostninger() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {            
            if (this.props.omkostninger && this.props.omkostninger.standardAAOK) {
                result = ' -' + FormatUtils.formaterBeloeb(this.props.omkostninger.standardAAOK, 0) + ' kr';
            }               
        }
        return result;
    }
    pensionsafkastskat() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {            
            if (this.props.omkostninger && this.props.omkostninger.pensionsafkastskat) {
                result = ' -' + FormatUtils.formaterBeloeb(this.props.omkostninger.pensionsafkastskat, 0) + ' kr';
            }               
        }
        return result;
    }
    nuvaerendePensionsopsparing() {        
        let result = "";
        if (this.state.pensionsopsparing && !this.individuelOmkostningVurdering()) {            
            if (this.state.pensionsopsparing) {
                result = ' ' + FormatUtils.formaterBeloeb(parseFloat(this.state.pensionsopsparing), 0) + ' kr';
            }               
        }
        return result;
    }
    pensionsopsparingEfter1Aar() {        
        let result = "";
        if (this.props.omkostninger && !this.individuelOmkostningVurdering()) {            
            if (this.props.omkostninger && this.props.omkostninger.pensionsopsparingEfter1Aar) {
                result = ' ' + FormatUtils.formaterBeloeb(this.props.omkostninger.pensionsopsparingEfter1Aar, 0) + ' kr';
            }               
        }
        return result;
    }
    visberegningsdetaljerMulig() {
        if (this.individuelOmkostningVurdering() || this.standardAaop() === "") 
        {
            return false;
        }
        return true;
    }
    visberegningsdetaljerHeaderText() {
        if (this.state.visberegningsdetaljer) {
            return <div>Vis færre detaljer for beregningen <i className="fa fa-minus pl-10"></i></div>
        }
        return <div>Vis flere detaljer for beregningen <i className="fa fa-plus pl-10"></i></div>
    }

    render() {
        return (

			<div className="tab-pane fade in active" id="htab1">


                <Modal show={this.state.visIndividuelOmkostningVurderingModal} onHide={this.lukIndividuelOmkostningVurderingModal}  aria-labelledby="ModalHeader" >
                  <Modal.Header closeButton>
                    <Modal.Title id='ModalHeader'>Individuel vurdering</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Din aftale er så stor, at du skal have en individuel vurdering af, hvilke omkostninger du skal betale.
                    Du skal derfor tage fat i dit pengeinstitut, som kan vise dig en beregning, som tager udgangspunkt i netop
                    dine muligheder.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Modal.Dismiss className='btn btn-default'>Luk</Modal.Dismiss>
 
                  </Modal.Footer>
                </Modal>

				<div className="row">
					<div className="col-md-6">
    
                        <div className="mt-10">    
							<form className="form-horizontal" role="form">								

                                <div className="form-group">
        							<div className="col-sm-8 control-label">                                        
							            <a className="collapsed link-dark" data-toggle="collapse" href="#pensionsopsparingCollapseContent" aria-expanded="false" aria-controls="collapseContent"><i className="fa fa-info-circle"></i></a> Din nuværende pensionsopsparing (i kroner)
							            <div className="collapse" id="pensionsopsparingCollapseContent">
                                                <div className="text-left alert alert-success omkostnigberegner-alert"> 
                                                    <span className="small">Er du kunde i Letpension, kan du se din pensionsopsparing i Letportalen via din netbank. Ellers kan du se din pensionsopsparing i Pensionsinfo</span>
                                                </div>
							            </div>

                                    </div>
                                    <div className="col-sm-4">
								            <input type="text" value={this.state.pensionsopsparing} className="form-control" placeholder="Indtast værdi" onChange={this.pensionsopsparingTextChange} />
								    </div>
								</div>
                                <div className="form-group">
        							<div className="col-sm-8 control-label">                                        
							            <a className="collapsed link-dark" data-toggle="collapse" href="#pensionsindbetalingCollapseContent" aria-expanded="false" aria-controls="collapseContent"><i className="fa fa-info-circle"></i></a> Din årlige indbetaling til pension (i kroner)
							            <div className="collapse" id="pensionsindbetalingCollapseContent">
                                                <div className="text-left alert alert-success omkostnigberegner-alert"> 
                                                    <span className="small">Er du kunde i Letpension, kan du se dine årlige indbetalinger til pension i Letpension via din Netbank. Ellers kan du se dine indbetalinger via PensionsInfo. Hvis din pensionsopsparing bliver betalt via din arbejdsgiver, skal du indtaste indbetalingerne før betaling af arbejdsmarkedbidrag</span>
                                                </div>
							            </div>

                                    </div>

                                    <div className="col-sm-4">
								            <input type="text" value={this.state.pensionindbetaling} className="form-control" placeholder="Indtast værdi" onChange={this.pensionindbetalingTextChange}/>
								    </div>
								</div>
                                <div className="form-group">
        							<div className="col-sm-8 control-label">                                        
							            <a className="collapsed link-dark" data-toggle="collapse" href="#forventetpensionsalderCollapseContent" aria-expanded="false" aria-controls="collapseContent"><i className="fa fa-info-circle"></i></a> Din forventede pensionsalder
							            <div className="collapse" id="forventetpensionsalderCollapseContent">
                                                <div className="text-left alert alert-success omkostnigberegner-alert"> 
                                                    <span className="small">Din forventede pensionsalder har sammen med din nuværende alder betydning for beregningen af investeringsomkostninger. Investeringsrisikoen i de fire investeringsprofiler i PFA Plus nedtrappes gradvist frem mod den forventede pensionsalder. Investeringsomkostningerne ændrer sig i takt med nedtrapningen</span>
                                                </div>
							            </div>

                                    </div>
                                    <div className="col-sm-4">
								            <select className="form-control" onChange={this.vaelgForventetPensionsalder} value={this.state.forventetpensionsalder}>
									            <option>60</option>
									            <option>61</option>
									            <option>62</option>
									            <option>63</option>
									            <option>64</option>
									            <option>65</option>
									            <option>66</option>
									            <option>67</option>
								            </select>
								    </div>
								</div>
                                <div className="form-group">
        							<div className="col-sm-8 control-label">                                        
							            <a className="collapsed link-dark" data-toggle="collapse" href="#alderCollapseContent" aria-expanded="false" aria-controls="collapseContent"><i className="fa fa-info-circle"></i></a> Din alder
							            <div className="collapse" id="alderCollapseContent">
                                                <div className="text-left alert alert-success omkostnigberegner-alert"> 
                                                    <span className="small">Din nuværende alder har sammen med din forventede pensionsalder betydning for beregningen af investeringsomkostninger. Investeringsrisikoen i de fire investeringsprofiler i PFA Plus nedtrappes gradvist frem mod den forventede pensionsalder. Investeringsomkostningerne ændrer sig i takt med nedtrapningen</span>
                                                </div>
							            </div>

                                    </div>


                                    <div className="col-sm-4">
								            <select className="form-control" onChange={this.vaelgAlder} value={this.state.alder}>   
                                                {this.muligePersonAldre()}
								            </select>
								    </div>
								</div>
                                <div className="form-group">
        							<div className="col-sm-8 control-label">                                        
							            <a className="collapsed link-dark" data-toggle="collapse" href="#investeringsprofilCollapseContent" aria-expanded="false" aria-controls="collapseContent"><i className="fa fa-info-circle"></i></a> Din investeringsprofil
							            <div className="collapse" id="investeringsprofilCollapseContent">
                                                <div className="text-left alert alert-success omkostnigberegner-alert"> 
                                                    <span className="small">Din opsparing kan blive investeret i en af de fire investeringsprofiler A, B, C eller D, hvor risikoen bliver nedtrappet frem mod den forventede pensionsalder, eller i Investeringsprofilen Valgfri, hvor du selv vælger din fordeling, og hvor sammensætningen er fast, indtil du vælger noget andet.</span>
                                                    <br/>
                                                    <span className="small">Investeringsprofilen A har den laveste risiko og D har den højeste risiko</span>
                                                </div>
							            </div>
                                    </div>

                                    <div className="col-sm-4">
								            <select className="form-control" onChange={this.vaelgInvesteringsprofil} value={this.state.investeringsprofil}>
									            <option>A</option>
									            <option>B</option>
									            <option>C</option>
									            <option>D</option>
									            <option>Valgfri</option> 
								            </select>
								    </div>
								</div>

                                {this.state.investeringsprofil === "Valgfri" &&
                                <div>
                                    <div className="form-group">
                                        <div className="col-sm-1"/>
                                        <div className="col-sm-7">Andel i Høj risiko fonden</div>
                                        <div className="col-sm-4">
								                <input type="text" value={this.state.valgfriHoejAndel} className="form-control" placeholder="Indtast værdi" onChange={this.valgfriHoejAndelTextChange}/>
								        </div>
								    </div>

                                    <div className="form-group">
                                        <div className="col-sm-1"/>
                                        <div className="col-sm-7">Andel i Lav risiko fonden</div>
                                        <div className="col-sm-4">
								                <input type="text" value={this.state.valgfriLavAndel} className="form-control" placeholder="" onChange={this.valgfriLavAndelTextChange} readOnly/>
								        </div>
								    </div>
                                </div>
                                }
                                <div className="form-group">
        							<div className="col-sm-8 control-label">                                        
							            <a className="collapsed link-dark" data-toggle="collapse" href="#tegningsmaadeCollapseContent" aria-expanded="false" aria-controls="collapseContent"><i className="fa fa-info-circle"></i></a> Din pensionsordning bliver betalt
							            <div className="collapse" id="tegningsmaadeCollapseContent">
                                                <div className="text-left alert alert-success omkostnigberegner-alert"> 
                                                    <span className="small">Marker om din pensionsordning bliver betalt via din arbejdsgiver eller privat. Bliver din pensionsopsparing betalt via din arbejdsgiver, bliver arbejdsmarkedsbidraget trukke fra den årlige forventede indbetaling</span>
                                                </div>
							            </div>

                                    </div>

                                    <div className="col-sm-4">
								        <div className="radio">
                                            <label>
                                                <input type="radio" value="Arbejdsgiver" name="tegningsmaade" onChange={this.vaelgTegningsmaade} checked={this.state.tegningsmaade === 'Arbejdsgiver'}  /> via arbejdsgiver
                                            </label>
                                        </div>
								        <div className="radio">
                                            <label>
                                                <input type="radio" value="Privat" name="tegningsmaade" onChange={this.vaelgTegningsmaade} checked={this.state.tegningsmaade !== 'Arbejdsgiver'} /> privat
                                            </label>
                                        </div>
								    </div>
								</div>

                                {this.props.udvidet &&
                                    <div>
                                        <div className="form-group">
                                            <label className="col-sm-8 control-label">Kundekapital</label>
                                            <div className="col-sm-4">
								                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" name="kundekapital" onChange={this.vaelgKundekapital} checked={this.state.kundekapital === true}  />
                                                    </label>
                                                </div>
								            </div>
								        </div>

                                        <div className="form-group">
        							        <label className="col-sm-8 control-label">Rabat på omkostning af betaling (0 - 100%)</label>
                                            <div className="col-sm-4">
								                    <input value={this.state.betalingomkostningrabat} type="text" className="form-control" placeholder="Indtast værdi" onChange={this.betalingOmkostningRabatTextChange}/>
								            </div>
								        </div>

                                        <div className="form-group">
                                            <label className="col-sm-8 control-label">Rabat på omkostning af saldo</label>
                                            <div className="col-sm-4">
								                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" name="kundekapital" onChange={this.vaelgSaldoOmkostningRabat} checked={this.state.saldoomkostningrabat === true}  />
                                                    </label>
                                                </div>
								            </div>
								        </div>
                                    </div>
                                }

                            </form>
                        </div>
                        <br/>
                        <br/>

					</div>
					<div className="col-md-6">

                        <div className="mt-10">
                            <fieldset>
                                <legend>{this.beregnerIkon()} Årlige omkostninger</legend>
                                <fieldset className="white-bg">
                                    <legend>Direkte omkostninger</legend>
                                    <div><strong>Omkostninger af:</strong></div>
                                    <div className="elements-list clearfix"> 
                                        <span>din betaling til PFA</span>
                                        <span className="pull-right">{this.omkostningerAfDinBetalingTilPFA()}</span>
                                    </div>
                                    <div className="elements-list clearfix"> 
                                        <span>din betaling til dit pengeinstitut</span>
                                        <span className="pull-right">{this.omkostningerAfDinBetalingTilDitPengeinstitut()}</span>
                                    </div>
                                    <div className="elements-list clearfix"> 
                                        <span>din saldo til dit pengeinstitut</span>
                                        <span className="pull-right">{this.omkostningerAfDinSaldoTilPfaOgDitPengeinstitut()}</span>
                                    </div>

                                </fieldset>
                                <fieldset className="white-bg">
                                    <legend>Indirekte omkostninger</legend>
                                    <div className="elements-list clearfix"> 
                                        <span>Investeringsomkostninger til PFA</span>
                                        <span className="pull-right">{this.investeringsOmkostninger()}</span>
                                    </div>

                                </fieldset>

                                <fieldset className="white-bg">
                                    <legend>Standard årlige omkostninger</legend>
                                    <div className="elements-list clearfix"> 
                                        <span>I kroner (ÅOK)</span>
                                        <span className="pull-right">{this.standardAaok()}</span>
                                    </div>
                                    <div className="elements-list clearfix"> 
                                        <span>I procent (ÅOP)</span>
                                        <span className="pull-right">{this.standardAaop()}</span>
                                    </div>

                                    {this.visberegningsdetaljerMulig() &&
                                    <div className="mt-10">            
							            <a className="collapsed link-dark" data-toggle="collapse" href="#collapseContent" onClick={this.toggleVisberegningsdetaljer} aria-expanded="false" aria-controls="collapseContent">{this.visberegningsdetaljerHeaderText()}</a>
							            <div className={this.state.visberegningsdetaljer ? "collapse in" : "collapse"} id="collapseContent">
                                                <div className="mt-10 elements-list clearfix"> 
                                                    <span className="small">Nuværende pensionsopsparing</span>
                                                    <span className="small pull-right">{this.nuvaerendePensionsopsparing()}</span>
                                                </div>
                                                <div className="elements-list clearfix"> 
                                                    <span className="small">Årlig indbetaling efter AMB</span>
                                                    <span className="small pull-right">{this.aarligIndbetalingEfterAmb()}</span>
                                                </div>
                                                <div className="elements-list clearfix"> 
                                                    <span className="small">Afkast før investeringsomkostninger og pal</span>
                                                    <span className="small pull-right">{this.investeringsAfkast()}</span>
                                                </div>                                                
                                                <div className="elements-list clearfix"> 
                                                    <span className="small">Administrations- og investeringsomkostninger</span>
                                                    <span className="small pull-right">{this.administrationOgInvesteringsomkostninger()}</span>
                                                </div>
                                                <div className="elements-list clearfix"> 
                                                    <span className="small">Pensionsafkastskat 15,3 %</span>
                                                    <span className="small pull-right">{this.pensionsafkastskat()}</span>
                                                </div>
                                                <div className="elements-list clearfix"> 
                                                    <span className="small">Pensionsopsparing efter 1 år</span>
                                                    <span className="small pull-right"><u>{this.pensionsopsparingEfter1Aar()}</u></span>
                                                </div>

							            </div>
							        </div>
                                    }



                                </fieldset>

                            </fieldset>


                        </div>

					</div>
				</div>

                <div className="row">
                    <div className="col-md-12">
                        <h4 className="title">Årlige omkostninger i procent</h4>
                        <p>Dine omkostninger bliver vist som årlige omkostninger i kroner (ÅOK) og årlige omkostninger i procent af opsparingen (ÅOP). I de årlige omkostninger indgår både direkte og indirekte omkostninger.</p>

                        <h4 className="title">Hvad koster det at ændre en Letsikring af indtægt ved pension? </h4>
                        <p>Har du en Letsikring af indtægt ved pension og overvejer du at ændre din aftale, kan der være omkostninger forbundet med ændringen. <a className="link-dark" target="_blank" href="/dokumenter/priseraendringletsikringafindtaegtvedpension">Her</a> kan du læse mere om, hvad det koster at ændre en Letsikring af indtægt ved pension.</p>
                    </div>
                </div>


			</div>


      );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OmkostningBeregner);
