import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Promise from 'promise-polyfill';

import { store } from 'reducers/store.jsx'
import App from './app.jsx';

require('../../Resources/css/custom.css');


if (!window.Promise) {
    window.Promise = Promise;
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('content')
);
