import ApiUtils from '../util/apiUtils.jsx'
import { indlaesBruger } from "./bruger";

export const CPR_MATCH = 'CPR_MATCH';
export const CPR_MATCH_SLUT = 'CPR_MATCH_SLUT';
export const CPR_MATCH_FEJL = 'CPR_MATCH_FEJL';

export function cprMatch() {
    return {
        type: CPR_MATCH
    }
}

export function cprMatchSlut() {
    return {
        type: CPR_MATCH_SLUT
    }
}

export function cprMatchFejl() {
    return {
        type: CPR_MATCH_FEJL
    }
}

export function tilknytCpr(cpr) {

    return (dispatch, getState) => {

        //console.log('Action bestyrelse ')
        dispatch(cprMatch());
        let url = ApiUtils.apiRootUrl() + 'login/tilknytcpr';
        //console.log('Action indlaesBestyrelse ' + url)        

        let token = ApiUtils.getAuthenticationToken();
        let header = ApiUtils.buildHeader(token);

        return fetch(url,
                {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify({
                        cpr: cpr
                    })
                }
            )
            .then(ApiUtils.checkStatus)
            .then(response =>
                response.json()
                .then(json => {
                    dispatch(cprMatchSlut());
                    //console.log('Action tilknytCpr: ' + JSON.stringify(json));
                    sessionStorage.removeItem("lptoken");
                    sessionStorage.setItem("lptoken", json.token);
                    document.cookie = "lptoken=" + json.token;
                    dispatch(indlaesBruger());
                }),
                error => {
                    console.log('Action bruger error.', error);
                    let fejlTekst = 'Fejl ved tilknytning af cpr nummer';
                    dispatch(cprMatchFejl(fejlTekst));
                });
    }
}




