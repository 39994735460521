import { SubmissionError } from 'redux-form';
import { store } from 'reducers/store';

var ApiUtils = {
    checkStatus: function(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    },
    checkStatusForm: function(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else if(response.status === 400) {
            return response.json().then(
                response => { throw new SubmissionError(response) },
                error => { throw new SubmissionError({_error: 'Ukendt fejl'})}
            );
        } else {
            console.log('Server error: ' + response.status + ' response.statusText');
            throw new SubmissionError({_error: 'Ukendt serverfejl'});
        }
    },
    errorForm: function() {
        throw new SubmissionError({_error: 'Forbindelsesfejl'});
    },
    buildHeader: function(token) {
        let headers = { 'Content-Type': 'application/json;charset=UTF-8', };
        headers.Accept = 'application/json';
        headers.Authorization = 'Bearer ' + token;

        //console.log('Headers: ' + JSON.stringify(headers));
        return headers;
    },
    buildAuthenticationToken: function(username, password) {
        return btoa(username + ':' + password);
    },
    getAuthenticationToken() {
        let token = sessionStorage["lptoken"];
        return btoa(token);
    },
    apiRootUrl: function() {
        return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/api/v1/';
    },
    wrapPromise: function(path, promise) {
        let getContextKey = function() {
            let reducerBruger = store.getState().bruger.bruger;
            return reducerBruger && reducerBruger.id + ':' + reducerBruger.tilbudId;
        }
        let contextKey = getContextKey();
        //let id = uuidv4();
        return new window.Promise((resolve, reject) => {
            //console.log("Start call " + path + ":" + id + " - "+contextKey);
            promise.then(function() {
                    let endContextKey = getContextKey(); 
                    //console.log("End call " + path + ":" + id + " - "+endContextKey);
                    if (contextKey === endContextKey) {
                        resolve.apply(this, arguments);
                    } else {
                        console.log("Promise fulfillment ignored because of changing user context");
                    }
                },
                function() {
                    let endContextKey = getContextKey(); 
                    //console.log("End call " + id + " - "+endContextKey);
                    if (contextKey === endContextKey) {
                        reject.apply(this, arguments);
                    } else {
                        console.log("Promise fulfillment ignored because of changing user context");
                    }
                });
        });
    },
    fetch: function(path, callerOptions) {
        let token = ApiUtils.getAuthenticationToken();
        let headers = ApiUtils.buildHeader(token);
        callerOptions = callerOptions || {};
        let formMode = callerOptions.formMode;
        if (formMode) {
            delete callerOptions.formMode;
        }
        if (callerOptions.headers) {
            headers = Object.assign(headers, callerOptions.headers);
        }
        let url = ApiUtils.apiRootUrl() + path;
        let options = Object.assign({ method: 'GET' }, callerOptions, { headers });
        if (formMode) {
            return ApiUtils.wrapPromise(path, fetch(url, options).then(ApiUtils.checkStatusForm, ApiUtils.errorForm));
        } else {
            return ApiUtils.wrapPromise(path, fetch(url, options).then(ApiUtils.checkStatus));
        }
    },
    get: function(path, options) {
        console.log("Get " + path);
        return ApiUtils.fetch(path, Object.assign({ method: 'GET' }, options))
            .then(response => {
                return response.json();
            });
    },
    post: function(path, body, options) {
        return ApiUtils.fetch(path, Object.assign({ method: 'POST', body: JSON.stringify(body) }, options))
            .then(response => {
                return response.json();
            });
    },
    put: function(path, id, body, options) {        
        return ApiUtils.fetch(path + "/" + id, Object.assign({ method: 'PUT', body: JSON.stringify(body) }, options))
            .then(response => {
                return response.json();
            });
    },
    delete: function(path, id, options) {
        return ApiUtils.fetch(path + "/" + id, Object.assign({ method: 'DELETE' }, options));
    },
    log: function(alvorlighedsgrad, besked, error) {
        let stackTrace = error && error.stack && typeof error.stack === 'string' ? error.stack : (error ? error.toString() : null);
        return ApiUtils.post('log/'+alvorlighedsgrad, { alvorlighedsgrad, besked, stackTrace });
    },
    error: (msg, error) => ApiUtils.log('SystemError', msg, error),
    fatal: (msg, error) => ApiUtils.log('Fatal', msg, error),
    warn: (msg, error) => ApiUtils.log('Warning', msg, error),
    info: (msg, error) => ApiUtils.log('Info', msg, error)
};

export { ApiUtils as default };

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}