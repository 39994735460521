
import {
  VIS_INFOMODAL,
  OPDATER_INFOMODAL,
  SKJUL_INFOMODAL,
} from 'actions/infoModal.jsx'

export default function infoModal(state = {
    viser: false,
    titel: '',
    indhold: null
}, action)
{
  switch (action.type) {
    case VIS_INFOMODAL:
        return Object.assign({},
            state,
            {
                viser: true,
                titel: action.titel,
                indhold: action.indhold,
                skjulLuk: action.skjulLuk
            });
    case OPDATER_INFOMODAL:
        return Object.assign({},
            state,
            {
                indhold: action.indhold,
                skjulLuk: action.skjulLuk
            });
    case SKJUL_INFOMODAL:
        return Object.assign({},
            state,
            {
                viser: false
            });
    default:
      return state;
  }
}
