import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class Begunstigelse extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Hvem skal have pengene, hvis du ikke skal?</h3>
                            <div className="separator center"></div>
                            <p>Når du opretter en livsforsikring, beslutter du, hvem du vil sikre med livsforsikringen. På samme måde er det vigtigt, at du tager stilling til, hvem der skal have din pensionsopsparing, hvis du dør, før du selv får den udbetalt.</p>
                            <p>I lovgivningen er der regler for, hvem man kan vælge som modtager(e) af udbetalingen, det vil sige, hvem man kan vælge som begunstiget. Nogle af reglerne bør du kende til og dem kan du læse mere om i vejledningen "Hvem skal have pengene, hvis du ikke skal".</p>
                            <p>For at vælge en modtager eller ændre din valgte modtager, skal du kontakte din rådgiver i dit pengeinstitut.</p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>


			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-sm-8">
										<h3 className="title">Hvem skal du vælge som modtager?</h3>
										<p>Vil du vide mere om, hvem du kan vælge som modtager(e) af din pensionsopsparing, kan du læse om reglerne i vores vejledning om begunstigelse.</p>
									</div>
									<div className="col-sm-4">
										<br/>
										<p><a href="/dokumenter/vejledningtilbegunstigelse" className="btn btn-lg btn-gray-transparent btn-animated">Læs vejledning<i className="fa fa-arrow-right pl-20"></i></a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/omletpension#top" billede="/Resources/images/Letpension/om_Letpension_457x300.jpg" tekst="Om Letpension" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>



</div>


      );
    }
}




