import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class Investering extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Sådan bliver dine penge investeret</h3>
                            <div className="separator center"></div>
                            <p>For at sikre dine penge bedst muligt samarbejder Letpension med PFA Pension. Det betyder, at det er PFA Pension, der adminstrerer investeringen af din opsparing. Her kan du læse mere om, hvordan din opsparing bliver investeret, få information om investeringsfondene og se det seneste afkast.</p>
                            <p>Er du allerede kunde i Letpension, kan du se dit investeringsvalg og dit præcise afkast ved at logge ind på Letportalen enten direkte via siden her eller via din netbank.</p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>

			<section className="section clearfix">
			    <div className="container white-bg">
			        <Tabs defaultActiveKey={1} id="tabs">
			            <Tab eventKey={1} title={<div><i className="fa fa-line-chart pr-10"></i>Investering og afkast</div>}>
			                <div className="letTabContent active">
    			                <div className="row">
							        <div className="col-md-6">
                                        <h4 className="title">Hvordan bliver mine penge investeret?</h4>
                                        <p>Når du køber en Letsikring af indtægt ved pension, kan du vælge, hvordan din opsparing bliver investeret. Du kan vælge mellem to forskellige investeringskoncepter.</p>
                                        <div className="pl-20">
								        <p><b>a. Investeringskoncept med fire investeringsprofiler: A, B, C og D</b></p>
                                        <p>Forskellen på de fire investeringsprofiler er hvor stor en andel af din opsparing, der bliver investeret i risikofyldte aktiver. Du skal altså tage stilling til, hvor risikovillig du er.</p>
								        <p><b>b. Investeringskoncept Valgfri</b></p>
								        <p>Du vælger selv en tilsigtet fordeling mellem risikofondene, Lav og Høj. PFA Pension tilpasser mindst to gange om året den faktiske fordeling til din valgte tilsigtede fordeling. Du skal selv sørge for løbende at justere og tilpasse din tilsigtede risiko, herunder fx i forhold til dit pensionstidspunkt.</p>
                                        </div>
                                        <p>Du kan læse mere om investeringskoncepter og -profiler under fanen ”Investeringskoncepter”</p>
                                        <p>Din opsparing bliver investeret via to fonde: Høj risiko fonden og Lav risiko fonden. Høj risiko fonden vil typisk bestå af aktier og investeringer med lignende risiko som aktier. Vælger du en investeringsprofil med en stor del af din opsparing i Høj risiko</p>
							        </div>
							        <div className="col-md-6">
								        <p>fonden, kan saldoen på din opsparing svinge meget. Til gengæld vil du over tid have mulighed for at få et højere afkast. Vælger du en investeringsprofil, hvor en mindre del af din opsparing er investeret i Høj risiko fonden, vil saldoen på din opsparing ikke svinge så meget. Til gengæld er din mulighed for et højt afkast mindre. Det betyder, at jo mere risikovillig du er, jo større er potentialet for en større pension.</p>
								        <p>Du kan læse mere om Høj risiko fonden og Lav risiko fonden under fanen ”Investeringsfonde”. Når du opretter en Letsikring af indtægt ved pension, skal du sammen med din rådgiver vurdere, hvor risikovillig du er.</p>
                                        <h4 className="title">PFA KundeKapital</h4>
                                        <p>Du kan vælge, at 5 % af betalingerne til din opsparing skal gå til PFA KundeKapital. For PFA KundeKapital kan du ikke vælge, hvordan din opsparing bliver investeret, og du er ikke sikret udbetaling af en vis størrelse.</p>
                                        <p>Du kan læse mere under fanen ”PFA KundeKapital”.</p>

                                        <h4 className="title">Se dit afkast</h4>
                                        <p>Er du kunde i Letpension, kan du se dit specifikke afkast ved at logge ind på Letportalen enten via ”Log ind” øverst på siden her eller via din netbank.</p>
                                        <p>Her kan du læse mere generelt om vores <a className="link-dark" target="_blank" href="/dokumenter/afkast">investeringsafkast</a>.</p>
			                        </div>
			                    </div>
			                </div>
			            </Tab>
			            <Tab eventKey={2} title={<div><i className="fa fa-pie-chart pr-10"></i>Investeringsprofiler</div>}>
						    <div className="letTabContent">
    						    <div className="row">
							        <div className="col-md-6">
                                        <h4 className="title">Letpensions to investeringskoncepter</h4>
                                        <p>Som kunde i Letpension kan du vælge mellem to forskellige investeringskoncepter: Ét, hvor du vælger mellem fire investeringsprofiler og ét, hvor du selv vælger fordelingen i fondene.</p>
								        <h4 className="title">Investeringsprofil A-D</h4>
								        <p>Forskellen på de fire investeringsprofiler er først og fremmest, hvor stor en andel af din opsparing, der bliver investeret i risikofyldte aktiver. Du skal altså tage stilling til, hvor risikovillig du er.                                    
								        </p>
								        <p>For investeringsprofilerne A-D gælder, at det tilsigtede risikoniveau, du vælger, bestemmer fordelingen mellem Høj risiko fonden og Lav risiko fonden.</p>
								        <h4 className="title">Investeringsprofil A</h4>
								        <p><em>Meget lav andel af opsparing i Høj risiko fonden</em><br/>
                                        25 % af din opsparing bliver investeret i bl.a. aktier i Høj risiko fonden. 75 % bliver investeret i bl.a. obligationer i Lav risiko fonden. Vi trapper gradvist din andel af investeringer i Høj risiko fonden ned, så den er ca. 10 %, når du går på pension. Det gør vi for at sikre dine penge bedre, når du nærmer dig pensionsalderen.</p>
								        <p>Sammensætningen af investeringerne i profil A kan sammenlignes med en traditionel pensionsopsparing til gennemsnitsrente.</p>
								        <p>Med profil A har du mulighed for at tilknytte sikring af udbetalingen.</p>
								        <h4 className="title">Investeringsprofil B</h4>
								        <p><em>Lav andel af opsparing i Høj risiko fonden</em><br/>
								        50 % af din opsparing bliver investeret i bl.a. aktier i Høj risiko fonden. 50 % bliver investeret i bl.a. obligationer i Lav risiko fonden.</p>
							        </div>
							        <div className="col-md-6">
								        <p>Vi trapper gradvist din andel af investeringer i Høj risiko fonden ned, så den er ca. 20 %, når du går på pension. Det gør vi for at sikre dine penge bedre, når du nærmer dig pensionsalderen.</p>
								        <p>Med profil B har du mulighed for at tilknytte sikring af udbetalingen.</p>
								        <h4 className="title">Investeringsprofil C</h4>
								        <p><em>Mellem andel af opsparing i Høj risiko fonden</em><br/>
								            75 % af din opsparing bliver investeret i bl.a. aktier i Høj risiko fonden. 25 % bliver investeret i bl.a. obligationer i Lav risiko fonden. Vi trapper gradvist din andel af investeringer i Høj risiko fonden ned, så den er ca. 30 %, når du går på pension. Det gør vi for at sikre dine penge bedre, når du nærmer dig pensionsalderen.</p>
								        <h4 className="title">Investeringsprofil D</h4>
								        <p><em>Høj andel af opsparing i Høj risiko fonden</em><br/>
								            100 % af din opsparing bliver investeret i bl.a. aktier i Høj risiko fonden. Vi trapper gradvist din andel af investeringer i Høj risiko fonden ned, så den er ca. 40 %, når du går på pension. Det gør vi for at sikre dine penge bedre, når du nærmer dig pensionsalderen.</p>
								        <h4 className="title">Investeringskoncept Valgfri</h4>
								        <p><em>Valgfri andel af opsparing i Høj risiko fonden</em><br/>
								            Investeringskoncept Valgfri er til dig, der har indsigt og interesse i investering. Du vælger selv, hvor stor en andel af din opsparing, der skal være i Høj risiko fonden. D fra din valgte fordeling, vælger PFA investeringerne for dig. Du tager altså selv ansvaret for den løbende fastlæggelse af risikoen på din pensionsopsparing, men overlader de løbende investeringer til PFA.</p>
								        <p>Modsat investeringsprofil A-D bliver andelen i Høj risiko fonden IKKE nedtrappet, når du nærmer dig pensionsalderen, medmindre du selv aktivt vælger at ændre fordelingen mellem Høj risiko fonden og Lav risiko fonden. Vi anbefaler, at du løbende tilpasser risikoen i takt med, at du nærmer dig pensionsalderen.</p>
						            </div>
						        </div>
						    </div>
			            </Tab>
			            <Tab eventKey={3} title={<div><i className="fa fa-bar-chart pr-10"></i>Investeringsfonde</div>}>
						    <div className="letTabContent">
							    <div className="row">
								    <div className="col-md-6">
                                        <h4 className="title">Hvordan er de to investeringsfonde bygget op?</h4>
                                        <p>Din opsparing bliver investeret via Høj risiko fonden og Lav risiko fonden:</p>

                                        <h4 className="title">Høj risiko fonden</h4>
                                        <p>Høj risiko fonden består af aktier (75-100 %) samt investeringer med lignende risiko som aktier, kaldet aktiederivater. Sammensætningen er altså en kombination af en faktisk aktiebeholdning og fire typer af aktiederivater:</p>

							            <ul className="nav flex-column list-style-icons">
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Noterede aktiefutures
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Unoterede aktiefutures
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Noterede aktieoptioner
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Unoterede aktieoptioner
                                            </li>
							            </ul>
                                        <br/>
                                        <p>Kombinationen af aktiefutures, aktieoptioner og den faktiske aktiebeholdning skal kunne indeholdes i de overordnede aktierammer.</p>

                                        <h4 className="title">Lav risiko fonden</h4>
                                        <p>Lav risiko fonden består af kreditobligationer (0-30 %), obligations- og rentederivater, valutaderivater samt kreditderivater.</p>
                                        <p>Obligations- og rentederivaterne omfatter:</p>

							            <ul className="nav flex-column list-style-icons">
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Rentefutures (børsnot og OTC)
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Renteswap (OTC)
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Renteoptioner (cap/floor og OTC)
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Swapoptioner (OTC)
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                FRA (OTC)
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Strukturerede obligationsprodukter
                                            </li>
							            </ul>


								    </div>
								    <div className="col-md-6">
                                        <p>Valutaderivaterne omfatter:</p>
							            <ul className="nav flex-column list-style-icons">
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                FX Optioner (OTC)
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                FX Forward (OTC)
                                            </li>
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Valutaswap (OTC)
                                            </li>
							            </ul>

                                        <br/>
                                        <p>Kreditderivaterne omfatter:</p>
							            <ul className="nav flex-column list-style-icons">
                                            <li className="nav-item">
                                                <i className="fa fa-caret-right pr-10"></i>
                                                Credit default swaps
                                            </li>
							            </ul>

                                        <br/>
                                        <p>Kombinationen af kreditobligationer og de forskellige derivater skal kunne indeholdes i den overordnede obligation.</p>
                                        <p>I Høj risiko fonden vil aktieandelen ligge mellem 75 % og 100 %. I Høj risiko fonden kan der anvendes finansielle instrumenter, således at eksponeringen ved aktieoptioner og aktiefutures sammen med den faktiske aktiebeholdning skal kunne indeholdes i de overordnede aktierammer. De finansielle instrumenter der kan anvendes, er aktiederivater i form af noterede og unoterede aktiefutures og noterede og unoterede aktieoptioner.</p>
                                        <p>I Lav risiko fonden vil andelen af kreditobligationer ligge mellem 0 % og 30 %. I Lav risiko fonden kan der anvendes finansielle instrumenter, således at eksponeringen skal kunne indeholdes i de overordnede obligationsrammer. De finansielle instrumenter der kan anvendes er Obligations- og rentederivater i form af rentefutures (børsnot og OTC), renteswap (OTC), Renteoptioner (cap/floor)(OTC), swapoptioner (OTC), FRA (OTC) og trukturerede obligationsprodukter, samt valutaderivater i form af FX optioner (OTC), FX forward (OTC) og valutaswap (OTC), samt kreditderivater i form af credit default swaps.</p>

								    </div>
							    </div>
						    </div>
			            </Tab>
			            <Tab eventKey={4} title={<div><i className="fa fa-users pr-10"></i>PFA KundeKapital</div>}>
			                <div className="letTabContent">
			                    <div className="row">
			                        <div className="col-md-6">
			                            <h4 className="title">Sådan fungerer PFA KundeKapital</h4>
			                            <p>Når du køber en Letsikring af indtægt ved pension, vil den blive oprettet i PFA Pension. Som kunde i Letpension kan du derfor vælge, at 5 % af de fremtidige betalinger til din opsparing skal gå til PFA KundeKapital.</p>
			                            <p>PFA KundeKapital er ansvarlig kapital i PFA Pension på linje med egenkapitalen og kan sammenlignes med en investering i PFA Pension. PFA KundeKapital får mindst samme afkast som egenkapitalen, men kan også være forbundet med risiko. PFA KundeKapital er nemlig en del af PFA Pensions kapitalgrundlag og skal derfor – sammen med egenkapitalen – dække, hvis PFA Pension lider tab.</p>
			                            <p>Det betyder, at PFA KundeKapital kan blive mindre ved negativt afkast og i yderste konsekvens tabes helt.</p>
			                        </div>
			                        <div className="col-md-6">
			                            <p>Du kan ikke vælge, hvordan din opsparing i PFA KundeKapital bliver investeret og du kan ikke sikre størrelsen af udbetaling fra PFA KundeKapital.</p>
			                            <p>PFA KundeKapital bliver udbetalt sammen med udbetalingerne fra din Letsikring af indtægt ved pension.</p>
			                            <p><a className="link-dark" target="_blank" href="https://pfa.dk/privat/opsparing/pfa-kundekapital/">Her</a> kan du læse mere om PFA KundeKapital.</p>
			                        </div>
			                    </div>
			                </div>
			            </Tab>
                    </Tabs>
                </div>
            </section>

			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-sm-8">
										<h3 className="title">Hvad betaler du for din Letsikring? </h3>
										<p>Vil du vide mere om, hvad du betaler for at have en Letsikring af indtægt ved pension i Letpension, kan du se mere her.</p>
									</div>
									<div className="col-sm-4">
										<br/>
										<p><SiteLink url="/produktet/omkostninger#top" classname="btn btn-lg btn-gray-transparent btn-animated">Se mere om omkostninger<i className="fa fa-arrow-right pl-20"></i></SiteLink></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/blivkunde#top" billede="/Resources/images/Letpension/Find_pengeinstitut_457x300.jpg" tekst="Bliv kunde i Letpension" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>



</div>


      );
    }
}



class Test extends Component {
    render() {
        return (
            <div>
							    <div className="col-md-6">
                                    <h4 className="title">Hvordan bliver mine penge investeret?</h4>
                                    <p>Når du køber en Letsikring af indtægt ved pension, kan du vælge, hvordan din opsparing bliver investeret. Du kan vælge mellem to forskellige investeringskoncepter.</p>
                                    <div className="pl-20">
								    <p><b>a. Investeringskoncept med fire investeringsprofiler: A, B, C og D</b></p>
                                    <p>Forskellen på de fire investeringsprofiler er hvor stor en andel af din opsparing, der bliver investeret i risikofyldte aktiver. Du skal altså tage stilling til, hvor risikovillig du er.</p>
								    <p><b>b. Investeringskoncept Valgfri</b></p>
								    <p>Du vælger selv en tilsigtet fordeling mellem risikofondene, Lav og Høj. PFA Pension tilpasser mindst to gange om året den faktiske fordeling til din valgte tilsigtede fordeling. Du skal selv sørge for løbende at justere og tilpasse din tilsigtede risiko, herunder fx i forhold til dit pensionstidspunkt.</p>
                                    </div>
                                    <p>Du kan læse mere om investeringskoncepter og -profiler under fanen ”Investeringskoncepter”</p>
                                    <p>Din opsparing bliver investeret via to fonde: Høj risiko fonden og Lav risiko fonden. Høj risiko fonden vil typisk bestå af aktier og investeringer med lignende risiko som aktier. Vælger du en investeringsprofil med en stor del af din opsparing i Høj risiko</p>
							    </div>
							    <div className="col-md-6">
								    <p>fonden, kan saldoen på din opsparing svinge meget. Til gengæld vil du over tid have mulighed for at få et højere afkast. Vælger du en investeringsprofil, hvor en mindre del af din opsparing er investeret i Høj risiko fonden, vil saldoen på din opsparing ikke svinge så meget. Til gengæld er din mulighed for et højt afkast mindre. Det betyder, at jo mere risikovillig du er, jo større er potentialet for en større pension.</p>
								    <p>Du kan læse mere om Høj risiko fonden og Lav risiko fonden under fanen ”Investeringsfonde”. Når du opretter en Letsikring af indtægt ved pension, skal du sammen med din rådgiver vurdere, hvor risikovillig du er.</p>
                                    <h4 className="title">PFA KundeKapital</h4>
                                    <p>Du kan vælge, at 5 % af betalingerne til din opsparing skal gå til PFA KundeKapital. For PFA KundeKapital kan du ikke vælge, hvordan din opsparing bliver investeret, og du er ikke sikret udbetaling af en vis størrelse.</p>
                                    <p>Du kan læse mere under fanen ”PFA KundeKapital”.</p>

                                    <h4 className="title">Se dit afkast</h4>
                                    <p>Er du kunde i Letpension, kan du se dit specifikke afkast ved at logge ind på Letportalen enten via ”Log ind” øverst på siden her eller via din netbank.</p>
                                    <p>Her kan du læse mere generelt om vores <a className="link-dark" target="_blank" href="/dokumenter/afkast">investeringsafkast</a>.</p>
							    </div>
</div>
        );
    }
}

