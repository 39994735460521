import {
    HENT_LETSIKRINGER,
    MODTAG_LETSIKRINGER,
    INVALIDER_LETSIKRINGER,
    HENT_LETSIKRING_AENDRINGSDATO,
    MODTAG_LETSIKRING_AENDRINGSDATO,
    HENT_LETSIKRING_DEPOTREGNSKAB,
    MODTAG_LETSIKRING_DEPOTREGNSKAB
    } from 'actions/letsikringer.jsx'


export default function letsikringer(
    state = {
        indlaeserListe: false,
        fejlmeddelelseListe: null,
        liste: null
    },
    action
)
{
    switch (action.type) {
    case HENT_LETSIKRINGER:
        return Object.assign({},
            state,
            {
                indlaeserListe: true,
                fejlmeddelelseListe: null
            });
    case MODTAG_LETSIKRINGER:
        var newState = {
            indlaeserListe: false,
            fejlmeddelelseListe: action.fejlmeddelelse,
            liste: action.liste
        };

        action.liste.forEach((ls) => {
            newState[`aendringsDato/${ls.letsikringNr}/${ls.aktuelAendringsDato}`] = ls;
        });

        return Object.assign({}, state, newState);
    case INVALIDER_LETSIKRINGER:
        return {
            indlaeserListe: false,
            fejlmeddelelseListe: null,
            liste: null
        };
    case HENT_LETSIKRING_AENDRINGSDATO:
        return Object.assign({},
            state,
            {
                [`indlaeserAendringsDato/${action.letsikringNr}/${action.aendringsDato}`]: true,
                [`fejlmeddelelseAendringsDato/${action.letsikringNr}/${action.aendringsDato}`]: null
            });
    case MODTAG_LETSIKRING_AENDRINGSDATO:
        return Object.assign({},
            state,
            {
                [`indlaeserAendringsDato/${action.letsikringNr}/${action.aendringsDato}`]: false,
                [`fejlmeddelelseAendringsDato/${action.letsikringNr}/${action.aendringsDato}`]: action.fejlmeddelelse,
                [`aendringsDato/${action.letsikringNr}/${action.aendringsDato}`]: action.letsikring
            });

    case HENT_LETSIKRING_DEPOTREGNSKAB:
        return Object.assign({},
            state,
            {
                [`indlaeserDepotregnskab/${action.letsikringNr}/${action.aarstal}`]: true,
                [`fejlmeddelelseDepotregnskab/${action.letsikringNr}/${action.aarstal}`]: null
            });
    case MODTAG_LETSIKRING_DEPOTREGNSKAB:
        return Object.assign({},
            state,
            {
                [`indlaeserDepotregnskab/${action.letsikringNr}/${action.aarstal}`]: false,
                [`fejlmeddelelseDepotregnskab/${action.letsikringNr}/${action.aarstal}`]: action.fejlmeddelelse,
                [`depotregnskab/${action.letsikringNr}/${action.aarstal}`]: action.depotregnskab
            });
    default:
        return state;
    }
}


