import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import SiteLink from './navigation/sitelink.jsx';

export default class Footer extends Component {
    render() {
        return (
            <div className="page-wrapper">
                <footer id="footer" className="clearfix ">

	                <div className="subfooter dark-bg">
		                <div className="container">
			                <div className="subfooter-innerX">
                  
				                 <div className="row">
					                <div className="col-md-12">
						                <p className="text-center">Neotech - Nordtoftevej 6, 2860 Søborg - CVR-nr 3025 6891</p>
					                </div>
				                 </div>

			                </div>
		                </div>
	                </div>
                </footer>
            </div>
);
    }
}
