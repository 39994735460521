export default function history(
    state = {
        history: []
    },
    action
) {
    return Object.assign({}, state, { history: state.history.concat([action]) });
}



