import { 
    HENT_KUNDE_INGENRELATIONER,
    GEM_KUNDE_INGENRELATIONER,
    MODTAG_KUNDE_INGENRELATIONER
    } from '../actions/kunderelationer.jsx'


export default function kunderelationer(
    state = {
        indlaeserIngenRelationer: false,
        gemmerIngenRelationer: false,
        ingenRelationer: null
    },
    action
)
{
    switch (action.type) {
    case HENT_KUNDE_INGENRELATIONER:
        return {
            indlaeserIngenRelationer: true
        }
    case GEM_KUNDE_INGENRELATIONER:
        return {
            gemmerIngenRelationer: true
        }
    case MODTAG_KUNDE_INGENRELATIONER:
        return {
            ingenRelationer: action.ingenRelationer,
            indlaeserIngenRelationer: false,
            gemmerIngenRelationer: false
        }
    default:
        return state;
    }
}





