import React from 'react';
import { Component } from 'react';

export default class ActivityIndicator extends Component {


    render() {
        return (
            <div className="activityContainer">
                <svg className="activitySVG" version="1.1" viewBox="0 0 177 125" width="45" height="32" preserveAspectRatio="none" id="harlekin">
                    <g id="harlekin-underlays">
                        <path id="harlekin-greenUnderlay" stroke="none" fill="rgb(184, 184, 184)" d="M 130.96,107.41 L 130.96,43.22 126.1,39.4 173.8,2.54 178.29,5.54 178.33,69.65 130.96,107.41 Z M 130.96,107.41"/>
                        <path id="harlekin-orangeUnderlay" stroke="none" fill="rgb(184, 184, 184)" d="M 117.64,120.9 L 117.64,127.5 50.2,123.4 2,83.5 2.25,76.3 69.5,81 117.64,120.9 Z M 117.64,120.9"/>
                        <path id="harlekin-blueUnderlay" stroke="none" fill="rgb(184, 184, 184)" d="M 120.45,115.5 L 73.35,76.05 73.35,11.6 78.2,8.4 125.7,47.5 125.85,111.7 120.45,115.5 Z M 120.45,115.5"/>
                    </g>
                    <g id="harlekin-foregrounds">
                        <path className="animate-pulse-3" id="harlekin-greenEdge" fill="rgb(181, 205, 128)" d="M 126.1,39.4 L 130.96,43.22 178.28,5.55 173.8,2.54 126.1,39.4 Z M 126.1,39.4"/>
                        <path className="animate-pulse-3" id="harlekin-green" fill="rgb(158, 180, 109)" d="M 130.97,107.33 L 178.33,69.65 178.29,5.54 130.93,43.22 130.97,107.33 Z M 130.97,107.33"/>
                        <path className="animate-pulse-1" id="harlekin-orangeEdge" fill="rgb(235, 151, 53)" d="M 50.58,115.68 L 50.18,123.39 117.65,127.45 117.58,120.85 50.58,115.68 Z M 50.58,115.68"/>
                        <path className="animate-pulse-1" id="harlekin-orange" fill="rgb(247, 203, 146)" d="M 69.48,80.97 L 117.62,120.9 50.61,115.7 50.19,123.42 2,83.44 2.27,76.31 2.24,76.26 69.48,80.97 Z M 69.48,80.97"/>
                        <path className="animate-pulse-2" id="harlekin-blueEdge" fill="rgb(21, 64, 87)" d="M 125.69,47.5 L 120.4,51.35 120.45,115.54 125.83,111.68 125.69,47.5 Z M 125.69,47.5"/>
                        <path className="animate-pulse-2" id="harlekin-blue" fill="rgb(115, 133, 150)" d="M 125.69,47.5 L 120.44,51.36 120.45,115.6 73.34,76.05 73.33,11.59 78.22,8.42 125.69,47.5 Z M 125.69,47.5"/>
                    </g>
                </svg>
                {this.renderBesked()}

            </div>
        );
    }
    renderBesked() {
        if (this.props.besked) {
            return <span className="activityLabel">{this.props.besked}...</span>;
        }else {
            return <span className="activityLabel">Henter oplysninger...</span>;
        }
    }
}



