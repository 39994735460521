
import ApiUtils from '../util/apiUtils.jsx'


export const HENT_KORRESPONDANCE = 'HENT_KORRESPONDANCE';
export const MODTAG_KORRESPONDANCE = 'MODTAG_KORRESPONDANCE';
export const INVALIDER_KORRESPONDANCE = 'INVALIDER_KORRESPONDANCE';


export function hentKorrespondance() {
  return {
    type: HENT_KORRESPONDANCE
  }
}

function modtagKorrespondance(state, json, fejl) {
//    console.log('Action modtagIndtjening: '  + fejl);
    return {
        type: MODTAG_KORRESPONDANCE,
        dokumenter: json,
        receivedAt: Date.now(),
        fejlmeddelelse: fejl
    }
}


export function indlaesKorrespondance() {

    return (dispatch, getState) => {

        dispatch(hentKorrespondance());
        let url = ApiUtils.apiRootUrl() + 'arkiv';

        console.log('Action indlaesKorrespondance ' + url);

        let token = ApiUtils.getAuthenticationToken();
        let header = ApiUtils.buildHeader(token);

        //let user = getState().user;

        //let header = ApiUtils.buildHeader(user.authenticationToken);
        //console.log('Action indlaesIndtjening state: ' + JSON.stringify(getState().indtjening))
        //console.log('Action indlaesIndtjening user pengeinstitut: ' + JSON.stringify(user.userinfo.pengeinstitut))

        return fetch(url,
                {
                    method: 'GET',
                    headers: header
                }
            )
            .then(ApiUtils.checkStatus)
            .then(response =>
                response.json()
                .then(json => {
                    //console.log('Action indlaesFilialer XX: ' + JSON.stringify(json))
                    dispatch(modtagKorrespondance(getState(), json), null);
                }),
                error => {
                    console.log('Action indlaesKorrespondance error.', error);
                    dispatch(modtagKorrespondance(getState(), null, 'Fejl ved indlæsning af korrespondance'));
                });
    }
}


