import { restIndlaes, restSlet, restOpdater, restTilfoej, getRestState, getRestStateListe, restOpdaterAction } from "../reducers/rest";
import ApiUtils from '../util/apiUtils.jsx'

export const HENT_KUNDE_INGENRELATIONER = 'HENT_KUNDE_INGENRELATIONER';
export const GEM_KUNDE_INGENRELATIONER = 'GEM_KUNDE_INGENRELATIONER';
export const MODTAG_KUNDE_INGENRELATIONER = 'MODTAG_KUNDE_INGENRELATIONER';

const KUNDERELATIONER = {
    path: "kunderelationer",
    id: "id"
};

export function getKundeRelationerState(state) {
    return Object.assign({}, getRestState(KUNDERELATIONER, state));
}

export function letforslagRelationSaetAnvendt(relation, anvendt) {
    return (dispatch, getState) => {
        return restOpdater(KUNDERELATIONER, Object.assign({}, relation, { anvendesILetForslag: anvendt }), dispatch);
    }
}

export function indlaesKundeRelationer() {
    return (dispatch, getState) => restIndlaes(KUNDERELATIONER)(dispatch, getState);
}

export function tilfoejKundeRelation(relation, dispatch) {
    return restTilfoej(KUNDERELATIONER, relation, dispatch);
}

export function opdaterKundeRelation(relation, dispatch) {
    return restOpdater(KUNDERELATIONER, relation, dispatch);
}

export function sletKundeRelation(relation, dispatch) {
    return restSlet(KUNDERELATIONER, relation, dispatch);
}

export function indlaesKundeIngenRelationer() {
    return (dispatch, getState) => {
        dispatch({ type: HENT_KUNDE_INGENRELATIONER });
        return ApiUtils.get('kunderelationer/ingenrelationer')
            .then(
                dto => {
                    dispatch({ type: MODTAG_KUNDE_INGENRELATIONER, ingenRelationer: dto.ingenRelationer });
                },
                error => {
                    console.log('Action indlaesKundeIngenRelationer error.', error);
                }
            );
    }
}

export function gemKundeIngenRelationer(ingenRelationer) {
    return (dispatch, getState) => {
        dispatch({ type: GEM_KUNDE_INGENRELATIONER });
        return ApiUtils.post('kunderelationer/ingenrelationer', { ingenRelationer })
            .then(
                dto => {
                    dispatch({ type: MODTAG_KUNDE_INGENRELATIONER, ingenRelationer: dto.ingenRelationer });
                },
                error => {
                    console.log('Action gemKundeIngenRelationer error.', error);
                }
            );
    }
}