
import {
  HENT_DOKUMENT,
  MODTAG_DOKUMENT,
} from '../actions/dokument.jsx'


export default function dokument(
        state = {
            indlaeser: false,
            fejlmeddelelse: null,
            dokument: null
        },
        action
    )
{
  switch (action.type) {
    case HENT_DOKUMENT:
      return Object.assign({}, state, {
        indlaeser: true,
        fejlmeddelelse: null,
        dokument: null,
      })
    case MODTAG_DOKUMENT:
      return Object.assign({}, state, {
        indlaeser: false,
        fejlmeddelelse: action.fejlmeddelelse,
        dokument: action.dokument
      })
    default:
      return state;
  }
}


