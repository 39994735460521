import { restIndlaes, restSlet, restOpdater, restTilfoej, getRestState, getDetaljerRestState, getRestStateListe } from "../reducers/rest";

const LETFORSLAG = {
    path: "letforslag",
    id: 'id'
};

export function getLetforslagState(state) {
    return getRestState(LETFORSLAG, state);
}

export function indlaesLetforslag() {
    return restIndlaes(LETFORSLAG);
}

export function opdaterDebiteringsKonto(aendringData, dispatch) {
    console.log('opdaterLetsikringDebitering: ' + JSON.stringify(aendringData));
    //return (dispatch, getState) => { return restOpdater(KONTOLISTE, aendringData, dispatch); }

    let OPDATERDEBITERINGKONTO = {
        path: LETFORSLAG.path + '/debiteringskonto',
        id: 'tilbudsOrdningId'
    }

    return restOpdater(OPDATERDEBITERINGKONTO, aendringData, dispatch);
}

export function danLetoverblik(dispatch) {

    const LETOVERBLIK = {
        path: LETFORSLAG.path,
        id: 'id',
        update: "detaljer"
    };

    return restOpdater(LETOVERBLIK, {id: 'letoverblik'}, dispatch);
    //return (dispatch, getState) => {
    //    return restOpdater(LETFORSLAG, {id: 'letoverblik'}, dispatch);
    //}   
}

export function getLetoverblikState(state) {
    return getDetaljerRestState(LETFORSLAG, 'letoverblik', state);
}

export function getBestilAendringState(state) {
    return getDetaljerRestState(LETFORSLAG, 'bestilaendring', state);
}

export function bestilAendring(dispatch) {

    let BESTILAENDRING = {
        path: LETFORSLAG.path,
        id: 'id',
        update: "detaljer"
    }

    return restOpdater(BESTILAENDRING, { id: 'bestilaendring' }, dispatch);
}

export function getGenindlaesDataState(state) {
    return getDetaljerRestState(LETFORSLAG, 'genindlaesdata', state);
}

export function genindlaesData(dispatch) {

    let GENINDLAESDATA = {
        path: LETFORSLAG.path,
        id: 'id',
        update: "detaljer"
    }

    return restOpdater(GENINDLAESDATA, { id: 'genindlaesdata' }, dispatch);
}

export function getKontaktAnmodningDataState(state) {
    return getDetaljerRestState(LETFORSLAG, 'kontaktanmodning', state);
}

export function kontaktAnmodning(dispatch) {

    let KONTAKTANMODNINGDATA = {
        path: LETFORSLAG.path,
        id: 'id',
        update: "detaljer"
    }

    return restOpdater(KONTAKTANMODNINGDATA, { id: 'kontaktanmodning' }, dispatch);
}


