import ApiUtils from '../util/apiUtils.jsx'

export const MODTAG_MEDDELSE = 'MODTAG_MEDDELSE';
export const FJERN_BESKED = 'FJERN_BESKED';
export const FRAVAELG_BESKED_NOTIFIKATION = 'FRAVAELG_BESKED_NOTIFIKATION';


export function modtagMeddelse(meddelse) {

    console.log('action modtagMeddelse: ' + JSON.stringify(meddelse)); 
    return {
        type: MODTAG_MEDDELSE,
        meddelse: meddelse
    };
}

export function fjernBesked(meddelse) {
    return {
        type: FJERN_BESKED,
        meddelse: meddelse
    };
}

export function fravaelgNotifikation(notifikationsType) {

    //return (dispatch, getState) => {
    //let url = ApiUtils.apiRootUrl() + 'notifikationer';

    //console.log('Action skjulNotifikation ' + url);

    //let token = ApiUtils.getAuthenticationToken();
    //let header = ApiUtils.buildHeader(token);

    ApiUtils.post('notifikationer/GaugeAdvarsel', {});
        //fetch(url,
        //        {
        //            method: 'PUT',
        //            headers: header,
        //            body: { "NotifikationType": notifikationsType }
        //        }
        //    )
        //    .then(ApiUtils.checkStatus);
        //.then(
        //        console.log('Action skjulNotifikation response.')
        //    ),
        //    error => {
        //        console.log('Action skjulNotifikation error.', error);
        //    };
    //}
}

