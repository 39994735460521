
import ApiUtils from '../util/apiUtils.jsx'


export const HENT_DOKUMENT = 'HENT_DOKUMENT'
export const MODTAG_DOKUMENT = 'MODTAG_DOKUMENT'


export function hentDokument() {
  return {
    type: HENT_DOKUMENT
  }
}

function modtagDokument(state, json, fejl) {
//    console.log('Action modtagForsikringsagenter: '  + fejl);
    return {
        type: MODTAG_DOKUMENT,
        dokument: json,
        receivedAt: Date.now(),
        fejlmeddelelse: fejl
    }
}


export function indlaesDokument() {

    return (dispatch, getState) => {

        //console.log('Action bestyrelse ')
        dispatch(hentDokument())
        let url = ApiUtils.apiRootUrl() + 'dokumenter';
        console.log('Action indlaesDokument ' + url)

        let headers = { 'Content-Type': 'application/pdf;base64'};

        return fetch(url,
        {
            method: 'GET',
            headers: {
              'Content-Type': 'application/pdf;base64'
            }
        },
        )
        .then(ApiUtils.checkStatus)
          .then(response =>
            response.json()
              .then(json => {
                console.log('Action dokument XX: ' + JSON.stringify(json))
                dispatch(modtagDokument(getState(), json), null)
              })
            ,error => {
                console.log('Action bestyrelse error.', error)
                dispatch(modtagDokument(getState(), null, 'Fejl ved indlæsning af dokument'))
            })
    }
}


