import React, { Component } from 'react';
import SiteLink from './sitelink.jsx';

export default class ImageLink extends Component {
    render() {
        return (
                <SiteLink url={this.props.link}>
                    <div className="lp-link-box shadow text-center mb-20">
                        <div className="overlay-container overlay-visible">
                            <div className="row grid-space-0">
                                <img src={this.props.billede} />
                                <div className="overlay-bottom hidden-xsX">
                                    <div className="text mt-10">
                                       <h3>{this.props.tekst}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SiteLink>
            );
    }
}

