
import {
  OPDATER_MINEVAERDIERWIZARD_STEP,
  OPDATER_MINEVAERDIERWIZARD_MODE,
} from 'actions/minevaerdierwizard.jsx'

export default function minevaerdierwizard(state = {
    step: 1,
    mode: 'Vis',
    visFejl: false,
    lastAction: null
}, action)
{
  switch (action.type) {
  case OPDATER_MINEVAERDIERWIZARD_STEP:
        return Object.assign({},
            state,
            {
                step: action.step,
                visFejl: action.visFejl,
                lastAction: action.lastAction
            });
  case OPDATER_MINEVAERDIERWIZARD_MODE:
        return Object.assign({},
            state,
            {
                mode: action.mode
            });
    default:
      return state;
  }
}
