

export const VAELG_MENU = 'VAELG_MENU'
export const NULSTIL_MENU = 'NULSTIL_MENU'

export function vaelgMenu(valgtMenu) {
  return {
    type: VAELG_MENU,
    valgtMenu: valgtMenu
  }
}

export function nulstilMenu(valgtMenu) {
  return {
    type: NULSTIL_MENU,
    valgtMenu: null
  }
}

