import {
    MODTAG_MEDDELSE,
    FJERN_BESKED,
    FRAVAELG_BESKED_NOTIFIKATION
    } from 'actions/meddelseNotifikationer.jsx'


export default function meddelseNotifikationer (
    state = {
        meddelser: []
    },
    action
)
{
    switch (action.type) {
    case MODTAG_MEDDELSE:
        state.meddelser.push(action.meddelse);
        return Object.assign({},
            state,
            {
                meddelser: state.meddelser
            });
    case FJERN_BESKED:
        var index = state.meddelser.indexOf(action.meddelse);
        if (index > -1) {
            state.meddelser.splice(index, 1);
        }
        return Object.assign({},
            state,
            {
                meddelser: state.meddelser
            });
    case FRAVAELG_BESKED_NOTIFIKATION:
        var index = state.meddelser.indexOf(action.meddelse);
        if (index > -1) {
            state.meddelser.splice(index, 1);
        }
        return Object.assign({},
            state,
            {
                meddelser: state.meddelser
            });
    default:
        return state;
    }
}


