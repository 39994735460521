export const Skaermbredder =
{
    lilleSkaermMaximumBredde: 767,
    storSkaermMinimumBredde: 992
};


export const SituationsType = {
    Pension: 'Pension',
    Doed: 'Doed',
    Sygdom: 'Sygdom',

    titel: function(situationsType) {
        switch (situationsType) {
            case SituationsType.Pension:
                return 'Pension';
            case SituationsType.Doed:
                return 'Død';
            case SituationsType.Sygdom:
                return 'Sygdom';
            default:
                throw `Ukendt situationsType: ${situationsType}`;
        }
    }
};

export const DaekningType = {
    LetsikringAfBarn: 'LetsikringAfBarn',
    LetsikringAfLaanOgKredit: 'LetsikringAfLaanOgKredit',
    LetsikringAfSygdom: 'LetsikringAfSygdom',
    LetsikringAfSygdom65: 'LetsikringAfSygdom65',
    LetsikringVedDoed: 'LetsikringVedDoed',
    LetsikringTabAfErhvervsevneBasis: 'LetsikringTabAfErhvervsevneBasis',
    LetsikringLivsforsikringBasis: 'LetsikringLivsforsikringBasis',
    LetsikringVedKritiskSygdom: 'LetsikringVedKritiskSygdom',
    LetsikringInvalidesumBasis: 'LetsikringInvalidesumBasis',
    LetsikringAfIndtaegt: 'LetsikringAfIndtaegt',
    LetsikringTabAfErhvervsevneBasis2360: 'LetsikringTabAfErhvervsevneBasis2360',
    LetsikringAfSygdomHelVedHalv: 'LetsikringAfSygdomHelVedHalv',
    LetsikringInvalidesumBasis60: 'LetsikringInvalidesumBasis60',
    LetsikringTabAfErhvervsevneBasis2362: 'LetsikringTabAfErhvervsevneBasis2362',
    LetsikringAfSygdomHelVedHalv62: 'LetsikringAfSygdomHelVedHalv62',
    LetsikringAfSygdomHelVedHalv60: 'LetsikringAfSygdomHelVedHalv60'
};

export const UdbetalingsType = {
    Engangsudbetaling: 'Engangsudbetaling',
    Ophoerende: 'Ophoerende',
    Livsvarig: 'Livsvarig'
};

export const BeskatningsType = {
    Afgiftspligtig: 'Afgiftspligtig',
    Skattepligtig: 'Skattepligtig',
    Skattefri: 'Skattefri'
};

export const SkatteHjaelpType =
{
    Fradrag: 'Fradrag',
    FradragDepot: 'FradragDepot',
    Beskatning: 'Beskatning'
};

export const Frekvens =
{
    FjortenDages: 'FjortenDages',
    Maanedlig: 'Maanedlig',
    KvartAarlig: 'KvartAarlig',
    HalvAarlig: 'HalvAarlig',
    Aarlig: 'Aarlig',

    aarligFrekvens: function(frekvens) {
        switch(frekvens) {
            case Frekvens.FjortenDages:
                return 26;
            case Frekvens.Maanedlig:
                return 12;
            case Frekvens.KvartAarlig:
                return 4;
            case Frekvens.HalvAarlig:
                return 2;
            case Frekvens.Aarlig:
                return 1;
            default:
                throw `Ukendt frekvens: ${frekvens}`;
        }
    },

    frekvensDivideret: function(frekvens, tal) {
        if (typeof tal !== 'number') {
            throw `tal er ikke et tal`;
        }

        if (tal === 0) {
            return 0;
        }
        return tal / Frekvens.aarligFrekvens(frekvens);
    },

    titel: function(frekvens) {
        switch(frekvens) {
        case Frekvens.FjortenDages:
            return 'Hver 14. dag';
        case Frekvens.Maanedlig:
            return 'Månedlig';
        case Frekvens.KvartAarlig:
            return 'Kvartårlig';
        case Frekvens.HalvAarlig:
            return 'Halvårlig';
        case Frekvens.Aarlig:
            return 'Årlig';
        default:
            throw `Ukendt frekvens: ${frekvens}`;
        }
    }
};

export const Tegningsmaade =
{
    Privat: 'Privat',
    Arbejdsgiver: 'Arbejdsgiver'
}

export const Koen =
{
    Mand: 'Mand',
    Kvinde: 'Kvinde',

    fraCprNummer(cprNummer) {
        return parseInt(cprNummer.slice(-1)) % 2 === 0 ? Koen.Kvinde : Koen.Mand;
    }
}

export const RelationsType =
{
    Aegtefaelle: 'Aegtefaelle',
    Barn: 'Barn',
    SamleversBarn: 'SamleversBarn',
    Samlever: 'Samlever',
    RegistreretPartner: 'RegistreretPartner',
    
    titel: function(relationsType) {
        switch(relationsType) {
        case RelationsType.Aegtefaelle:
            return 'Ægtefælle';
        case RelationsType.Barn:
            return 'Barn';
        case RelationsType.SamleversBarn:
            return 'Samlevers barn';
        case RelationsType.Samlever:
            return 'Samlever';
        case RelationsType.RegistreretPartner:
            return 'Registreret partner';
        default:
            return relationsType;
        }
    }
}

export const MineOplysningerType =
{
    MineVærdier: 'MineVærdier', 
    Helbred: 'Helbred', 
    Pensionsinfo: 'Pensionsinfo', 
    Gaeld: 'Gaeld', 
    Formue: 'Formue', 
    Loen: 'Loen', 
    Relationer: 'Relationer'
}

export const MineOplysningerStatus =
{
    Udfyldt: 'Udfyldt', 
    DelvistUdfyldt: 'DelvistUdfyldt', 
    SkalUdfyldes: 'SkalUdfyldes',
    KanUdfyldes: 'KanUdfyldes'
}
