import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import SiteLink from './navigation/sitelink.jsx';

export default class Fejl404 extends Component {
  componentDidMount() {
    document.title = 'Letpension';

    let noIndexMetaTag = document.createElement('meta');
    noIndexMetaTag.name = "robots";
    noIndexMetaTag.content = "noindex";

    document.getElementsByTagName('head')[0].appendChild(noIndexMetaTag);

  }
    render() {
        return (
		<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear ">
				<div className="container">
					<div className="row">
                        <div className="col-md-12">
						    <h1 className="title text-center">Øv - siden findes ikke</h1>
                            <div className="separator center"></div>   
						    <p className="text-center">Vi beklager, men vi kender ikke den side, du leder efter. Prøv i stedet at finde siden via menuen.</p>

                        </div>
					</div>
				</div>
            </section>

		</div>
      );
    }
}

