import React, { Component } from 'react';
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class LetsikringVedSygdom extends Component {
    render() {
        return (
            <div className="harlekin-baggrund">
			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Letsikring ved sygdom</h3>
                        </div>
                    </div>

                    <div className="row">
                        <video controls className="col-xs-12 col-sm-8 col-xs-offset-0 col-sm-offset-2">
                            <source src="/resources/videos/Letsikring_ved_sygdom.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="separator center"></div>
                            <p>Med en Letsikring ved sygdom kan du sikre dig selv, hvis du bliver syg eller kommer ud for en ulykke og dermed mister din indtægt.</p>
                            <p>En Letsikring ved sygdom sikrer dig penge, hvis du mister halvdelen af din arbejdsevne og indtægt i mere end tre måneder. Udbetalingen er uafhængig af myndighedernes vurdering af din sygdom.</p>
                            <p>Du vælger selv, hvor meget af din indtægt, du vil have dækket - dog højst 80 % af din bruttoindkomst ved personligt arbejde og maksimalt 358.500 kr. om året (2018).</p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>

			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-md-8">
										<h3 className="title">Hvis du vil vide mere </h3>
										<p>Vil du vide mere om, hvordan vi kan sikre dig et økonomisk råderum, hvis du bliver alvorligt syg, kan du kontakte din rådgiver i dit lokale pengeinstitut.</p>
									</div>
									<div className="col-md-4">
										<br/>
										<p><SiteLink url="/blivkunde" classname="btn btn-lg btn-gray-transparent btn-animated">Bliv kunde i Letpension<i className="fa fa-arrow-right pl-20"></i></SiteLink></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/produktet/letsikringvedkritisksygdom#top" billede="/Resources/images/Letpension/produkter/letsikringvedkritisksygdom.png" tekst="Letsikring ved kritisk sygdom" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-5">
                            <ImageLink link="/produktet/letsikringafindtaegtvedpension#top" billede="/Resources/images/Letpension/produkter/letsikringafindtaegtvedpension.png" tekst="Letsikring af indtægt ved pension" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/produktet/letsikringafbarnveddoed#top" billede="/Resources/images/Letpension/produkter/letsikringafbarnveddoed.png" tekst="Letsikring af barn" />
                        </div>
                        <div className="col-xs-12 col-sm-offset-0 col-sm-6 col-lg-5">
                            <ImageLink link="/produktet/letsikringveddoed#top" billede="/Resources/images/Letpension/produkter/letsikringveddoed.png" tekst="Letsikring ved død" />
                        </div>
                    </div>

                </div>
            </section>

            </div>
      );
    }
}

