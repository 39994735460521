import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux';
import {indlaesPengeinstitutter, getPengeinstitutterState } from '../actions/pengeinstitutter';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LpTable from "components/common/table";
import { Accordion, AccordionGroup } from "components/common/accordion";
import HenterOplysninger from '../navigation/henteroplysninger';
import withSizes from 'react-sizes';

function mapStateToProps(state) {

    let pengeinstitutterState = getPengeinstitutterState(state);
    let pengeinstitutterIndlaeser = false;
    let pengeinstitutterFejlMeddelelse = null;
    let pengeinstitutter = null;    
    if (pengeinstitutterState) {
        pengeinstitutter = pengeinstitutterState.liste;
        pengeinstitutterFejlMeddelelse = pengeinstitutterState.fejl;
        if (pengeinstitutterState.indlaeser) {
            pengeinstitutterIndlaeser = pengeinstitutterState.indlaeser;
        }
    }

    return {
        pengeinstitutter: pengeinstitutter,
        pengeinstitutterIndlaeser: pengeinstitutterIndlaeser,
        pengeinstitutterFejlMeddelelse: pengeinstitutterFejlMeddelelse
    };
    
}
function mapDispatchToProps (dispatch) {
    return {
        dispatchIndlaesPengeinstitutter: () => dispatch(indlaesPengeinstitutter())
    }
}


const mapSizesToProps = ({ width }) => ({
  lilleSkaerm: width < 480,
})

export class FindOsIListe extends Component {
  render() {
    //console.log('FindOsIListe render: ' + JSON.stringify(this.props));
      return (	  
		<div>			
			

			<section className="main-container">
				<div className="container">
					<div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Ydelser</h3>
                            <div className="separator center"></div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
						        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <br/>
                        </div>
					</div>

				</div>
			</section>
		</div>
      );
    }
}

let _FindOsIListe = connect(mapStateToProps, mapDispatchToProps)(FindOsIListe);
export default withSizes(mapSizesToProps)(_FindOsIListe);


