import React, { Component } from 'react';


export default class HenterOplysninger extends Component {
    render() {
        return (
            <div>
                <i className="fa fa-circle-o-notch fa-spin"/> Henter oplysninger...
            </div>
        )
    }
}

