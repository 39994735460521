var FormatUtils = {
  formaterBeloeb: function(beloeb, decimals) {
    let d = 2;
    if (decimals !== 'undefined' ) {
        d = decimals;
    }
    return beloeb.formatMoney(d, ',', '.');
  },
  afrund: function(beloeb, decimaler, perfaktor) {
    let f = 1;
    if (perfaktor) {
        f = perfaktor;
    }
    let decimalfactor = Math.pow(10, decimaler);
    let result = Math.round( beloeb / f * decimalfactor ) / decimalfactor;
    return result;
  }
};

Number.prototype.formatMoney = function(c, d, t){
var n = this,
    c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;
   return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
 };


export { FormatUtils as default };