import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

/*
   format = "General" example: 15-06-2008 21:15:07
   format = "ShortTime" example: 08:15
   format = "ShortDate" example: 15-06-2008
   format = "MaanedAar" example: 06-2008
   format = "Date" example:  9. juni 2009
   format = "DateTime" example:  9. juni 2009 kl. 08:15
   format = "ISO" example: 2008-06-15T21:15:07
*/

export function formatDate(dato, format) {

    if (typeof dato === 'string' || dato instanceof Date) {
        dato = moment(dato);
    }

    let str;

    switch (format) {
    case 'ShortTime':
        str = dato.format('HH:mm');
        break;
    case 'ShortDate':
        str = dato.format('DD-MM-YYYY');
        break;
    case 'MaanedAar':
        str = dato.format('MM-YYYY');
        break;
    case 'Date':
        str = dato.format('LL');
        break;
    case 'DateTime':
        str = `${dato.format('LL')} kl. ${dato.format('LT')}`;
        break;
    case 'ISO':
        str = dato.format('YYYY-MM-DDTHH:mm:ss');
        break;
    case 'General':
    default:
        str = dato.format('DD-MM-YYYY HH:mm:ss');
        break;
    }
    return str;
}

export function parseDate(dateStr, format) {
    switch (format) {
    case 'ShortDate': 
    {
        const parsed = dateStr.match(/^(\d{2})-(\d{2})-(\d{4})$/);
        if (parsed) {
            const [date, day, month, year] = parsed;
            let ret = new Date(year, month - 1, day);
            if (ret.getDate() == day && ret.getMonth() == month - 1 && ret.getFullYear() == year) {
                return ret;
            }
        }
        return null;
    }
    case 'ISO' :
    {
        const parsed = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/);
        if (parsed) {
            const [date, year, month, day, hours, minutes, seconds] = parsed;
            let ret = new Date(year, month-1, day, hours, minutes, seconds);
            if (ret.getDate() == day && ret.getMonth() == month - 1 && ret.getFullYear() == year) {
                return ret;
            }
        }
        return null;
    }
    }
    throw "Unsupported parse format ${format}";
}

export class LpDateTimeSpan extends Component {
    render() {

        var str = '';

        if (this.props.value) {
            let dato = moment(this.props.value);
            str = formatDate(dato, this.props.format);
        }

        return (
            <span className={this.props.className}>{str}</span>
        );
    }

}

LpDateTimeSpan.contextTypes = {
    value: PropTypes.string,
    format: PropTypes.oneOf(['General', 'ShortTime', 'ShortDate', 'MaanedAar', 'Date']),
    className: PropTypes.string
};


export function formatBeloeb(value, decimals, tilfoejValuta, tekstHvisIkkeTal) {
    let numeric = !isNaN(parseFloat(value)) && isFinite(value);
    if (!numeric && tekstHvisIkkeTal) {
        return tekstHvisIkkeTal;
    }
    let v = value ? value : 0;

    let c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals,
        d = ',',
        t = '.',
        s = v < 0 ? '-' : '',
        i = String(parseInt(v = Math.abs(Number(v) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;

    let str = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(v - i).toFixed(c).slice(2) : '');

    if (tilfoejValuta) {
        str = `${str} kr.`;
    }

    return str;
}

export class LpBeloebSpan extends Component {
    render() {
        let str = formatBeloeb(this.props.value, this.props.decimals, this.props.tilfoejValuta, this.props.tekstHvisIkkeTal);
        return (
            <span className={classnames(this.props.className, 'beloeb')}>{str}</span>
        );
    }
}

LpBeloebSpan.contextTypes = {
    value: PropTypes.number,
    decimals: PropTypes.number,
    tilfoejValuta: PropTypes.bool,
    className: PropTypes.string,
    tekstHvisIkkeTal: PropTypes.string
};
LpBeloebSpan.defaultProps = {
    tekstHvisIkkeTal: 'Ukendt'
};

export class LpProcentSpan extends Component {
    render() {
        let v = this.props.value ? this.props.value * 100 : 0;
        let d = this.props.decimals ? this.props.decimals : 2;

        var str = formatBeloeb(v, d, false);

        if (this.props.tilfoejProcent) {
            str = `${str} %`;
        }

        return (
            <span className={this.props.className}>{str}</span>
        );
    }
}

LpProcentSpan.contextTypes = {
    value: PropTypes.number,
    decimals: PropTypes.number,
    tilfoejProcent: PropTypes.bool,
    className: PropTypes.string
};

export function formatCprNummer(cprNummer) {
    return [cprNummer.slice(0, 6), '-', cprNummer.slice(6)].join('');
}

export function formatKonto(konto) {
    if (!konto) return 'ukendt';

    let result = `${konto.regNr} ${konto.kontoNr}`;

    if (konto.skyggesaldoTypeFormateret) {
        result = `${result}-${konto.skyggesaldoTypeFormateret}`;
    }

    if (konto.navn) {
        result = `${result} (${konto.navn})`;
    }
    return result;
}