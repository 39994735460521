import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class AendringerTilDinAftale extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Ændringer til din aftale</h3>
                            <div className="separator center"></div>
                            <p>Vi kan løbende justere din løsning, så den passer til det sted i livet, hvor du er lige nu. Måske er du blevet gift, har fået børn eller købt hus?</p>
                            <p>Ved at kontakte din rådgiver i dit lokale pengeinstitut, kan han sørge for, at din løsning bliver tilpasset de nye forhold. </p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>


			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-sm-8">
										<h3 className="title">Brug for at se din aftale med os?</h3>
										<p>Vil du vide mere om, hvordan din aftale med os er sat sammen, kan du altid finde oplysningerne ved at logge ind på din Letportal.</p>
									</div>
									<div className="col-sm-4">
										<br/>
										<p><a href="https://www.letportal.dk" className="btn btn-lg btn-gray-transparent btn-animated">Log ind på Letportalen<i className="fa fa-arrow-right pl-20"></i></a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/kontakt#top" billede="/Resources/images/Letpension/Brug_din_forsikring_456x300.jpg" tekst="Brug din forsikring" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>



</div>


      );
    }
}




