import { restIndlaes, restSlet, restOpdater, restTilfoej, getDetaljerRestState, getRestStateListe } from "../../reducers/rest";

const OMKOSTNINGER = {
    update: 'detaljer',
    path: "omkostninger",
    id: 'id'
};


export function getOmkostningerState(state) {
    return getDetaljerRestState(OMKOSTNINGER, 'omkostningerId', state);
}

export function beregnOmkostninger(omkostningData, dispatch) {
    return restOpdater(OMKOSTNINGER, omkostningData, dispatch);
}

