import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import {vaelgMenu, nulstilMenu } from './actions/navigation.jsx';
import SiteLink from './navigation/sitelink.jsx';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem, DropdownButton, ListGroup, ListGroupItem  } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { Skaermbredder } from "constants.jsx";
import { withRouter } from 'react-router';
import withSizes from 'react-sizes';

const mapSizesToProps = ({ width }) => ({
    lilleSkaerm: width <= Skaermbredder.lilleSkaermMaximumBredde,
    storSkaerm: width >= Skaermbredder.storSkaermMinimumBredde
});


const cookies = new Cookies();

function mapStateToProps(state) {
    return {
        navigation: state.navigation
    };
}
function mapDispatchToProps (dispatch) {
    return {
        dispatchVaelgMenu: (valgtMenu) => dispatch(vaelgMenu(valgtMenu)),
        dispatchNulstilMenu: () => dispatch(nulstilMenu()),
    }
}


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
			cookiesAccepted: false
        };
    }

    cookieUsageAccepted() {
        let neotechCookie = cookies.get('neotech');
        if (neotechCookie) {
            return true;
        }
        return false;
    }
    acceptCookieUsage() {
        let neotechCookie = cookies.get('neotech');        
        let options = {}
        if (!neotechCookie) {
            let acceptTime = new Date();
            let expireTime = new Date(acceptTime.getFullYear() + 1, acceptTime.getMonth(), acceptTime.getDay()); 
            //let expireTime = new Date();
            neotechCookie = [{cookiesAccepted: acceptTime}];
            console.log('setting cookie');
            cookies.set('neotech', neotechCookie, { path: '/', expires: expireTime});
        }
        
        this.setState({cookiesAccepted: true});
    }
    componentDidMount() {
        document.title = this.props.title;
        // Brug følgende hvis cookie skal slettes
        //cookies.remove('letpension');  
        this.state.cookiesAccepted = this.cookieUsageAccepted();
    }
    nultilMenu() {
        //console.log('vaelgMenupunkt');
        this.props.dispatchNulstilMenu();
        //this.setState({valgtMenuElement: valgtElement});
    }
    activeClass(valgtElement) {
        return this.isActive(valgtElement) ? "active" : "";
    }

    isActive(valgtElement) {
        let valgtMenu = this.props.location.pathname;
        let isActive = false;

        if (valgtElement === '/') {
            if (valgtMenu === valgtElement) {
                isActive = true;
            }
        } 
        else {
            if (valgtMenu && valgtMenu.startsWith(valgtElement)) {
                isActive = true;
            }   
        }
        return isActive;
    }

    focusRef(valgtElement, ref) {
        if (this.isActive(valgtElement)) {
            this.focusRefElement = ref;
        }
    }
    renderMainNav() {
        return (
            <div>
                <div className="hidden-xs">
                    <div className="LpNavBarTopSpacer"></div>
                </div>
                <Nav className="LpNavBar" navbar>

                    <NavItem componentClass={Link} href="/omneotech" to="/omneotech" className={"LpNavItem " + this.activeClass('/omneotech')} ref={(ref) => this.focusRef('/omneotech', ref)} >
                        Om Neotech
                    </NavItem>

                    <NavItem componentClass={Link} href="/kontakt" to="/kontakt" className={"LpNavItem " + this.activeClass('/kontakt')} ref={(ref) => this.focusRef('/kontakt', ref)} >
                        Kontakt
                    </NavItem>


                </Nav>
            </div>

        );
    }
     renderCookie() {
        return (                        
            !this.cookieUsageAccepted() &&

			<div className="announcement bg-info">
				<div className="container">
					<div className="margin-clear alert alert-dismissible" role="alert">
						<div className="row">
							<div className="col-sm-8">
								<p>Vi bruger cookies på hjemmesiden for at måle trafikken på siden og for at forbedre brugeroplevelsen. Ved at bruge vores hjemmeside accepterer du brugen af cookies til disse formål.
								</p>
							</div>
							<div className="col-sm-4 text-center">
								<a href="#" onClick={this.acceptCookieUsage.bind(this)} className="btn btn-dark">Ok</a>
							</div>
						</div>
					</div>
				</div>
			</div>

        );
    }
   render () {
        return (
            
            <div className="header-container">
                {this.renderCookie()}

				<div className="header-top dark ">
					<div className="container">
						<div className="row">
							<div className="col-xs-12">
								<div className="header-top-first clearfix">
									<ul className="social-links circle small clearfix hidden-xs">
										<li className="linkedin"><a target="_blank" href="http://www.linkedin.com"><i className="fa fa-linkedin"></i></a></li>
										<li className="facebook"><a target="_blank" href="http://www.facebook.com"><i className="fa fa-facebook"></i></a></li>
									</ul>
									<ul className="list-inline">
										<li><i className="fa fa-phone pr-5 pl-10"></i>+45 61 30 22 16</li>
										<li><i className="fa fa-envelope-o pr-5 pl-10"></i> kontakt@neotech.dk</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

                

                    <div className="">
                        <Navbar className="header margin--bottom-clear" inverse collapseOnSelect >
                            <Navbar.Header  className={this.props.lilleSkaerm ? "navBarHeaderCollapsed" : ""}>
                                <Navbar.Brand>
                                    <div id="logo" className="logo">
                                        <SiteLink url="/">Neotech</SiteLink>
                                    </div>
                                </Navbar.Brand>
                                <Navbar.Toggle/>
                            </Navbar.Header>
                            <Navbar.Collapse>
                                {this.renderMainNav()}
                            </Navbar.Collapse>
                        </Navbar>                            
                    </div>

            </div>

    )
    }
}


let _Header = connect(mapStateToProps, mapDispatchToProps)(Header);
export default withSizes(mapSizesToProps)(withRouter(_Header));

