import { restOpdater, getDetaljerRestState } from "../reducers/rest";

export const OPDATER_BEHOVSGUIDE_AKTIVTTRIN = 'OPDATER_BEHOVSGUIDE_AKTIVTTRIN';

export function opdaterAktivtTrin(aktivtGuideTrin, aktivtIndtastTrin) {
    return {
        type: OPDATER_BEHOVSGUIDE_AKTIVTTRIN,
        aktivtGuideTrin,
        aktivtIndtastTrin
    };
}

let LETFORSLAG = {
    path: "letforslag",
    id: 'id',
    update: "detaljer"
}


export function tilpasBehov(dispatch, behovsdata) {    
    return restOpdater(LETFORSLAG, behovsdata, dispatch);
}

export function getTilpasBehovState(state) {
    return getDetaljerRestState(LETFORSLAG, 'tilpasbehov', state);
}


