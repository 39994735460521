import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import ImageLink from '../navigation/imagelink.jsx';

import TjekDinPension from '../forkunder/tjekdinpension.jsx';
import AendringerTilDinAftale from '../forkunder/aendringerafdinaftale.jsx';
import MoedeForberedelse from '../forkunder/moedeforberedelse.jsx';
import Klage from '../forkunder/klage.jsx';
import Helbredsoplysninger from '../forkunder/helbredsoplysninger.jsx';
import Begunstigelse from '../forkunder/begunstigelse.jsx';
import DataBeskyttelse from '../forkunder/databeskyttelse.jsx';
import BehandlingAfPersonOplysninger from '../forkunder/behandlingafpersonoplysninger.jsx';
import Skatteregler from '../forkunder/skatteregler.jsx';

export default class ForKunder extends Component {
  render() {
      return (
	  <div>
          <Switch>
            <Route exact path='/forkunder' component={ForKunderOverblik}/>
            <Route path='/forkunder/moedeforberedelse' component={MoedeForberedelse}/>
            <Route path='/forkunder/tjekdinpension' component={TjekDinPension}/>
            <Route path='/forkunder/aendringertildinaftale' component={AendringerTilDinAftale}/>
            <Route path='/forkunder/moedeforberedelse' component={MoedeForberedelse}/>
            <Route path='/forkunder/klage' component={Klage}/>
            <Route path='/forkunder/helbredsoplysninger' component={Helbredsoplysninger}/>
            <Route path='/forkunder/begunstigelse' component={Begunstigelse}/>
            <Route path='/forkunder/videregivelseafkundeoplysninger' component={DataBeskyttelse}/>
             <Route path='/forkunder/behandlingafpersonoplysninger' component={BehandlingAfPersonOplysninger}/>
            <Route path='/forkunder/skatteregler' component={Skatteregler}/>
          </Switch>
		</div>

      );
    }
}


export class ForKunderOverblik extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Tal Letpension med din lokale rådgiver</h3>
                            <div className="separator center"></div>
                            <p>Er du kunde i et af de pengeinstitutter, der er med i Letpension-samarbejdet, er det din egen lokale rådgiver, der fortæller dig om de muligheder, Letpension tilbyder. På den måde er du sikker på at få en løsning, der er skabt sammen med en, der kender dig og din økonomi.</p>
                            <p>Skifter du pengeinstitut, kan du altid tage din Letsikring med dig, så længe du skifter til et af de andre pengeinstitutter i Letpension-samarbejdet.</p>
                            <p>Er du ikke kunde i et af de mange pengeinstitutter og vil du gerne vide mere om, hvad Letpension kan tilbyde, er du velkommen til at kontakte et pengeinstitut i nærheden af dig, der er med i samarbejdet.</p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>


			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-sm-8">
										<h3 className="title">Brug for at se din aftale med os?</h3>
										<p>Vil du vide mere om, hvordan din aftale med os er sat sammen, kan du altid finde oplysningerne ved at logge ind på din Letportal.</p>
									</div>
									<div className="col-sm-4">
										<br/>
										<p><a href="https://www.letportal.dk" className="btn btn-lg btn-gray-transparent btn-animated">Log ind på Letportalen<i className="fa fa-arrow-right pl-20"></i></a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/blivkunde#top" billede="/Resources/images/Letpension/Find_pengeinstitut_457x300.jpg" tekst="Bliv kunde i Letpension" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>



</div>


      );
    }
}








