import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import dokument from './dokument.jsx'
import bruger from './bruger.jsx'
import nemid from './nemid.jsx'
import arkiv from './arkiv.jsx'
import letforslag from './letforslag.jsx'
import kunderelationer from './kunderelationer.jsx';
import minevaerdierwizard from './minevaerdierwizard.jsx';
import infoModal from './infoModal.jsx';
import letsikringer from './letsikringer.jsx';
import behovsguide from './behovsguide.jsx'
import { reducer as restReducer } from "./rest";
import meddelseNotifikationer from './meddelseNotifikationer.jsx'
import { reducer as valuesReducer } from "./values";
import history from "./history";

import { MODTAG_BRUGER } from 'actions/bruger';

const rootReducer = combineReducers({
    form: formReducer,
    rest: restReducer,
    values: valuesReducer,
    bruger,
    nemid,
    dokument,
    arkiv,
    kunderelationer,
    letforslag,
    minevaerdierwizard,
    infoModal,
    letsikringer,
    behovsguide,
    meddelseNotifikationer,
    history
});

var nuvaerendeBruger = null;

export default function(state = {}, action) {
    if (action.type === MODTAG_BRUGER) {
        let bruger = action.bruger;
        if (bruger && bruger.loginInfo.loginStatus === 'LoggedIn') {
            let newState = state;
            if (nuvaerendeBruger && (nuvaerendeBruger.id !== bruger.id || nuvaerendeBruger.tilbudId != bruger.tilbudId)) {
                newState = {};
            }
            nuvaerendeBruger = bruger;
            return rootReducer(newState, action);
        }
    }
    return rootReducer(state, action);
};
