import { store } from "../reducers/store";
import { handleInvalidate } from "../reducers/rest";
import { modtagMeddelse } from "../actions/meddelseNotifikationer";
import { handleSetValue, setSignalrBrugerAction } from "../reducers/values";

export function signalRRegisterCommands(token) {

    let connection = $.connection.changeNotifier;

    connection.client.notifyChange = (notifications) => {
        console.log('NotifyChange');
        console.log(notifications);
        notifications &&
            notifications.forEach(notification => handleInvalidate(notification, store.dispatch, store.getState()));
    }

    connection.client.notifyPushMessage = (meddelse) => {
        console.log('PushMessage: ' + JSON.stringify(meddelse));
        store.dispatch(modtagMeddelse(meddelse));
    }

    connection.client.setValue = (key, value) => {
        console.log('SetValue ' + key + "=" + JSON.stringify(value));
        handleSetValue(key, value, store.dispatch);
    }

    if ($.connection.hub.qs !== token) {
        if ($.connection.hub.qs || !token) {
            console.log('Stopping signalR connection');
            $.connection.hub.stop();
        }
        $.connection.hub.qs = { 'token': token };
        if (token) {
            console.log('Starting signalR connection');
            //$.connection.hub.logging = true;
            $.connection.hub.start().done(() => {
                store.dispatch(setSignalrBrugerAction(store.getState().bruger.bruger));
            });
        }
    }
}
