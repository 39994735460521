import React, { Component } from 'react';
import {Side, Indhold, Titel} from 'publicweb/components/sidelayout'
import {KontaktLetpension} from 'components/letpension/kontakt'

export class KontaktSide extends Component {
    render() {
        return <Side>
                   <Indhold>
                       <KontaktLetpension/>
                   </Indhold>
               </Side>;
    }
}

