import {
    CPR_MATCH,
    CPR_MATCH_SLUT,
    CPR_MATCH_FEJL
    } from '../actions/nemid.jsx'

export default function nemid(
        state = {
            antalCprMatchForsoeg: 0
        },
        action
    )
{
  switch (action.type) {
    case CPR_MATCH:
        return Object.assign({},
            state,
            {
                indlaeser: true
            });    
    case CPR_MATCH_SLUT:
        return Object.assign({},
            state,
            {
                indlaeser: false,
                antalCprMatchForsoeg: 0
            });    
    case CPR_MATCH_FEJL:
        return Object.assign({},
            state,
            {
                indlaeser: false,
                antalCprMatchForsoeg: state.antalCprMatchForsoeg + 1
            });    
    default:
      return state;
  }
}


