import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';
import { Accordion, AccordionGroup } from "components/common/accordion.jsx";

export default class BehandlingAfPersonOplysninger extends Component {
    render() {
        return (
            <BehandlingAfPersonOplysningerIndhold/>
        );
    }
}


export class BehandlingAfPersonOplysningerIndhold extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Behandling af personoplysninger</h3><br/>
                            <i>Sidst opdateret: 7. maj 2018</i>

                            <div className="separator center"></div>
								<p>For at sikre dig og din opsparing bedst muligt, samarbejder Letpension med PFA Pension. Det betyder, at du som kunde får det bedste fra to verdener: individuel og personlig rådgivning i dit lokale pengeinstitut og en løsning, der bliver administreret af en af de største aktører på markedet. Det betyder også, at du som kunde i Letpension har forsikringer og pensioner i PFA Pension, og at PFA Pension har ansvaret for at beskytte og behandle dine personoplysninger. Her kan du læse mere om, hvordan PFA Pension behandler dine personoplysninger i forhold til dine forsikrings- og pensionsaftaler.</p>

								<p><strong>PFA PENSION ER DEN DATAANSVARLIGE</strong><br/>
								Den juridiske enhed, som er ansvarlig for behandlingen af dine personoplysninger i forhold til pensions- og forsikringsordninger, er:</p>

								<p><strong>PFA Pension, Forsikringsaktieselskab</strong><br/>
								Sundkrogsgade 4<br/>
								2100 København Ø<br/>
								CVR-nr. 13 59 43 76</p>

								<p><strong>1. FORMÅL MED BEHANDLING AF PERSONOPLYSNINGER</strong><br/>
								PFA Pension behandler dine personoplysninger til de forskellige formål, som er beskrevet nedenfor. Under hvert formål kan du se, hvilke kategorier af personoplysninger PFA Pension behandler, hvorfra PFA Pension indsamler  personoplysningerne, hvorfor de har lov til at behandle dine personoplysninger, og hvem PFA Pension kan videregive personoplysningerne til. Du får hele teksten frem ved at klikke på det/de formål, du ønsker at læse om. </p>
                        </div>
                    </div>
                </div>
            </section>


			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
                            <div>
                                <AccordionGroup id="gruppe">
                                    <Accordion titel="Tilbud om oprettelse af pensions- og forsikringsordninger" mountOnEnter={true}>
										<div className="panel-body">
                                            <p>Som kunde i Letpension har du samtidig dine forsikringer og pensioner i PFA Pension, og PFA Pension er dataansvarlig og dermed ansvarlig for behandlingen af dine personoplysninger.</p>
											<p>PFA Pension behandler i visse tilfælde personoplysninger om dig, når du ønsker at oprette en aftale i Letpension via dit pengeinstitut.</p>

                                            <fieldset className="white-bg">
                                                <legend>Kategorier af personoplysninger</legend>
                                                <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være: </p>
												<p>Almindelige kategorier af personoplysninger: <br/>
												Fødselsdato, køn, oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger).</p>
                                            </fieldset>
                                            <fieldset className="white-bg">
                                                <legend>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</legend>
                                                <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
                                                <p>Indsamling, anvendelse og videregivelse af almindelige personoplysninger om dig er nødvendig  for, at PFA Pension kan forfølge en legitim interesse, nemlig at oprette en ordning via Letpension som du har ønsket. (databeskyttelsesforordningen art. 6, stk. 1, litra f).</p>
                                            </fieldset>
                                           <fieldset className="white-bg">
                                                <legend>Hvor får PFA Pension personoplysninger om dig fra?</legend>
                                                <p>PFA Pension modtager dine personoplysninger fra dit pengeinstitut, som er dem, du har lavet aftalen gennem.</p>
                                            </fieldset>
                                            <fieldset className="white-bg">
                                                <legend>Videregivelse af dine personoplysninger</legend>
                                                <p>PFA Pension kan videregive dine personoplysninger til samarbejdspartnere, som assisterer dem med fx teknisk support og leverandørydelser. </p>
                                            </fieldset>
										</div>
                                    </Accordion>
                                    <Accordion titel="Oprettelse, individuel tilpasning og ændring af ordninger" mountOnEnter={true}>
										<div className="panel-body">
											<p>Som kunde i Letpension har du samtidig dine forsikringer og pensioner i PFA Pension, og PFA Pension er dataansvarlig og dermed ansvarlig for behandlingen af dine personoplysninger. </p>
											<p>Almindeligvis sker oprettelsen ved, at PFA Pension modtager en aftale med Letpension fra dit pengeinstitut. Ved oprettelsen og i forbindelse med senere individuelle tilpasninger og/eller ændring af din ordning behandler PFA Pension de nødvendige oplysninger om dig, som de skal bruge til at oprette og ændre din ordning via Letpension hos PFA Pension.</p>
                                            
										    <fieldset className="white-bg">
										        <legend>Kategorier af personoplysninger</legend>
										        <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være:</p>

											
										        <p>Almindelige kategorier af personoplysninger:<br/>
                                                Navn, CPR-nummer, oplysninger om dit engagement i Letpension (fx din indbetaling, risikoprofil, forsikringer og eventuelle andre produkter, oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger), familierelationer (ægtefælle/samlever/børn), civilstand fra CPR registeret (herunder eventuelt separations- og/eller skilsmissebevilling eller –dom).</p>
                                            

										        <p>Særlige kategorier af personoplysninger (følsomme personoplysninger):<br/>
                                                <i>Ved oprettelse</i>:<br/>
												Når du bliver oprettet som kunde i PFA Pension, vil der typisk ikke blive behandlet følsomme personoplysninger om dig.  
												</p>

										        <p><i>Ved tilpasninger og ændringer</i>:<br/>
													Ved individuelle tilpasninger som fx et ønske om forhøjelse af dækningers sum behandler PFA Pension helbredsoplysninger.:<br/> 
												Ved generelle ændringer af den ordning du er omfattet af, kan det være nødvendigt for os at behandle følsomme personoplysninger om dig, hvis der foreligger en risikostigning for PFA Pension . 
												</p>
                                            
										    </fieldset>

											<fieldset className="white-bg">
                                                <legend>Hvor får PFA Pension personoplysninger om dig fra?</legend>
                                                <p>Når PFA Pension ikke får personoplysningerne fra dig, kan vi få dem fra:</p>
												<ul className="list-icons">
													<li><i className="fa fa-caret-right pr-10"></i>CPR-registeret</li>
													<li><i className="fa fa-caret-right pr-10"></i>Pengeinstitut</li>
													<li><i className="fa fa-caret-right pr-10"></i>Tidligere pensions- og forsikringsselskab</li>
													<li><i className="fa fa-caret-right pr-10"></i>Sygehuse, praktiserende læge, øvrige læger og behandlingssteder, såsom speciallæger, kiropraktorer, fysioterapeuter, psykologer m. fl.</li>
													<li><i className="fa fa-caret-right pr-10"></i>Videncenter for Helbred og Forsikring</li>
												</ul>
											
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</legend>
                                                <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
												<ul className="list-icons">
													<li><i className="fa fa-caret-right pr-10"></i>Indsamling og anvendelse af almindelige personoplysninger om dig er nødvendig for, at PFA Pension kan opfylde  aftalen/pensions- og forsikringsordningen, de har med dig (databeskyttelsesforordningen art. 6, stk. 1, litra b).</li>
													<li><i className="fa fa-caret-right pr-10"></i>Dine almindelige personoplysninger, følsomme personoplysninger og CPR-nummer indsamles, anvendes og videregives på baggrund af et samtykke/udtrykkeligt samtykke fra dig (databeskyttelsesforordningen art. 6, stk. 1, litra a og databeskyttelsesforordningen art. 9, stk. 2, litra a samt databeskyttelsesloven § 11, stk. 2, nr. 2).</li>
												</ul>
											
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Videregivelse af dine personoplysninger</legend>
                                                <p>PFA Pension kan videregive dine personoplysninger til følgende <i>modtagere eller kategorier af modtagere</i>:</p>
																			                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Videncenter for Helbred og Forsikring, fx ved afslag på oprettelse</li>
								                <li><i className="fa fa-caret-right pr-10"></i>PFA Pension’s samarbejdspartnere, som assisterer vores virksomhed med fx teknisk support og leverandørydelser</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Datacentraler</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Dit pengeinstitut</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Letpension</li>
							                </ul>
											
                                            </fieldset>
										</div>
                                    </Accordion>
                                    <Accordion titel="Administration af ordninger" mountOnEnter={true}>
										<div className="panel-body">
											<p>PFA Pension behandler dine personoplysninger, når de administrerer din ordning hos PFA Pension. Det kan fx være: </p>

							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>når PFA Pension  kommunikerer med dig, eller du gør brug af personaliserede digitale løsninger fra PFA Pension</li>
								                <li><i className="fa fa-caret-right pr-10"></i>når PFA Pension administrerer indbetalinger og udbetalinger på din pensionsordning</li>
								                <li><i className="fa fa-caret-right pr-10"></i>når PFA Pension sender dig oplysninger om ændringer i din pensionsoversigt, dine vilkår og anden administrativ information</li>
							                </ul>
											
 
											<fieldset className="white-bg">
                                                <legend>Kategorier af personoplysninger</legend>
                                                <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil  være:</p>
												<p>Almindelige kategorier af personoplysninger: <br/>
												Navn, kontaktoplysninger, CPR-nummer, køn, kunde- eller policenummer/Letsikringsnummer, oplysninger om dit engagement i Letpension (fx din indbetaling, risikoprofil, forsikringer og eventuelle andre produkter), betalingsoplysninger, oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger), oplysninger om eventuel forsvinden. </p>

												<p>Særlige kategorier af personoplysninger (følsomme personoplysninger):<br/>
												Helbredsoplysninger.</p>
                                            </fieldset>
											<fieldset className="white-bg">
                                                <legend>Hvor får PFA Pension oplysninger om dig fra?</legend>
                                                <p>I administrationstilfælde bruger PFA Pension oplysninger, som de har i deres system eller i Letpensions system. Herudover vil vi ofte få supplerende personoplysninger fra dig.  Når du giver os dine oplysninger via Letpensions system, sker det via dit NemID-login, hvorved dataoverførslen sker via en sikker forbindelse til Letpension.</p>
											    <p>Når PFA Pension ikke får personoplysningerne fra dig, kan de få dem fra:</p>
												<ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Pengeinstituttet</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Tidligere pensions- og forsikringsselskab, fx hvis din pension ønskes overført fra et andet selskab til Letpension</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Pengeinstitutter, fx hvis din pension ønskes overført fra et andet selskab til Letpension</li>
								                <li><i className="fa fa-caret-right pr-10"></i>CPR-registeret ved adresseændring eller ændring af civilstand</li>
							                </ul>
											
                                            </fieldset>
											<fieldset className="white-bg">
                                                <legend>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</legend>
                                                <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af almindelige personoplysninger om dig er nødvendig for, at PFA Pension kan opfylde  aftalen/pensions- og forsikringsordningen, PFA Pension har med dig (databeskyttelsesforordningen art. 6, stk. 1, litra b).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Behandling af følsomme personoplysninger er nødvendig for, at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesforordningen art. 9, stk. 2, litra f).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af CPR-nummer, de følsomme personoplysninger samt de almindelige personoplysninger er nødvendig, da PFA Pension er retligt forpligtet til efter hvidvaskloven at legitimere og risikovurdere kunder samt underrette Hvidvasksekretariatet (Statsadvokaten for Særlig Økonomisk og International Kriminalitet) om mistanke med henblik på at forebygge og bekæmpe hvidvask og terrorfinansiering. PFA Pension indhenter personoplysninger om dig i forbindelse med kundekendskabsprocedurer og som følge af undersøgelse, notering og overvågning af pensionsordningen (databeskyttelsesloven § 11, stk. 2, nr. 1 samt databeskyttelsesforordningen art. 9, stk. 2, litra g og databeskyttelsesforordningen art. 6, stk. 1, litra c).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af dit CPR-nummer samt de almindelige personoplysninger, er nødvendig, da PFA Pension er retligt forpligtet til at indberette til SKAT efter skattekontrolloven. Herudover har PFA Pension pligt til at tilbageholde skat ved udbetaling af pensionsopsparing til dig. I den forbindelse videregiver PFA Pension dine personoplysninger efter pensionsbeskatningsloven. CPR-nummer videregives med henblik på identifikation af kunden ved indberetning af økonomiske oplysninger og udbetaling af skat (databeskyttelsesloven § 11, stk. 2, nr. 1 samt databeskyttelsesforordningen art. 6, stk. 1, litra c).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Videregivelse af dine almindelige personoplysninger, herunder dit CPR-nummer, til banker og andre pengeinstitutter er nødvendig for, at PFA Pension kan opfylde  aftalen/pensions- og forsikringsordningen, vi har med dig (databeskyttelsesforordningen art. 6, stk. 1, litra b samt databeskyttelsesloven § 11, stk. 2, nr. 3).</li>
							                </ul>
											
                                            </fieldset>
											<fieldset className="white-bg">
                                                <legend>Videregivelse af dine personoplysninger</legend>
                                                <p>PFA Pension kan videregive dine personoplysninger til følgende <i>modtagere eller kategorier af modtagere</i>:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Letpension</li>
								                <li><i className="fa fa-caret-right pr-10"></i>PFA Pension’s samarbejdspartnere, som assisterer virksomheden med fx teknisk support og leverandørydelser</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Pengeinstitutter</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Offentlige myndigheder fx SKAT i forbindelse med lovpligtige indberetninger</li>
							                </ul>
											
                                            </fieldset>
										</div>
                                    </Accordion>
                                    <Accordion titel="Skadesbehandling og udbetaling af forsikring" mountOnEnter={true}>
										<div className="panel-body">
											<p>PFA Pension behandler dine personoplysninger, når de vurderer, om de kan udbetale forsikring i forbindelse med skade.</p>
 
											<fieldset className="white-bg">
                                                <legend>Kategorier af personoplysninger</legend>
                                                <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil  være:</p>

												<p>Almindelige kategorier af personoplysninger: <br/>
												Navn, kontaktoplysninger, CPR-nummer, oplysninger om praktiserende læge, oplysninger om behandlingssted, oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger), betalingsoplysninger og oplysninger om offentlige ydelser. </p>

												<p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br/>
												Helbredsoplysninger. </p>
												
                                            </fieldset>
											
											<fieldset className="white-bg">
                                                <legend>Hvor får PFA Pension oplysninger om dig fra? </legend>
                                                <p>Du er den primære kilde til personoplysninger om dig selv, og PFA Pension vil normalt indhente dine personoplysninger direkte fra dig. </p>
											    <p>Når PFA Pension ikke får personoplysningerne fra dig, kan de få dem fra: </p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Tidligere pensions- og forsikringsselskab (hvis din pension eller forsikring er overført fra et andet selskab).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Sygehuse, praktiserende læge, øvrige læger og behandlingssteder, såsom speciallæger, kiropraktorer, fysioterapeuter m. fl.</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Offentlige myndigheder.</li>
							                </ul>
											
                                            </fieldset>
											
											<fieldset className="white-bg">
                                                <legend>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</legend>
                                                <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af almindelige personoplysninger om dig er nødvendig for at opfylde aftalen/pensions- og forsikringsordningen vi har med dig (databeskyttelsesforordningen art. 6, stk. 1, litra b).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling og anvendelsen af helbredsoplysninger er nødvendig, for at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesforordningens art. 9, stk. 2, litra f).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af CPR-nummer er nødvendig, for at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 11, stk. 2, nr. 4 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens art. 9, stk. 2, litra f).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Videregivelse af almindelige personoplysninger og følsomme personoplysninger sker i andre tilfælde med dit samtykke/udtrykkelige samtykke (databeskyttelsesforordningen art. 6, stk. 1, litra a og databeskyttelsesforordningen art. 9, stk. 2, litra a) eller på baggrund af lov, herunder kan PFA Pension videregive til Patienterstatningen på baggrund af lov om klage og erstatningsadgang inden for sundhedsvæsenet (databeskyttelsesforordningen art. 6, stk. 1, litra c, databeskyttelsesforordningen art. 9, stk. 2, litra g og lov om klage og erstatningsadgang inden for sundhedsvæsenet).</li>
							                </ul>
											
                                            </fieldset>
											
											<fieldset className="white-bg">
                                                <legend>Videregivelse af dine personoplysninger</legend>
                                                <p>PFA Pension kan videregive dine personoplysninger til følgende <i>modtagere eller kategorier af modtagere</i>:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>PFA Pension’s samarbejdspartnere, som assisterer virksomheden med fx teknisk support, leverandørydelser og formidling af sundhedsydelser.</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Videncenter for Helbred og Forsikring. PFA Pension videregiver i særlige tilfælde oplysninger for at kunne få en udtalelse med henblik på, at PFA Pension kan træffe en afgørelse.</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Offentlige myndigheder, fx Patienterstatningen. PFA Pension videregiver oplysninger på baggrund af lov om klage og erstatningsadgang inden for sundhedsvæsenet.</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Sygeforsikring Danmark. Hvis kunden er medlem af Sygeforsikring Danmark, videregiver PFA Pension personoplysninger hertil i forbindelse med mulig refusion.</li>
							                </ul>
											
                                            </fieldset>
										</div>
                                    </Accordion>
                                    <Accordion titel="Når din ordning ophører" mountOnEnter={true}>
										<div className="panel-body">
											<p>PFA Pension behandler dine personoplysninger, når din ordning ophører. Det kan være hvis du vælger at opsige en aftale om pension eller forsikring.</p>
 
											<fieldset className="white-bg">
                                                <legend>Kategorier af personoplysninger</legend>
                                                <p>PFA Pension behandler forskellige personoplysninger om dig, som PFA Pension allerede har registreret. Disse personoplysninger vil  være: </p>

												<p>Almindelige kategorier af personoplysninger: <br/>
												Navn, kontaktoplysninger, CPR-nummer, kunde- og policenummer/Letsikringsnummer  </p>

												<p>Særlige kategorier af personoplysninger (følsomme personoplysninger):  <br/>
												Helbredsoplysninger </p>
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</legend>
                                                <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af almindelige personoplysninger om dig er nødvendig af hensyn til opfyldelse af aftalen/administrere pensions- og forsikringsordningen (databeskyttelsesforordningen art. 6, stk. 1, litra b). </li>
								                <li><i className="fa fa-caret-right pr-10"></i>Anvendelse og videregivelse af dine følsomme personoplysninger er nødvendig for, at retskrav kan fastsættes, gøres gældende eller forsvares (databeskyttelsesforordningen art. 9, stk. 2, litra f).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Videregivelse af dine almindelige personoplysninger og dit CPR-nummer sker i andre tilfælde på baggrund af dit samtykke (databeskyttelsesloven § 11, stk. 2, nr. 2 samt databeskyttelsesforordningen art. 6, stk. 1, litra a).</li>
							                </ul>
											
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Videregivelse af dine personoplysninger</legend>
                                                <p>PFA Pension kan videregive dine personoplysninger til følgende <i>kategorier af modtagere</i>:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Dit nye pensions- og forsikringsselskab</li>
								                <li><i className="fa fa-caret-right pr-10"></i>PFA Pension’s samarbejdspartnere, som assisterer virksomheden med fx teknisk support og leverandørydelser</li>
							                </ul>
											
                                            </fieldset>
										</div>
                                    </Accordion>
                                    <Accordion titel="Klagebehandling" mountOnEnter={true}>
										<div className="panel-body">
											<p>Som kunde i Letpension har du samtidig dine forsikringer og pensioner i PFA Pension, og PFA Pension er dermed dataansvarlig og ansvarlig for behandlingen af dine personoplysninger.</p>
										    <p>PFA Pension har et ansvar for at sikre dig en fair behandling, herunder at du får de udbetalinger, du er berettiget til, hverken mere eller mindre. Det kan en gang imellem give anledning til klager. </p>

											<p>PFA Pension anvender fx dine personoplysninger til at behandle en eventuel klage fra dig over en afgørelse, der er truffet af PFA Pension. Det kan også være, at du ikke er tilfreds med svaret i klagebehandlingen og vælger at klage over PFA Pension til  Ankenævnet for Forsikring eller at anlægge sag mod PFA Pension ved domstolene. I disse tilfælde vil PFA Pension også anvende dine personoplysninger.</p>

 
											<fieldset className="white-bg">
                                                <legend>Kategorier af personoplysninger</legend>
                                                <p>PFA Pension behandler forskellige personoplysninger om dig. Disse personoplysninger vil være:</p>
												<p>Almindelige kategorier af personoplysninger: 
												Navn, kontaktoplysninger, CPR-nummer, kunde- eller policenummer/Letsikringsnummer, oplysninger om dit engagement i PFA Pension (fx din indbetaling, risikoprofil, forsikringer og eventuelle andre produkter), begunstigelse, skatteoplysninger, fritidsaktiviteter, formueforhold, oplysninger om dit ansættelsesforhold (fx arbejdsgiver og, lønoplysninger), erhvervssituation, familierelationer (ægtefælle/samlever/børn), civiltilstand og oplysninger om sociale problemer (der ikke er relateret til helbred).</p>

												<p>Særlige kategorier af personoplysninger (følsomme personoplysninger): <br/>
												Helbredsoplysninger.</p>

												<p>Personoplysninger vedrørende straffedomme og lovovertrædelser: <br/>
												Oplysninger om strafbare forhold (databeskyttelsesloven § 8, stk. 5 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens art. 9, stk. 2, litra f).</p>
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Hvor får PFA Pension oplysninger om dig fra?</legend>
                                                <p>Når PFA Pension ikke får personoplysningerne fra dig, kan de få dem fra: </p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Pengeinstitut</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Repræsentanter, såsom advokat</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Tidligere eller andet pensions- og forsikringsselskab, fx hvis din pension eller forsikring er overført fra et andet selskab</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Offentlige myndigheder (fx kommunale dokumenter ved invalidepensionssager) </li>
								                <li><i className="fa fa-caret-right pr-10"></i>Offentlige myndigheder i forbindelse med sager, fx ved Datatilsynet eller Finanstilsynet)</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Sygehuse, praktiserende læge, øvrige læger og behandlingssteder, såsom speciallæger, kiropraktorer, fysioterapeuter, psykologer m. fl.</li>
							                </ul>
											
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</legend>
                                                <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>De almindelige personoplysninger indsamles, anvendes og videregives ud fra en interesseafvejning i forhold til, at retskrav skal kunne fastlægges, gøres gældende og forsvares af PFA Pension, hvis en kunde klager over en afgørelse truffet af PFA Pension (databeskyttelsesforordningen art. 6, stk, 1, litra f). </li>
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling og anvendelse af almindelige og følsomme personoplysninger er nødvendig for at overholde en retlig forpligtelse (databeskyttelsesforordningen art. 6, stk. 1, litra c og databeskyttelsesforordningen art. 9, stk. 2, litra g). PFA Pension har en retlig forpligtelse efter bekendtgørelse om den klageansvarlige og finansielle virksomheders håndtering af klager. </li>
								                <li><i className="fa fa-caret-right pr-10"></i>De følsomme personoplysninger indsamles, anvendes og videregives, hvis de er nødvendige for, at retskrav kan fastlægges, gøres gældende eller forsvares af PFA Pension (databeskyttelsesforordningen art. 9, stk. 2, litra f).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af CPR-nummer er nødvendig, for at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 11, stk. 2, nr. 4 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningens art. 9, stk. 2, litra f).</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af oplysninger om strafbare forhold  er nødvendig, for at retskrav kan fastlægges, gøres gældende eller forsvares (databeskyttelsesloven § 8, stk. 5 og databeskyttelsesloven § 7, stk. 1 samt databeskyttelsesforordningen art. 9 stk. 2, litra f).</li>
							                </ul>
											
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Videregivelse af dine personoplysninger</legend>
                                                <p>PFA Pension kan videregive dine personoplysninger til følgende <i>kategorier af modtagere</i>:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Repræsentanter, såsom advokat</li>
								                <li><i className="fa fa-caret-right pr-10"></i>PFA Pension’s samarbejdspartnere, som assisterer virksomheden med fx teknisk support og leverandørydelser </li>
								                <li><i className="fa fa-caret-right pr-10"></i>Offentlige myndigheder i forbindelse med myndighedssager, fx Datatilsynet eller Finanstilsynet</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Pengeinstitut i forbindelse med udbetaling til dig eller afklaring af udbetaling</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Domstole og ankenævn</li>
							                </ul>
											
                                            </fieldset>
    									</div>
                                    </Accordion>
                                    <Accordion titel="Analyse og statistik" mountOnEnter={true}>
										<div className="panel-body">
											<p>PFA Pension behandler dine personoplysninger, når de udarbejder statistikker og analyser. Det kan være:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Produktundersøgelser, analyser og lønsomhedsvurderinger</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Solvensopgørelser og lovpligtige solvensrapporteringer</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Aktuarberetning</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Statistiske undersøgelser af væsentlig samfundsmæssig betydning vedrørende årsager til sygdom, sygdomsmønstre og sammenhæng til dødelighed </li>
							                </ul>
											
 
											<fieldset className="white-bg">
                                                <legend>Kategorier af personoplysninger</legend>
                                                <p>I forbindelse med at PFA Pension udarbejder statistikker og analyser, behandler PFA Pension forskellige personoplysninger om dig (oftest vil oplysningerne dog anvendes i anonymiseret form). Personoplysningerne vil være:</p>

												<p>Almindelige kategorier af personoplysninger: <br/>
												Navn, kontaktoplysninger, CPR-nummer, køn, kunde- eller policenummer, begunstigelse, oplysninger om dit engagement i PFA Pension (fx din indbetaling, risikoprofil, forsikringer og eventuelle andre produkter), oplysninger om dit ansættelsesforhold (fx arbejdsgiver og lønoplysninger), pensionsindbetaling, skatteoplysninger, formueforhold, depotstørrelse, familierelationer (ægtefælle/samlever/børn), oplysninger om dødsfald og oplysninger om forsvinden.</p>

												<p>Særlige kategorier af personoplysninger (følsomme personoplysninger):  <br/>
												Helbredsoplysninger. </p>
                                            </fieldset>
 
											<fieldset className="white-bg">
                                                <legend>Hvorfor har PFA Pension lov til at behandle personoplysninger om dig?</legend>
                                                <p>PFA Pension indsamler og anvender dine personoplysninger ud fra følgende juridiske grundlag:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Behandlingen af almindelige personoplysninger er nødvendig for at overholde en retlig forpligtelse, som påhviler PFA Pension (databeskyttelsesforordningen art. 6, stk.1, litra c). Den retlige forpligtelse findes i lov om Danmarks Nationalbank (”statistiske oplysninger inden for dens kompetenceområde”) og i lov om finansiel virksomhed (”de oplysninger der er nødvendige for Finanstilsynets virksomhed”). </li>
								                <li><i className="fa fa-caret-right pr-10"></i>PFA Pension udarbejder lovpligtige rapporteringer på en række områder, herunder solvensopgørelser og lovpligtige analyser. PFA Pension behandler følsomme personoplysninger, hvis behandlingen er nødvendig af hensyn til væsentlige samfundsinteresser (databeskyttelsesforordningen art. 9, stk. 2, litra g samt databeskyttelsesloven § 10, stk. 1). Det følger blandt andet af ovenstående, at PFA Pension er pålagt at foretage beregningerne og analyserne.</li>
								                <li><i className="fa fa-caret-right pr-10"></i>PFA Pension udarbejder analyser af sammenkørte oplysninger om skader, sygdom, sygdomsmønstre og sammenhæng til dødelighed. Analyserne foretages  med det formål at mindske risikoen for sygdom og død samt at opnå generel viden om effekten af forebyggende indsatser til gavn for samfundet. (databeskyttelsesloven § 10, stk. 1).</li>
											</ul>
											
                                            </fieldset>
 
											<fieldset className="white-bg">
                                                <legend>Videregivelse af dine personoplysninger</legend>
                                                <p>I forbindelse med analyse og statistik bliver alle personoplysninger anonymiseret og sammenholdt for at udlede tendenser og trends, og der bliver ikke videregivet personoplysninger til andre modtagere.</p>
                                            </fieldset>
										</div>
                                    </Accordion>
                                    <Accordion titel="Overholdelse af persondataretlig regulering" mountOnEnter={true}>
                                        <div className="panel-body">
                                            <p>PFA Pension behandler dine personoplysninger ved lovpligtige indberetninger, og når de skal sikre overholdelse af gældende lovgivning, fx om hvidvask, persondata og finansiel virksomhed.
                                                PFA Pension indsamler, anvender og videregiver dine oplysninger i forbindelse med overholdelse af databeskyttelsesforordningen, databeskyttelsesloven og anden relevant lovgivning, fx lov om finansiel virksomhed. Det kan fx være:</p>
                                            <ul className="list-icons">
                                                <li><i className="fa fa-caret-right pr-10"></i>Dokumentationspligt</li>
                                                <li><i className="fa fa-caret-right pr-10"></i>Lovpligtige indberetninger til myndighederne</li>
                                                <li><i className="fa fa-caret-right pr-10"></i>Overholdelse af principper for behandling af personoplysninger og det juridiske grundlag for behandlingen</li>
                                                <li><i className="fa fa-caret-right pr-10"></i>Beskyttelse af personoplysninger ved iværksættelse og vedligeholdelse af tekniske og organisatoriske sikkerhedsforanstaltninger, fx for at hindre uautoriseret adgang til PFA Pension’s IT-systemer</li>
                                                <li><i className="fa fa-caret-right pr-10"></i>Undersøgelse af mistanke eller viden om sikkerhedsbrud og derefter rapportering til kunder eller andre berørte og til Datatilsynet</li>
                                                <li><i className="fa fa-caret-right pr-10"></i>Håndtering af forespørgsler og klager fra kunder og andre</li>
                                                <li><i className="fa fa-caret-right pr-10"></i>Håndtering af inspektioner og forespørgsler fra Datatilsynet og Finanstilsynet</li>
                                                <li><i className="fa fa-caret-right pr-10"></i>Håndtering af tvister fx ankenævnssager og retssager </li>
                                                <li><i className="fa fa-caret-right pr-10"></i>Statistik</li>
                                            </ul>
											
                                        </div>
                                    </Accordion>
                                    <Accordion titel="Ydelser og udbetaling til ægtefæller, samlever og børn" mountOnEnter={true}>
										<div className="panel-body">
										    <p>Hvis du er ægtefælle/samlever, tidligere ægtefælle, begunstiget eller nærmeste pårørende til en pensions- eller forsikringskunde hos PFA Pension, anvender de dine personoplysninger til bl.a. administration af udbetaling til dig ved dødsfald eller ved deling af pensionsordning ved fx skilsmisse.</p>
 
											<fieldset className="white-bg">
                                                <legend>Kategorier af personoplysninger</legend>
                                                <p>PFA Pension anvender forskellige personoplysninger om dig. Disse personoplysninger vil  være:</p>

												 <p>Almindelige kategorier af personoplysninger: 
												Navn, kontaktoplysninger, CPR-nummer, legitimationsoplysninger (fx pas, hvis du ikke kan legitimeres via CPR-registeret), betalingsoplysninger, familierelationer (ægtefælle/samlever/børn), lønoplysninger, vielsesdato og civilstand (herunder eventuelt separations- og/eller skilsmissebevilling eller –dom).</p>

                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Hvor får PFA Pension oplysninger om dig fra?</legend>
                                                <p> Når PFA Pension ikke får personoplysningerne fra dig, kan de få dem fra: </p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Offentlige myndigheder (f.eks. SKAT, skifteretten)</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Boet efter afdøde </li>
								                <li><i className="fa fa-caret-right pr-10"></i>Nets Denmark A/S (oplysninger om NEM-konto til brug for udbetaling)</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Pengeinsitutter</li>
							                </ul>
											
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Hvorfor har PFA Pension lov til at behandle oplysninger om dig?</legend>
                                                <p>PFA Pension indsamler, anvender og videregiver dine personoplysninger ud fra følgende juridiske grundlag:</p>
							                <ul className="list-icons">
							                    <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af almindelige personoplysninger er nødvendig for at overholde en retlig forpligtelse (databeskyttelsesforordningens art. 6, stk. 1, litra c). PFA Pension har en retlig forpligtelse efter skattekontrolloven til at tilbageholde skat ved udbetaling af pensionsopsparing til dig. PFA Pension videregiver dine personoplysninger til SKAT efter pensionsbeskatningsloven.</li>
								                <li><i className="fa fa-caret-right pr-10"></i>Indsamling, anvendelse og videregivelse af almindelige personoplysninger er nødvendig for at overholde en retlig forpligtelse (databeskyttelsesforordningen art. 6, stk. 1, litra c). PFA Pension er efter hvidvaskloven retligt forpligtet til at legitimere og risikovurdere dig samt underrette Hvidvask sekretariatet (Statsadvokaten for Særlig Økonomisk og International Kriminalitet) om mistanke med henblik på at forebygge og bekæmpe hvidvask. </li>
								                <li><i className="fa fa-caret-right pr-10"></i> CPR-nummer videregives for at kunne identificere dig ved indberetning af økonomiske oplysninger og udbetaling af skat (databeskyttelsesloven § 11, stk. 2, nr. 1). </li>
							                </ul>
											
                                            </fieldset>

											<fieldset className="white-bg">
                                                <legend>Videregivelse af dine personoplysninger</legend>
                                                <p>PFA Pension kan videregive dine personoplysninger til følgende kategorier af modtagere:</p>
							                <ul className="list-icons">
								                <li><i className="fa fa-caret-right pr-10"></i>Offentlige myndigheder, fx SKAT i forbindelse med tilbageholdelse af boafgift og skat.</li>
								                <li><i className="fa fa-caret-right pr-10"></i>PFA Pension’s samarbejdspartnere, som assisterer virksomheden med fx teknisk support og leverandørydelser.</li>
											</ul>
                                            </fieldset>
										</div>
                                    </Accordion>
                                </AccordionGroup>
                            </div>

						</div>
					</div>
				</div>
			</section>
			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
						<p><strong>2. OVERFØRSEL AF PERSONOPLYSNINGER TIL LANDE UDEN FOR EU/EØS </strong><br/>
PFA Pension overfører ikke dine personoplysninger til lande uden for EU/EØS.</p>

<p>PFA Pension bruger samarbejdspartnere (databehandlere og underdatabehandlere) i England. Det betyder, at dine personoplysninger kan blive overført til England. Når England udtræder af EU, udgør England et såkaldt tredjeland uden for EU/EØS. Dataoverførslen vil herefter ske i henhold til EU Kommissionens standardkontrakt for overførsel af personoplysninger, medmindre England anses for at have et tilstrækkeligt beskyttelsesniveau. </p>

<p>Hvis PFA Pension indgår EU Kommissionens standardkontrakt med de engelske samarbejdspartnere, kan du til hver en tid få udleveret en kopi af standardkontrakten ved at kontakte PFA Pension’s databeskyttelsesrådgiver.</p>

<p><strong>3. OPBEVARING AF DINE PERSONOPLYSNINGER</strong><br/>
PFA Pension opbevarer dine personoplysninger, indtil kundeforholdet er ophørt, og forældelsesfristerne i  forældelsesloven er indtrådt. Opbevaringen af personoplysningerne sker tillige med respekt for PFA Pension’s forpligtelser til at opbevare personoplysninger i henhold til regnskabs- og bogføringslovgivningen. Der kan forekomme undtagelser, men  PFA Pension opbevarer dine personoplysninger som angivet nedenfor. Herefter vil dine personoplysninger blive slettet.</p>

<div className="col-md-12">
    <div className="table-responsive">
        <table className="table table-hover table-colored table-striped">
        <thead>
        <tr>
            <th>Hovedregler</th>
            <th>Opbevaringsperiode</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>
                <small>
                    Personoplysninger, der er en del af dit kundeforhold  med PFA Pension (”den almindelige forældelsesfrist”)
                </small>
            </td>
            <td>
                <small>
                    10 år efter kundeforholdets ophør*
                </small>
            </td>
        </tr>
        <tr>
            <td>
                <small>
                    Forsikringstilbud, som du ikke accepterer inden acceptfristen	
                </small>
            </td>
            <td>
                <small>
                    Seks mdr. efter acceptfristens udløb
                </small>
            </td>
        </tr>
        <tr>
            <td>
                <small>
                    Hvis du trækker din ansøgning om forsikring tilbage efter indsendelse af helbredsoplysninger til PFA Pension	
                </small>
            </td>
            <td>
                <small>
                    Umiddelbart lige efter tilbagetrækningen af ansøgningen
                </small>
            </td>
        </tr>
        <tr>
            <td>
                <small>
                    Hvis du måtte få afslag på antagelse af forsikring eller individuel risikostigning 	
                </small>
            </td>
            <td>
                <small>
                    Tre år efter ophøret af den automatiske behandling af ansøgningen
                </small>
            </td>
        </tr>
        <tr>
            <td>
                <small>
                    Personoplysninger, som indhentes og behandles om dig til overholdelse af hvidvaskloven 	
                </small>
            </td>
            <td>
                <small>
                    Fem år efter kundeforholdets ophør
                </small>
            </td>
        </tr>
        <tr>
            <td>
                <small>
                    Personoplysninger, som behandles til statistiske formål 	
                </small>
            </td>
            <td>
                <small>
                    30 år efter kundeforholdets ophør 
                </small>
            </td>
        </tr>
        </tbody>
        </table>
    </div>
</div>
*Der kan være karensperioder, der ikke er taget højde for i ovennævnte frist. I tillæg til den almindelige forældelsesfrist på 10 år anvender PFA Pension derfor en periodemæssig sikkerhedsmargin, før oplysningerne slettes.<br/>

<p>Hvis PFA Pension eller kunden foretager en handling, der forlænger forældelsesfristen, fx anmeldelse af en skade, vil PFA Pension stoppe med at slette personoplysningerne.</p>

<p><strong>4. AUTOMATISKE INDIVIDUELLE AFGØRELSER</strong><br/>
PFA Pension anvender automatiserede individuelle afgørelser, når dit ønske om at oprette eller ændre din forsikringsordning via Letpension indebærer en risikostigning for PFA Pension. Den generelle logik bag automatiseringen er, at systemet beregner, om du kan foretage den ønskede oprettelse eller risikostigning på din ordning. Beregningen sker automatisk. PFA Pension’s automatiske behandling af en ansøgning om en forsikring eller individuel risikostigning ophører, hvis det mulige udfald af behandlingen er et afslag på antagelse. Hvis den automatiserede afgørelse fører til afslag, kan du anmode om, at behandlingen af dit ønske overgår til en PFA-medarbejder. Dette sker alene på baggrund af en henvendelse til PFA Pension fra dig selv. </p>

<p><strong>5. PLIGTMÆSSIG INFORMATION  </strong><br/>
PFA Pension har brug for personoplysninger om dig, når de fx opretter og administrerer din pensions- og forsikringsordning. Hvis du ikke giver personoplysningerne til PFA Pension, vil konsekvensen være, at PFA Pension ikke kan varetage formålene ovenfor, fx at de ikke kan oprette dig som kunde i PFA Pension eller ikke kan ændre din pensionsordning.</p>

<p><strong>6. DINE RETTIGHEDER</strong><br/>
Når PFA Pension behandler personoplysninger om dig, har du følgende rettigheder:</p>
<ul className="list-icons">
	<li><i className="fa fa-caret-right pr-10"></i><u>Retten til indsigt</u><br/>
	<div className="indent">Du har ret til at få indsigt i, hvilke personoplysninger PFA Pension behandler om dig.</div> </li>
	<li><i className="fa fa-caret-right pr-10"></i><u>Ret til at gøre indsigelse</u><br/>
	    <div className="indent">Du har ret til at modsætte dig behandlingen af dine personoplysninger og få behandlingen af dine personoplysninger begrænset. Særligt har du en ubetinget ret til at modsætte dig behandling af dine personoplysninger til brug for direkte markedsføring og til at gøre indsigelse mod profilering i det omfang, den vedrører direkte markedsføring.</div></li>
	<li><i className="fa fa-caret-right pr-10"></i><u>Retten til berigtigelse</u><br/>
	    <div className="indent">Du har ret til at få berigtiget urigtige personoplysninger uden unødig forsinkelse, herunder har du under hensyn til formålene med behandlingen ret til at få tilføjet eventuelt manglende personoplysninger.</div></li>
	<li><i className="fa fa-caret-right pr-10"></i><u>Retten til sletning</u><br/>
	    <div className="indent">Du kan se, hvornår PFA Pension sletter dine personoplysninger under afsnit 3 – <i>Opbevaring af dine personoplysninger</i>. Du har i særlige tilfælde ret til at få dine personoplysninger slettet i PFA Pension inden de angivne frister. </div></li>
	<li><i className="fa fa-caret-right pr-10"></i><u>Retten til begrænset behandling</u><br/>
	    <div className="indent">Du har ret til at begrænse PFA Pension’s behandling af dine personoplysninger i visse tilfælde, blandt andet når der foreligger tvivl om dine personoplysningers rigtighed.</div></li>
	<li><i className="fa fa-caret-right pr-10"></i><u>Retten til dataportabilitet</u><br/>
	    <div className="indent">Du har ret til at få personoplysninger, som du selv har afgivet til PFA Pension, udleveret til dig i et struktureret, almindeligt anvendt og maskinlæsbart format. I samme tilfælde har du en ret til at få dine personoplysninger overdraget fra PFA Pension til en anden dataansvarlig, fx til et andet pensions- og forsikringsselskab.</div></li>
	<li><i className="fa fa-caret-right pr-10"></i><u>Tilbagekaldelse af samtykke</u><br/>
	    <div className="indent">Hvis PFA Pension behandler dine personoplysninger på baggrund af dit <i>samtykke</i>, har du til enhver tid ret til at tilbagekalde dit samtykke. En tilbagekaldelse af samtykket indebærer, at PFA Pension ikke fremover må behandle dine personoplysninger til det formål, som du gav dit samtykke til. Tilbagekaldelsen påvirker ikke den behandling af dine personoplysninger, som er foretaget forud for tilbagekaldelsen, fx hvis du har givet os samtykke til at videregive oplysningerne. PFA Pension kan være berettiget til at behandle (fx opbevare) dine personoplysninger på et andet grundlag end samtykke. <br/>Hvis du tilbagekalder dit samtykke, kan konsekvensen være, at PFA Pension i nogle tilfælde ikke vil kunne fortsætte kundeforholdet.</div></li>
</ul>
<br/>

<p>Du kan gøre brug af dine rettigheder ved at ringe til din rådgiver i dit pengeinstitut.</p>

<p>Der kan være betingelser eller begrænsninger til rettighederne ovenfor. Det er derfor ikke sikkert, at du fx har ret til dataportabilitet eller ret til at få personoplysninger slettet i det konkrete tilfælde. Dette afhænger af de konkrete omstændigheder i forbindelse med databehandlingen.</p>

                            <p><strong>7. KONTAKTOPLYSNINGER PÅ PFA PENSION’S DATABESKYTTELSESRÅDGIVER (DPO)</strong><br/>
                                Hvis du har spørgsmål til PFA Pensions’s beskyttelse og behandling af dine personoplysninger, er du velkommen til at kontakte PFA Pension’s databeskyttelsesrådgiver.</p>

                            <p>Du kan kontakte vores databeskyttelsesrådgiver på følgende måder:</p>

                                <table style={{color: 'black'}}> 
                                    <tbody>                                    
                                    <tr>
                                        <td>På e-mail:</td>
                                        <td> </td>
                                        <td style={{paddingLeft: '3em'}}> databeskyttelse@pfa.dk</td>
                                    </tr>
                                    <tr>
                                        <td><br/></td>
                                        <td> </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Ved brev: </td>
                                        <td> </td>
                                        <td style={{paddingLeft: '3em'}}> PFA Pension, forsikringsaktieselskab</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td> </td>
                                        <td style={{paddingLeft: '3em'}}> Sundkrogsgade 4</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td> </td>
                                        <td style={{paddingLeft: '3em'}}> 2100 København Ø  </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td> </td>
                                        <td style={{paddingLeft: '3em'}}> Att: Databeskyttelsesrådgiver</td>
                                    </tr>
                                    </tbody>
                                </table>
<br />
<p><strong><u>8. KLAGE TIL DATATILSYNET</u></strong></p>

<p>Du har til enhver tid ret til at indgive en klage til Datatilsynet over PFA Pension’s behandling af dine personoplysninger. Du bør dog altid først tage kontakt til PFA Pension, hvis du mener PFA Pension har behandlet dine personoplysninger i strid med den persondataretlige regulering. På den måde kan du få PFA Pension’s forklaring af sagen. Du kan kontakte Datatilsynet pr. mail <a href="mailto:dt@datatilsynet.dk" className="link-dark">dt@datatilsynet.dk</a> eller læse mere på <a href="www.datatilsynet.dk" className="link-dark">www.datatilsynet.dk</a>.</p>

<p>Datoen for, hvornår PFA Pension sidst har opdateret denne politik, vil fremgå øverst på siden.</p>

                        </div>
                    </div>
                </div>
            </section>

            <br/>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/produktet/omkostninger#top" billede="/Resources/images/Letpension/omkostninger_457x300.jpg" tekst="Omkostninger" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>



</div>


      );
    }
}




