import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Panel } from 'react-bootstrap';


export class KontaktLetpension extends Component {
    render() {
        return (

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <img id="cover" src={'/Resources/images/businesscall.jpg'}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="separator center"></div>
							<h3 className="title text-center">mick.storm@neotech.dk</h3>

                        </div>
                    </div>
			    </div>
            </section>

      );
    }
}

