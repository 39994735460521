
import {
  HENT_KORRESPONDANCE,
  MODTAG_KORRESPONDANCE,
  INVALIDER_KORRESPONDANCE,
} from '../actions/arkiv.jsx'


export default function arkiv(
        state = {
            indlaeser: false,
            invalideret: false,
            fejlmeddelelse: null,
            dokumenter: null
        },
        action
    )
{
  switch (action.type) {
    case HENT_KORRESPONDANCE:
      return Object.assign({}, state, {
        indlaeser: true,
        invalideret: false,
        fejlmeddelelse: null,
        dokumenter: null,
      })
    case MODTAG_KORRESPONDANCE:
      return Object.assign({}, state, {
        indlaeser: false,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        dokumenter: action.dokumenter
      })
    case INVALIDER_KORRESPONDANCE:
      return Object.assign({}, state, {
        indlaeser: false,
        fejlmeddelelse: null,
        invalideret: true,
        dokumenter: null
      })
    default:
      return state;
  }
}


