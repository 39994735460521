import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';

import ImageLink from '../navigation/imagelink.jsx';


export class OmNeotech extends Component {
    render() {
        return (

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <img id="cover" src={'/Resources/images/booksandgoogles.jpg'}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="separator center"></div>
							<h3 className="title text-center">Selskab der leverer IT ydelser</h3>

                        </div>
                    </div>
			    </div>
            </section>
      );
    }
}



