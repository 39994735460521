import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class Helbredsoplysninger extends Component {
    render() {
        return (

<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Hvorfor har vi brug for oplysninger om dit helbred og erhverv?</h3>
                            <div className="separator center"></div>
                            <p>Letpension har brug for at kende til dit helbred og dit erhverv for at kunne tilbyde dig de dækninger, der passer præcis til dine behov. Praktisk foregår det ved, at du svarer på en række spørgsmål på vores elektroniske helbredsportal, inden rådgivningen går i gang.</p>
                            
                            <p><strong>Hvad skal der ske inden mødet med min rådgiver?</strong></p>
                            <p>Når du aftaler et møde med din lokale rådgiver i dit pengeinstitut, bliver du bedt om at svare på en række spørgsmål om dit helbred og dit erhverv. Gennem vores elektroniske helbredsportal kan du i ro og mag besvare spørgsmålene. Din rådgiver må ikke hjælpe dig eller vide, hvad du svarer, og dit pengeinstitut kan ikke se dine helbredsoplysninger. Din rådgiver får kun at vide, hvilken løsning, han sammen med dig kan skabe på baggrund af dine svar. Indholdet af dine svar får din rådgiver altså aldrig at se.</p>
                        </div>
                    </div>
                </div>
            </section>


			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="call-to-action dark-bg mt-10">
								<div className="row p-20">
									<div className="col-sm-12">
										<h3 className="title">Brug for hjælp?</h3>
										<p>Vil du vide mere om, hvordan du får adgang til vores helbredsportal og bedst muligt svarer på spørgsmålene, kan du få hjælp her.</p>
									</div>
									<div className="col-sm-12">
										<br/>
										<p><a href="/dokumenter/vejledningtillethelbred" className="btn btn-lg btn-gray-transparent btn-animated" target="_blank">Vejledning til Lethelbred<i className="fa fa-arrow-right pl-20"></i></a></p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="call-to-action dark-bg mt-10">
								<div className="row p-20">
									<div className="col-sm-12">
										<h3 className="title">Svar på spørgsmål om helbred og erhverv</h3>
										<p>Har din rådgiver givet dig adgang til at svare på spørgsmål om dit helbred og erhverv, kan du logge ind her.</p>
									</div>
									<div className="col-sm-12">
										<br/>
										<p><a href="https://www.letportal.dk" className="btn btn-lg btn-gray-transparent btn-animated" target="_blank">Log ind på Lethelbred<i className="fa fa-arrow-right pl-20"></i></a></p>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>

			<section className="padding-top-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Har du brug for hjælp til at besvare spørgsmålene eller har du spørgsmål til resultatet af dine svar, kan du kontakte vores helbredssupport på telefon 7026 2630.</p>
                            
                            <p><strong>Hvad giver jeg tilladelse til?</strong></p>
                            <p>På helbredsportalen beder vi om din tilladelse til, at vi må informere din rådgiver om, hvilken løsning, du kan få fra Letpension. Til sidst skal du bekræfte, at de oplysninger, du har givet os, er fyldestgørende.</p>
                            <br />                            
                        </div>
                    </div>
                </div>
            </section>

			<section>
				<div className="container">

                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-lg-offset-1 col-lg-5">
                            <ImageLink link="/omletpension#top" billede="/Resources/images/Letpension/om_Letpension_457x300.jpg" tekst="Om Letpension" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-lg-5">
                            <ImageLink link="/forkunder/tjekdinpension#top" billede="/Resources/images/Letpension/produkter/tjek_din_pension.png" tekst="Tjek din pension" />
                        </div>
                    </div>

                </div>
            </section>



</div>


      );
    }
}
