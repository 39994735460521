import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import SiteLink from './navigation/sitelink.jsx';

export default class PrivatlivOgCookies extends Component {
  componentDidMount() {
    document.title = 'Letpension';
  }
    render() {
        return (
		<div className="harlekin-baggrund">

			<section className="main-container padding-bottom-clear ">
				<div className="container">
					<div className="row">
                        <div className="col-md-1"/>
                        <div className="col-md-10">
                            <h1 className="title text-center">PRIVATLIV OG COOKIES</h1>
                            <div className="separator center"></div>
                            <p>Her på siden kan du læse, hvordan Letpension A/S indsamler og anvender dine personoplysninger via hjemmesiden letpension.dk.</p>

                            <h4>Indsamling af dine personoplysninger</h4>
                            <p>Letpension indsamler personoplysninger om dig på blandt andet følgende måder:</p>
                            <ul className="list-icons">
                                <li><i className="fa fa-caret-right pr-10"></i>Ved brug af cookies på letpension.dk (se mere herom i det nedenstående); og</li>
                                <li><i className="fa fa-caret-right pr-10"></i>Ved at du selv sender os dine personoplysninger via en e-mail, som du har fået oplyst på letpension.dk.</li>
                            </ul>
                            <h4>Hvilke personoplysninger behandles om dig</h4>
                            <p>I forbindelse med ovenstående kan vi blandt andet indsamle følgende personoplysninger om dig:</p>
                            <ul className="list-icons">
                                <li><i className="fa fa-caret-right pr-10"></i>Navn, fødselsdato, kontaktoplysninger, hvad din henvendelse drejer sig om og svaret herpå, hvis du sender oplysningerne til os i en e-mail;</li>
                                <li><i className="fa fa-caret-right pr-10"></i>Din IP-adresse;</li>
                                <li><i className="fa fa-caret-right pr-10"></i>Oplysninger om dit styresystem på din computer, på din mobile enhed, hvis du besøger letpension.dk via denne, samt den valgte internetbrowser; og</li>
                                <li><i className="fa fa-caret-right pr-10"></i>Dine onlineaktiviteter på letpension.dk.</li>
                            </ul>
                            <h4>Formålene med behandlingen af dine personoplysninger</h4>
                            <p>Formålet med behandlingen af dine personoplysninger er at give dig en bedre kundeoplevelse, når du besøger letpension.dk. Herudover er formålet at vi kan yde dig den bedste service, når vi besvarer dine henvendelser via e-mail.</p>

                            <h4>Retsgrundlaget for behandlingen af dine personoplysninger</h4>
                            <p>Letpension kan behandle dine personoplysninger, hvis du har givet dit forudgående og udtrykkelige samtykke hertil, såsom når du henvender dig til os via e-mail og giver samtykke til, at vi må bruge de indsendte oplysninger til at behandle din henvendelse. Personoplysninger indsamlet gennem cookies behandles på baggrund af Letpensions legitime interesse i at give dig en bedre oplevelse på letpension.dk, når du har givet accept af cookies på hjemmesiden.</p>
                            <p>Letpension kan også behandle dine personoplysninger for at opfylde en aftale med dig eller under konkrete omstændigheder for at forfølge en legitim interesse, i det omfang en sådan interesse vurderes at gå forud for din interesse i, at der ikke sker behandling af dine personoplysninger. En legitim interesse for Letpension kan blandt andet være forbedring af letpension.dk, vores ydelser, undersøgelser i forbindelse med besvarelse af dine henvendelser og lignende.</p>

                            <h4>Modtagere af dine personoplysninger</h4>
                            <p>Letpension kan dele dine personoplysninger med samarbejdspartnere, leverandører samt andre tredjeparter, hvor formålet er at behandle dine personoplysninger på vegne af Letpension og til vores formål. Disse tredjeparter må således ikke anvende dine personoplysninger til egne formål. Dine personoplysninger kan videregives til dit pengeinstitut og/eller PFA, hvis det er nødvendigt for at besvare din henvendelse. Videregivelse af dine personoplysninger vil altid ske i overensstemmelse med den til enhver tid gældende databeskyttelseslovgivning, og hvor påkrævet med dit forudgående og udtrykkelige samtykke. </p>

                            <h4>Periode for opbevaringen af dine personoplysninger</h4>
                            <p>Letpension behandler dine personoplysninger så længe det er nødvendigt for at opfylde de ovenstående formål, herunder så længe din henvendelse håndteres af os og evt. yderligere opbevaring efter en besvaret henvendelse, hvis dette er nødvendigt af dokumentationshensyn. Oplysninger om din onlineadfærd kan opbevares så længe det er nødvendigt af hensyn til optimering af letpension.dk.</p>
                            <h4>Brug af cookies på letpension.dk</h4>
                            <p>Ved at besøge letpension.dk accepterer du, at Letpension bruger cookies til at forbedre din onlineoplevelse. </p>

                            <p><u><i>Hvad er en cookie?</i></u><br />
                            En cookie er en tekstfil, som bliver placeret på din harddisk af en websideserver. Cookies indeholder oplysninger, der senere kan læses af en webserver på det domæne, som udstedte den pågældende cookie til dig, dvs. at letpension.dk husker dig, næste gang du besøger den. Cookies kan ikke bruges til at køre programmer eller sende virus til din computer.</p>

                            <p><u><i>Formålet med at anvende cookies?</i></u><br />
                            Vores primære formål med at anvende cookies er at måle trafikken på letpension.dk og kunne forbedre brugeroplevelsen ved at føre statistik over, hvordan vores brugere benytter letpension.dk. </p>

                            <p><u><i>Google Analystics</i></u><br />
                            Vi bruger Google Analytics til at føre statistik over brugen af letpension.dk. Vi får for eksempel oplysninger om, hvilken browser vores besøgende bruger, hvilke sider, der bliver besøgt mest, og hvor længe vores besøgende bliver på letpension.dk. Google indsamler statistikken og indsætter ovennævnte tekstfil på din computer på vegne af Letpension. Vi anvender oplysningerne til at danne os et overblik over, hvordan letpension.dk bliver brugt, så vi løbende kan forbedre din brugeroplevelse på letpension.dk.</p>

                            <p>Du kan læse mere om Google Analytics <a href="https://www.google.com/intl/da_DK/policies/technologies/cookies/" className="link-dark" target="_blank">her</a>.</p>

                            <p><u><i>Fravalg af cookies</i></u><br />
                            Hvis du ikke ønsker, at Letpension skal indsamle og gemme ovenstående personoplysninger om dig, kan du slå cookies fra i indstillingerne i din internetbrowser. Bemærk dog, at fremgangsmåden til at slå cookies fra er forskellig afhængig af, hvilken internetbrowser du anvender. Vælg din internetbrowser nedenfor og læs herefter, hvordan du fjerner cookies fra din computer:</p>
                            <ul >
                                <li><a href="http://support.google.com/accounts/bin/answer.py?hl=da&&answer=32050" className="link-dark" target="_blank">Googles vejledning til hvordan man fjerner cookies i de mest anvendte browsere</a></li>
                                <li><a href="http://www.hvordan.dk/hvordan-fjerner-afviser-blokerer-man-cookies-i-firefox" className="link-dark" target="_blank">Vejledning til hvordan man fjerner og blokerer cookies i Firefox</a></li>
                                <li><a href="http://www.hvordan.dk/hvordan-blokerer-sletter-man-cookies-i-google-chrome" className="link-dark" target="_blank">Vejledning til hvordan man fjerner og blokerer cookies i Google Chrome</a></li>
                                <li><a href="http://www.hvordan.dk/hvordan-sletter-man-cookies-i-internet-explorer-7-8-9" className="link-dark" target="_blank">Vejledning til hvordan man fjerner og blokerer cookies i Internet Explorer 7, 8 og 9</a></li>
                            </ul>

                            <h4>Dine rettigheder</h4>
                            <p>Letpension har iagttaget og gennemført passende tekniske og organisatoriske sikkerhedsforanstaltninger for at beskytte dine personoplysninger samt sikre dine rettigheder som den registrerede, da dette har en høj prioritet for os. </p>

                            <p>Du har først og fremmest ret til at anmode om at få indsigt i de personoplysninger, som Letpension behandler om dig. Herudover har du ret til at få dine personoplysninger berigtiget, slettet eller få begrænset brugen af disse. Du har endvidere ret til at gøre indsigelse mod behandlingen af dine personoplysninger. Hvis din indsigelse er berettiget, må Letpension ikke længere behandle dine personoplysninger. </p>

                            <p>Under særlige omstændigheder har du ret til dataportabilitet. Det betyder, at du kan anmode om at få de oplysninger, du selv har givet til Letpension, udleveret i et struktureret, almindeligt anvendt og maskinlæsbart format. Hvis det er teknisk muligt, kan du også anmode om at få oplysningerne transmitteret til en tredjepart.</p>

                            <p>Hvis Letpension behandler dine personoplysninger på baggrund af et samtykke, har du til enhver tid ret til at trække dit samtykke tilbage. Bemærk dog, at det ikke berører lovligheden af den behandling, der er baseret på dit samtykke, før dit valg om at tilbagetrække det. </p>

                            <h4>Klage</h4>
                            <p>Hvis du ønsker at klage over Letpensions behandling af dine personoplysninger kan du rette henvendelse til klage@letpension.dk eller ved at rette henvendelse til Datatilsynet, Borgergade 28, 5, 1300 København K, dt@datatilsynet.dk, tlf. nr. 3319 3200. </p>
                            <p>Bemærk, at når du henvender dig til os via e-mail, giver du samtidig samtykke til, at vi må bruge de oplysninger, du sender os, til at behandle din henvendelse.</p>
                        </div>
					</div>
				</div>
            </section>
		</div>
      );
    }
}
