export const OPDATER_MINEVAERDIERWIZARD_STEP = 'OPDATER_MINEVAERDIERWIZARD_STEP';
export const OPDATER_MINEVAERDIERWIZARD_MODE = 'OPDATER_MINEVAERDIERWIZARD_MODE';

export function opdaterWizardStep(step, visFejl, lastAction) {
    return {
        type: OPDATER_MINEVAERDIERWIZARD_STEP,
        step,
        visFejl,
        lastAction
    };
}

export function opdaterWizardMode(mode) {
    return {
        type: OPDATER_MINEVAERDIERWIZARD_MODE,
        mode
    };
}
