import React, { Component } from 'react';
import SiteLink from '../navigation/sitelink.jsx';
import ImageLink from '../navigation/imagelink.jsx';

export default class LetsikringAfIndtaegtVedPension extends Component {
    render() {
        return (
            <div className="harlekin-baggrund">
			<section className="main-container padding-bottom-clear">
				<div className="container">
                    <div className="row">
                        <div className="col-md-12">
							<h3 className="title text-center">Letsikring af indtægt ved pension</h3>
                        </div>
                    </div>
                    <div className="row">
                        <video controls className="col-xs-12 col-sm-8 col-xs-offset-0 col-sm-offset-2">
                            <source src="/resources/videos/Letsikring_af_indtaegt_ved_pension.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="separator center"></div>
                            <p>Med en Letsikring af indtægt ved pension kan du sikre, at dine penge kommer dig og dine nærmeste til gode.</p>
                            <p>En Letsikring af indtægt ved pension er det, andre pensionsselskaber kalder en livrente. Den sikrer, at du får udbetalt penge, så længe du lever.</p>
                            <p>Du kan vælge, at din ægtefælle eller samlever skal overtage udbetalingen, hvis du dør i perioden med udbetaling. Du kan også sikre, at din opsparing bliver udbetalt til dine efterladte, hvis du dør, før din udbetaling begynder.</p>
                            <br />     
                            <h4 className="title">Vi lægger ikke skjul på, hvad tingene koster</h4>
                            <p>Hvis du gerne vil vide, hvilke omkostninger du betaler for at have en Letsikring af indtægt ved pension, kan du læse mere under ”Omkostninger”.</p>                       
                        </div>
                    </div>
                </div>
            </section>

			<section className="section clearfix">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="call-to-action dark-bg">
								<div className="row p-20">
									<div className="col-md-8">
										<h3 className="title">Hvis du vil vide mere </h3>
										<p>Vil du vide mere om, hvordan vi kan sikre, at dine penge kommer dig eller dine nærmeste til gode, kan du kontakte din rådgiver i dit lokale pengeinstitut.</p>
									</div>
									<div className="col-md-4">
										<br/>
										<p><SiteLink url="/blivkunde" classname="btn btn-lg btn-gray-transparent btn-animated">Bliv kunde i Letpension<i className="fa fa-arrow-right pl-20"></i></SiteLink></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

            <br/>

			<section className="section clearfix">
				<div className="container">
                    <div className="row">
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/produktet/omkostninger" billede="/Resources/images/Letpension/produkter/omkostninger.png" tekst="Omkostninger" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/produktet/letsikringvedsygdom" billede="/Resources/images/Letpension/produkter/letsikringvedsygdom.png" tekst="Letsikring ved sygdom" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/Produktet/LetsikringVedKritiskSygdom" billede="/Resources/images/Letpension/produkter/letsikringvedkritisksygdom.png" tekst="Letsikring ved kritisk sygdom" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/Produktet/LetsikringAfBarnVedDoed" billede="/Resources/images/Letpension/produkter/letsikringafbarnveddoed.png" tekst="Letsikring af barn" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/produktet/letsikringveddoed" billede="/Resources/images/Letpension/produkter/letsikringveddoed.png" tekst="Letsikring ved død" />
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 col-sm-offset-0 col-sm-6 col-md-4">
                            <ImageLink link="/blivkunde" billede="/Resources/images/Letpension/Find_pengeinstitut_457x300.jpg" tekst="Bliv kunde i Letpension" />
                        </div>

                    </div>

                </div>
            </section>
            </div>
      );
    }
}

class FindPengeInstitut extends Component {
    render() {
        return (
            <a className="nav-link" href="#/blivKunde/FindOsIListe"><i className="fa fa-caret-right pr-10"></i>Bliv kunde i Letpension</a>
        );
    }
}