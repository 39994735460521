import React, { Component } from 'react';
import classnames from 'classnames';
import { ErrorBoundary } from "components/common/errors";

export class Side extends Component {
    render() {
        return (
            <div className="harlekin-baggrund">
                <ErrorBoundary>
                    {this.props.children}
                </ErrorBoundary>
            </div>
        );
    }
}

export class Indhold extends Component {
    render() {
        return (
            <section className="section clearfix">
                <div className={classnames('container', this.props.className)}>
                    {this.props.children}
                </div>
            </section>
        );
    }
}

export class Titel extends Component {
    render() {
        return (
            <section className="main-container padding-bottom-clear ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="title text-center">{this.props.children}</h3>
                            <div className="separator center"></div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
