import { restIndlaes, restSlet, restOpdater, restTilfoej, getRestState, getRestStateListe } from "../../reducers/rest";

const PENGEINSTITUTTER = {
    path: "pengeinstitutter",
    id: 'id'
};


export function getPengeinstitutterState(state) {
    return getRestState(PENGEINSTITUTTER, state);
}

export function indlaesPengeinstitutter() {
    return restIndlaes(PENGEINSTITUTTER);
}
